import React, { useState, useRef, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
// import { Button, Modal,ModalHeader, ModalBody,ModalFooter,Label,Input,Row, Col,} from "reactstrap";
import { Modal } from "../../global/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { version } from "../../../../utils/const";
import axios from "axios";

import { Select, SelectOptions } from "../../global/Select";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { ChevronDownTable } from "../../../../svg-shapes/ChevronDownTable";
import { UpgradeLock } from "../../../../svg-shapes/UpgradeLock";
import { dotColor } from "../../global/functions";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import lockStyles from "../../../../styles/plexyz/components/pages/dashboard/upgrade-lock.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";

export const SpaceList = ({ data, tier, refetchSite, refetchArea }) => {
  const [sortBy, setSortBy] = useState("Status");
  const [sortType, setSortType] = useState("desc");
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [showDialog3, setShowDialog3] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [siteID, setSiteID] = useState("");
  const [siteName, setSiteName] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [mouseOver, setMouseOver] = useState(false);
  const [activeRow, setActiveRow] = useState(0);

  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  const distanceRef = useRef(null);
  const visitingRef = useRef(null);
  const speedRef = useRef(null);
  const locationRef = useRef(null);

  const { width: distanceColWidth } = useContainerDimensions(distanceRef, [data]);
  const { width: visitingColWidth } = useContainerDimensions(visitingRef, [data]);
  const { width: speedColWidth } = useContainerDimensions(speedRef, [data]);
  const { width: locationColWidth } = useContainerDimensions(locationRef, [data]);

  const deviceTable = () => {
    // console.log('hello', data);
    return data.length !== 0 ? (
      <table className={styles.devTable}>
        <thead>
          <tr className={styles.tableHeader}>
            <th style={{ width: "50%" }}>Site Name</th>
            <th style={{ minWidth: "66px" }}>Site Latitude</th>
            <th style={{ minWidth: "66px" }}>Site Longitude</th>
            <th style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody>
          {(data || []).map((d, i) => {
            var NAME = d.site_name;
            NAME = NAME.replace(/[^a-zA-Z0-9]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            var LAT, LONG;

            if (d.site_gps !== undefined && d.site_gps !== null) {
              if (d.site_gps.lat !== undefined) {
                LAT = d.site_gps.lat;
              } else {
                LAT = d.site_gps.x;
              }

              if (d.site_gps.long !== undefined) {
                LONG = d.site_gps.long;
              } else {
                LONG = d.site_gps.y;
              }
            }

            return (
              <tr key={i}>
                {/* <td>{d.site_uid}</td>     */}
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {NAME}
                </td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {LAT}{" "}
                </td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {" "}
                  {LONG}{" "}
                </td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        openDets(d);
                      }}
                      onMouseEnter={() => {
                        handleMouseOver(i);
                      }}
                      onMouseLeave={handleMouseLeave}
                    >
                      {" "}
                      EDIT{" "}
                    </button>
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        deleteUnit(d);
                      }}
                      onMouseEnter={() => {
                        handleMouseOver(i);
                      }}
                      onMouseLeave={handleMouseLeave}
                    >
                      {" "}
                      DEL{" "}
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : null;
  };

  // ====== functions =======

  useEffect(() => {
    // fetchList()
  }, []);
  const handleMouseOver = (i) => {
    setMouseOver(true);
    setActiveRow(i);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
    setActiveRow(0);
  };

  const checkDetails = async (event) => {
    event.preventDefault();
    if (siteName === "") {
      toast.error("Site Name is required");
      return;
      // } else if (lat === "") {
      //   toast.error("Latitude is required");
      //   return;
      // } else if (lat === "") {
      //   toast.error("Longitude is required");
      //   return;
    } else {
      try {
        await addSite();
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const addSite = () => {
    setShowDialog(false);
    const URL = `${version.expURL}/api2/site/create_site`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      site_name: siteName,
      site_gps: {
        lat: lat !== "" ? parseFloat(lat) : 0,
        long: long !== "" ? parseFloat(long) : 0,
      },
      // area_name: areaName
    };

    // console.log('boo', body);

    axios.post(URL, body).then((res) => {
      // console.log('create_site', res);
      if (res.data.status === "success") {
        toast.success("Site added successfully");
        refetchSite();
        cancelAddSite();
      }
    });

    // create_site, user_uid, site_gps,site_name //area_name, area_gps
  };
  const cancelAddSite = () => {
    setShowDialog(false);
    setShowDialog2(false);
    setShowDialog3(false);
    setSiteName("");
    setLat("");
    setLong("");
  };

  const openDets = (d) => {
    var NAME = d.site_name;
    NAME = NAME.replace(/[^a-zA-Z0-9]/g, " ");
    NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

    var LAT, LONG;

    if (d.site_gps !== undefined && d.site_gps !== null) {
      if (d.site_gps.lat !== undefined) {
        LAT = d.site_gps.lat;
      } else {
        LAT = d.site_gps.x;
      }

      if (d.site_gps.long !== undefined) {
        LONG = d.site_gps.long;
      } else {
        LONG = d.site_gps.y;
      }
    }

    setSiteID(d.site_uid);
    setSiteName(NAME);
    setLat(LAT);
    setLong(LONG);
    setShowDialog2(true);
  };

  const editSite = () => {
    setShowDialog2(false);
    const URL = `${version.expURL}/api2/site/edit_site`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      site_name: siteName,
      site_uid: siteID,
      site_gps: {
        lat: parseFloat(lat),
        long: parseFloat(long),
      },
    };

    // console.log('boo', body);

    axios.post(URL, body).then((res) => {
      // console.log('update_site', res);
      if (res.data.status === "success") {
        toast.success("Site has been updated!");
        refetchSite();
        cancelAddSite();
      }
    });
  };

  const deleteUnit = (d) => {
    var NAME = d.site_name;
    NAME = NAME.replace(/[^a-zA-Z0-9]/g, " ");
    NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

    setSiteID(d.site_uid);
    setSiteName(NAME);
    setShowDialog3(true);
  };

  const removeSite = () => {
    setShowDialog3(false);
    const URL = `${version.expURL}/api2/site/delete_site`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      site_uid: siteID,
    };

    // console.log('delete_site', body);

    axios.post(URL, body).then((res) => {
      // console.log('update_site', res);
      if (res.data.status === "success") {
        toast.success("Site has been removed!");
        refetchSite();
        refetchArea();
        cancelAddSite();
      } else {
        toast.error("Site chosen currently has unit binded to it");
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <h4 className={styles.title}>All Sites</h4>
        {/* <div className={styles.selectContainer}>
                    <Select
                        options={sortOptions}
                        value={sortBy}
                        changeValue={(newSortBy)=>{setSortBy(newSortBy);}}   
                    />
                </div> */}
        <div
          className={`${modalStyles.basicButton} ${modalStyles.active}`}
          onClick={() => setShowDialog(true)}
        >
          <p className={modalStyles.buttonTitle}>Add Site</p>
        </div>
      </div>

      <div className={styles.tableContainer}>
        {
          <>
            {/* <TableScrollbar> */}
            {deviceTable()}

            {/* </TableScrollbar> */}
            {tier === 1 && (
              <div
                className={styles.upgradeLock}
                style={{
                  width: `${
                    distanceColWidth + visitingColWidth + speedColWidth + locationColWidth
                  }px`,
                }}
              >
                <Upgrade />
              </div>
            )}
          </>
        }

        {/* <div onClick={checkCheck}>
                    grrrr
                </div>         */}
      </div>
      {showDialog && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Add Site</h3>

            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Site Name"
                inputId="siteName"
                value={siteName}
                onChange={(newValue) => {
                  setSiteName(newValue);
                }}
                type="text"
                placeholder="eg: Bangunan A, Workshop B"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Latitude"
                inputId="lat"
                value={lat}
                onChange={(newValue) => {
                  setLat(newValue);
                }}
                type="text"
                placeholder="eg: 1.234"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Longitude"
                inputId="long"
                value={long}
                onChange={(newValue) => {
                  setLong(newValue);
                }}
                type="text"
                placeholder="eg: 101.234"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  checkDetails(e);
                }}
              >
                Add Site
              </div>
              <div
                onClick={cancelAddSite}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showDialog2 && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Edit Site</h3>

            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Site Name"
                inputId="siteName"
                value={siteName}
                onChange={(newValue) => {
                  setSiteName(newValue);
                }}
                type="text"
                placeholder="eg: Bangunan A, Workshop B"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Latitude"
                inputId="lat"
                value={lat}
                onChange={(newValue) => {
                  setLat(newValue);
                }}
                type="text"
                placeholder="eg: 1.234"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Longitude"
                inputId="long"
                value={long}
                onChange={(newValue) => {
                  setLong(newValue);
                }}
                type="text"
                placeholder="eg: 101.234"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  editSite(e);
                }}
              >
                Update Site
              </div>
              <div
                onClick={cancelAddSite}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showDialog3 && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Delete Site</h3>
            <br />
            <h3 className={modalStyles.addDeviceTitle}>
              Are you sure you want to delete {siteName} ?
            </h3>
            <h4 className={modalStyles.addDeviceTitle}>
              Do note that this action cannot be undo.
              <br></br> Area listed under this site will also be deleted.
              <br></br> You'll have to add a new one if you accidentally deleted it.
            </h4>
            <br />

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  removeSite(e);
                }}
              >
                Confirm Delete
              </div>
              <div
                onClick={cancelAddSite}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

const InputContainer = ({ label, inputId, icon, value, onChange, type, placeholder }) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};

const SortableColumn = ({ sortType, sortBy, toggleSortType }) => {
  //onClick={()=>{console.log('sort clicked');toggleSortType();}}
  return (
    <span className={styles.sortableColumn}>
      {sortBy}
      <span className={`${styles.chevron} ${sortType === "asc" && styles.sortAscending}`}>
        <ChevronDownTable />
      </span>
    </span>
  );
};

export const Upgrade = () => {
  return (
    <div className={lockStyles.container}>
      <div className={lockStyles.lockLogo}>
        <UpgradeLock />
      </div>
      <p className={lockStyles.label}>Upgrade your plan to see more analytics</p>
    </div>
  );
};
