import React, { useState, useEffect, useRef, useContext } from "react";
import * as ReactDOM from "react-dom";
import { useDoubleTap } from "use-double-tap";
import * as d3 from "d3";
import { useD3 } from "../../../hooks/used3";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { TopControlContext } from "../pages/dashboard2/context";
import { dotColor } from "../global/functions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Tooltip } from "../global/Tooltip";
import { checkIfDuplicateExists } from "../global/functions";
import styles from "../../../styles/plexyz/components/graph-display/radar.module.css";
import tooltipStyles from "../../../styles/plexyz/components/global/tooltip.module.css";
import { transition } from "d3";

export const RadarChart = ({ width, height, graphData, hoveredMac, setHoveredMac }) => {
  const equalizeDomain = (range) => {
    const min = range[0];
    const max = range[1];

    const absMin = Math.abs(min);

    const newAbsMin = absMin < max ? max : absMin;
    const newMin = newAbsMin * -1;
    const newMax = max < absMin ? absMin : max;

    return [newMin, newMax];
  };
  const ref = useD3(
    (svg) => {
      //const height = height;
      const margin = { top: 16, right: 16, bottom: 16, left: 16 };
      //const graphData = graphData;
      const xDomain = equalizeDomain(d3.extent(graphData.map((d) => d.x)));
      const yDomain = equalizeDomain(d3.extent(graphData.map((d) => d.y)));

      const xScale = d3
          .scaleLinear()
          .domain(xDomain)
          .range([0, width - margin.left - margin.right]),
        yScale = d3
          .scaleLinear()
          .domain(yDomain)
          .range([height - margin.top - margin.bottom, 0]);

      const plotArea = svg
        .select(".plot-area")
        .attr("transform", "translate(" + margin.top + ",  " + margin.left + ")");

      const displayTooltip = hoveredMac !== "";
      const tooltipData = displayTooltip
        ? graphData.filter((d) => d.mac_address === hoveredMac)[0]
        : { mac_address: "", name: "", status: "", x: 0, y: 0 };
      const { mac_address, name, status, x, y } = tooltipData;

      //let tooltip = svg.select(".tooltip"), nameText, statusText,posX,posY;

      const tooltip = svg
        .append("g")
        .attr("className", "tooltip")
        .attr("transform", (d) => "translate(" + (xScale(x) - 120) + ", " + yScale(y) + ")")
        .attr("fill", "rgba(213, 198, 255, 0.66)")
        .attr("stroke", "rgba(213, 198, 255, 0.66)")
        .attr("stroke-width", 1)
        .style("font-size", 14)
        .style("pointer-events", "none")
        //.attr("opacity", hoveredMac === ""?0:1);
        .attr("opacity", 0);

      const nameText = tooltip
        .append("text")
        .attr("className", "name")
        .attr("x", 5)
        .attr("y", 5)
        .text("name");

      const statusText = tooltip
        .append("text")
        .attr("className", "status")
        .attr("x", 5)
        .attr("y", 22)
        .text("status");

      const posX = tooltip
        .append("text")
        .attr("className", "location-x")
        .attr("x", 5)
        .attr("y", 39)
        .text("loc x");

      const posY = tooltip
        .append("text")
        .attr("className", "location-y")
        .attr("x", 5)
        .attr("y", 56)
        .text("loc y");

      /*
          if(!tooltip._groups[0][0]){
            tooltip = svg
              .append("g")
              .attr("className","tooltip") 
              .attr("transform", "translate(" + (xScale(x) - 120) + ", " + yScale(y) + ")")       
              .attr("fill", "rgba(213, 198, 255, 0.66)")
              .attr("stroke", "rgba(213, 198, 255, 0.66)")
              .attr("stroke-width", 1)
              .style("font-size", 14)
              .style("pointer-events", "none")
              .attr("opacity", hoveredData?1:0);

              setTooltip(nameText);
              setTooltip(statusText);
              setTooltip(posX);
              setTooltip(posY);
          }else{
            tooltip
              .attr("transform", "translate(" + (xScale(x) - 120) + ", " + yScale(y) + ")")
              .attr("opacity", hoveredData?1:0);
          }*/

      /*
          if(hoveredMac === ""){
            console.log('no hovered');
            //d3.selectAll(".tooltip").selectAll("text").remove();
            //svg.select(".tooltip").select(".status").remove();
            //svg.select(".tooltip").select(".location-x").remove();
            //svg.select(".tooltip").select(".location-y").remove();
            //d3.selectAll(".tooltip").remove();

            const tooltip = d3.selectAll(".tooltip");
            const tooltipTexts = tooltip.selectAll("text");

            console.log(tooltip);
            tooltipTexts.remove();
            tooltip.remove();

            console.log(tooltip);
          }else if(hoveredMac !== "") {
            console.log('with hovered : ', hoveredMac);
            let tooltip = svg.select("g.tooltip");
            const tooltips = svg.selectAll("g.tooltip");
            console.log(tooltip._groups[0]);
            console.log(tooltips._groups[0]);
            if(!tooltip._groups[0][0]){
              tooltip = svg
                .append("g")
                .attr("className","tooltip") 
                .attr("transform", "translate(" + (xScale(x) - 120) + ", " + yScale(y) + ")")       
                .attr("fill", "rgba(213, 198, 255, 0.66)")
                .attr("stroke", "rgba(213, 198, 255, 0.66)")
                .attr("stroke-width", 1)
                .style("font-size", 14)
                .style("pointer-events", "none")
                .attr("opacity", hoveredMac!==""?1:0);

              tooltip
                .append("text")
                .attr("className", "name")
                .attr("x", 5)
                .attr("y", 5)
                .text(name);

              console.log(tooltip._groups[0]);
            }
            /*  .append("g")
              .attr("className","tooltip")
              .attr("transform", "translate(" + (xScale(x)) + ", " + yScale(y) + ")")          
              .attr("fill", "rgba(213, 198, 255, 0.66)")
              .attr("stroke", "rgba(213, 198, 255, 0.66)")
              .attr("stroke-width", 1)
              .style("font-size", 14)
              .style("pointer-events", "none")
              .attr("opacity", 1);
            
            
          }
          */
      /*;

          console.log(name, status, x, y);
          console.log(tooltip);
          console.log(nameText);
          console.log(statusText);
          */

      /*
          function setTooltip(tooltipText){
            const className = tooltipText === nameText?"name":tooltipText===statusText?"status":tooltipText===posX?"location-x":"location-y";
            const y = tooltipText === nameText?5:tooltipText===statusText?22:tooltipText===posX?39:56;
            const textData = tooltipText === nameText?name:tooltipText===statusText?status:tooltipText===posX?x:y;

            tooltipText = tooltip.selectAll("." + className)
                            .data(textData, d=>d);

            tooltipText.enter().append("text")
              .attr("className", className)
              .attr("x", 5)
              .attr("y", y)
              .text(textData);

            tooltipText
              .transition().duration(0)
              .attr("x", 5)
              .attr("y", y)
              .text(textData);

            tooltipText.exit()
              .transition().duration(250)
              .attr("opacity", 0)
              .remove();
          }
          */

      const devDots = plotArea.selectAll(".devDot").data(graphData, function (d) {
        return d.mac_address;
      });

      devDots
        .enter()
        .append("circle")
        .attr("className", "devDot")
        .attr("cx", (d) => xScale(d.x))
        .attr("cy", (d) => yScale(d.y))
        .attr("r", 13)
        .attr("stroke", (d) =>
          d.mac_address === hoveredMac ? "rgba(213, 198, 255, 0.66)" : "none"
        )
        .attr("strokeWidth", (d) => (d.mac_address === hoveredMac ? "2px" : "0"))
        .attr("fill", (d) => dotColor(d.status))
        .on("mouseover", function (dt, d) {
          if (hoveredMac !== d.mac_address) {
            setHoveredMac(d.mac_address);
          }

          /*
              d3.select(this)
                  .transition().duration(250)
                .attr("stroke", "rgba(213, 198, 255, 0.66)")
                .attr("stroke-width", "2px")
                .style("filter", "url(#dropshadow)");*/

          //console.log(d);

          tooltip
            .attr("opacity", 1)
            .attr("transform", "translate(" + (xScale(d.x) - 120) + ", " + yScale(d.y) + ")");

          nameText.text(d.name);
          statusText.text(d.status);
          posX.text(d.x);
          posY.text(d.y);
        })
        .on("mousemove", function (d) {})
        .on("mouseout", function (dt, d) {
          /*
              d3.select(this)
                  .transition().duration(250)
                .attr("stroke", "none")
                .attr("stroke-width", 0)
                .style("filter", "none");*/
          if (hoveredMac === d.mac_address) {
            setHoveredMac("");
          }

          tooltip.attr("opacity", 0);
          //plotArea.selectAll(".tooltip").remove();
        });

      devDots
        .transition()
        .duration(250)
        .attr("stroke", (d) =>
          d.mac_address === hoveredMac ? "rgba(213, 198, 255, 0.66)" : "none"
        )
        .attr("strokeWidth", (d) => (d.mac_address === hoveredMac ? "2px" : "0"))
        .attr("cx", (d) => xScale(d.x))
        .attr("cy", (d) => yScale(d.y));

      devDots.exit().transition().duration(250).attr("r", 0).remove();
      /*
          plotArea.selectAll("text.name").remove()
            
          const nameText = plotArea.selectAll(".name")
              .data(graphData.filter(d => d.mac_address === hoveredMac), (d)=>d.mac_address);
  
          nameText.enter()
              .append("text")
              .attr("className", "name")
              .attr("fill", "rgba(213, 198, 255, 0.66)")
              .attr("stroke", "rgba(213, 198, 255, 0.66)")
              .attr("stroke-width", 1)
              .attr("x", d => xScale(d.x) - 115)
              .attr("y", d => yScale(d.y))
              .attr("opacity", 1)
              .style("pointer-events", "none")
              .text(d => d.name);
  
          nameText.transition().duration(250)
              .text(d=>d.name);
  
          nameText.exit().transition().duration(250).remove();
          
         const tooltip = plotArea.selectAll(".tooltip")
            .data([{...tooltipData}], d=>"tooltip");

          tooltip.enter()
              .append("g")
              .attr("className","tooltip") 
              .attr("transform", d =>"translate(" + (xScale(d.x) - 120) + ", " + yScale(d.y) + ")")    
              .attr("fill", "rgba(213, 198, 255, 0.66)")
              .attr("stroke", "rgba(213, 198, 255, 0.66)")
              .attr("stroke-width", 1)
              .style("font-size", 14)
              .style("pointer-events", "none")
              //.attr("opacity", hoveredMac === ""?0:1);
              .attr("opacity", 1);

          tooltip.transition().duration(0)
            .attr("transform", d =>"translate(" + (xScale(d.x) - 120) + ", " + yScale(d.y) + ")")
            .attr("opacity", 1);

          tooltip.exit().remove();

          const nameText = tooltip.selectAll(".name")
            .data(d => d, d=>"name")

          nameText.enter()
              .append("text")
              .attr("className", "name")
              .attr("x", d => xScale(d.x) - 115)
              .attr("y", d => yScale(d.y))
              .text(d => "name");
  
          nameText.transition().duration(250)
              .text(d=>d.name);
  
          nameText.exit().remove();*/

      /*const statusText = tooltip.append("text")
              .attr("className", "status")
              .attr("x", 5)
              .attr("y", 22)
              .text("status")
              
            const posX = tooltip.append("text")
              .attr("className", "location-x")
              .attr("x", 5)
              .attr("y", 39)
              .text("loc x")
              
            const posY = tooltip.append("text")
              .attr("className", "location-y")
              .attr("x", 5)
              .attr("y", 56)
              .text("loc y")*/
    },
    [graphData, width, height, hoveredMac]
  );
  //filter: "url(#dropshadow)"

  return (
    <svg
      ref={ref}
      style={{
        height: height,
        width: width,
        margin: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
};

// how radar circular lines stay round
export const centerFlex = { display: "flex", justifyContent: "center", alignItems: "center" };

const swipeHeight = 89;

export const DecoratedRadar = ({
  data,
  height,
  hoveredMac,
  setHoveredMac,
  filterMac,
  plexyzID,
}) => {
  const graphRef = useRef(null);
  const { width } = useContainerDimensions(graphRef);
  const isMobile = useWindowDimensions().width < 600;

  const radarHeight = !isMobile ? height : height - swipeHeight;
  let filtered = data.filter((x) => x.mac_address.includes(filterMac));
  //51.45833333vw
  return (
    <div
      style={{
        width: "100%",
        height: `${radarHeight}px`,
        position: "absolute",
        left: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: "var(--dark)",
        borderRadius: 10,
      }}
      ref={graphRef}
      id="radar"
    >
      {width > 0 && height > 0 && (
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform, centerView, state: { scale }, ...rest }) => {
            return (
              <>
                <TransformComponent>
                  <RadarDecor width={width} height={radarHeight} total={data.length}>
                    <RadarChartAlt
                      graphData={filtered}
                      width={width}
                      height={radarHeight}
                      hoveredMac={hoveredMac}
                      setHoveredMac={setHoveredMac}
                      isMobile={isMobile}
                      centerView={centerView}
                      scale={scale}
                    />
                  </RadarDecor>
                </TransformComponent>
                <ZoomButtons
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  centerView={centerView}
                  resetTransform={resetTransform}
                />
              </>
            );
          }}
        </TransformWrapper>
      )}
      <DeviceCountMobile count={data.length} isMobile={isMobile} plexyzID={plexyzID} />
    </div>
  );
};

let centerRadius = 0;
let radius3 = 0;

// component that responsible for displaying the radar circles
const RadarDecor = ({ width, height, total, children }) => {
  const graphRef = useRef(null);
  const myDimensions = useContainerDimensions(graphRef);
  const lesserDimension = Math.min(myDimensions.width, myDimensions.height);
  const radius1 = 0.75 * lesserDimension;
  const radius2 = 0.5 * lesserDimension;
  radius3 = 0.25 * lesserDimension;
  centerRadius = (143 * lesserDimension) / 720;

  return (
    <div
      style={{
        width,
        height,
        flex: "1 1 auto",
        pointerEvents: "none",
        backgroundColor: "#181818",
        ...centerFlex,
      }}
      ref={graphRef}
    >
      <div
        style={{
          width: `${lesserDimension}px`,
          height: `${lesserDimension}px`,
          borderRadius: `${lesserDimension / 2}px`,
          backgroundColor: "rgba(0,0,0,0)",
          border: "2.16px solid #34267D",
          ...centerFlex,
        }}
      >
        <div
          style={{
            width: `${radius1}px`,
            height: `${radius1}px`,
            borderRadius: `${radius1 / 2}px`,
            backgroundColor: "rgba(0,0,0,0)",
            border: "2.16px solid #34267D",
            ...centerFlex,
          }}
        >
          <div
            style={{
              width: `${radius2}px`,
              height: `${radius2}px`,
              borderRadius: `${radius2 / 2}px`,
              backgroundColor: "rgba(47,33,88,0.4)",
              border: "none",
              ...centerFlex,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const RadarCenter = ({ total, displayCenter, hideCenter, isMobile }) => {
  const [opacity, setOpacity] = useState(1);
  const [pointerEvents, setPointerEvents] = useState("all");

  useEffect(() => {
    if (!displayCenter) {
      setOpacity(0);
      setPointerEvents("none");
    } else {
      setPointerEvents("all");
      setOpacity(1);
    }
  }, [displayCenter]);

  const dblTap = useDoubleTap((e) => {
    e.stopPropagation();
    e.preventDefault();
    hideCenter();
  });

  const displayOpac = displayCenter ? opacity + 0.75 : opacity;

  const desktopCenter = () => {
    return (
      <div
        style={{
          width: `${centerRadius}px`,
          height: `${centerRadius}px`,
          borderRadius: `${centerRadius / 2}px`,
          backgroundColor: "rgba(255,255,255,0.4)",
          border: "none",
          backgroundImage: "url(/images/radarCenter.png",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",

          ...centerFlex,
        }}
      >
        <div
          style={{
            width: `${centerRadius * 0.66}px`,
            height: `${centerRadius * 0.66}px`,
            borderRadius: `${(centerRadius * 0.66) / 2}px`,
            backgroundColor: "rgba(66,38,125,1)",
            border: "none",
            ...centerFlex,
            flexDirection: "column",
          }}
        >
          <h5
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "28px",
              lineHeight: "28px",
              margin: "0px",
              padding: "0px",
              //  identical to box height, or 143%
              color: "#FFFFFF",
            }}
          >
            {total}
          </h5>
          <p
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontize: "9px",
              lineHeight: "9px",
              //  identical to box height

              margin: "8px 0px 0px 0px",
              letterSpacing: "0.45px",
              textTransform: "uppercase",

              color: "#ECE5FF",
            }}
          >
            {total > 1 ? "devices" : "device"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: `${radius3}px`,
        height: `${radius3}px`,
        borderRadius: `${radius3 / 2}px`,
        backgroundColor: `${isMobile ? "#42267D" : "rgba(66,38,125,0.8)"}`,
        // border: `${isMobile ? "1px solid #ffac17" : "none"}`,
        border: "none",
        pointerEvents: pointerEvents,
        cursor: "pointer",
        opacity: displayOpac,
        transition: "all 0.25s",
        ...centerFlex,
      }}
      onMouseEnter={() => {
        setOpacity(0);
      }}
      onTouchStart={() => {
        setOpacity(0);
      }}
      onMouseLeave={() => {
        setOpacity(displayCenter ? 1 : 0);
      }}
      onTouchEnd={() => {
        setOpacity(displayCenter ? 1 : 0);
      }}
      {...dblTap}
    >
      {/* {isMobile ? <p className={styles.beaconLbl}>{total} Device</p> : desktopCenter()} */}
      {desktopCenter()}
    </div>
  );
};

// component that responsible for displaying the radar dots
export const RadarChartAlt = ({
  /*width,*/ height,
  graphData,
  hoveredMac,
  setHoveredMac,
  isMobile,
  scale,
}) => {
  const [displayCenter, setDisplayCenter] = useState(true);
  const [tooltipPosition, setTooltipPos] = useState({ left: 0, top: 0 });

  const { left, top } = tooltipPosition;
  const graphRef = useRef(null);
  const { width } = useContainerDimensions(graphRef, [graphData, height]);
  const myDimensions = useContainerDimensions(graphRef);
  const lesserDimension = Math.min(myDimensions.width, myDimensions.height);
  const tier = localStorage ? parseInt(localStorage.getItem("tier")) : 1;

  const emptyTooltip = { mac_address: "", name: "", status: "", x: 0, y: 0, duration: 0 };
  const tooltipData =
    hoveredMac !== "" ? graphData.filter((d) => d.mac_address === hoveredMac)[0] : emptyTooltip;

  const scaling = 1 / scale;
  useEffect(() => {
    if (hoveredMac !== "") {
      const { x, y } = tooltipData;
      setTooltipPos({
        left: `${xScale(x) - 120 * scaling}px`,
        top: `${yScale(y) + 21 * scaling}px`,
      });
    } else {
      setTooltipPos({ left: 0, top: 0 });
    }
  }, [hoveredMac]);

  const dotR = isMobile ? 7.5 : 13;

  const equalizeDomain = (range) => {
    const min = range[0];
    const max = range[1];

    const absMin = Math.abs(min);

    const newAbsMin = absMin < max ? max : absMin;
    const newMin = newAbsMin * -1;
    const newMax = max < absMin ? absMin : max;

    //return [newMin - (dotR + 15), newMax + (dotR + 15)];
    return [-1000, 1000];
  };

  const margin = { top: 16, right: 16, bottom: 16, left: 16 };

  const baseXDomain = d3.extent(graphData.map((d) => d.x));
  const baseYDomain = d3.extent(graphData.map((d) => d.y));
  const xDomain = equalizeDomain(baseXDomain);
  const yDomain = equalizeDomain(baseYDomain);

  const graphWidth = width - margin.left - margin.right;
  const graphHeight = height - margin.top - margin.bottom;
  // below are the ones responsible for the placement of the dots, both use lesserDimension to prevent both elongation; horizontally and vertically
  const xScale = d3.scaleLinear().domain(xDomain).range([0, lesserDimension]),
    yScale = d3.scaleLinear().domain(yDomain).range([lesserDimension, 0]);

  const displayTooltip = left !== 0 && top !== 0 && hoveredMac !== "";

  const tooltip = () => {
    if (!tooltipData) {
      return;
    }
    const durationText = () => {
      if (typeof tooltipData.duration !== "undefined") {
        return tooltipData.duration + "s";
      }
      return "not available";
    };

    return (
      <div
        style={{
          position: "absolute",
          left,
          top,
          backgroundColor: "#ff00ff",
          width: "auto",
          height: "auto",
          inlineSize: `${scaling * 120}px`,
          overflowWrap: "break-word",
          pointerEvents: "none",
          paddingLeft: `${scaling * 10}px`,
          paddingRight: `${scaling * 10}px`,
          paddingTop: `${scaling * 10}px`,
          paddingBottom: `${scaling * 12}px`,
          borderRadius: `${scaling * 5}px`,
        }}
      >
        <p
          style={{
            lineHeight: `${scaling * 14}px`,
            padding: "0px",
            margin: "0px",
            color: "#ECE6FF",
            fontSize: `${scaling * 12}px`,
          }}
        >
          {tooltipData.name}
        </p>
        <p
          style={{
            lineHeight: `${scaling * 14}px`,
            padding: "0px",
            marginBottom: "0px",
            marginTop: `${scaling * 6}px`,
            color: "#ECE6FF",
            fontSize: `${scaling * 12}px`,
          }}
        >
          {durationText()}
        </p>
        {tier > 1 && (
          <p
            style={{
              lineHeight: `${scaling * 14}px`,
              padding: "0px",
              marginBottom: "0px",
              marginTop: `${scaling * 4}px`,
              color: "#ECE6FF",
              fontSize: `${scaling * 12}px`,
            }}
          >
            ({tooltipData.x}, {tooltipData.y})
          </p>
        )}
      </div>
    );
  };

  const toggleCenter = () => {
    return (
      <div
        className={styles.toggleCenter}
        onClick={() => {
          setDisplayCenter(!displayCenter);
        }}
      >
        <div className={`${styles.centerToggle} ${!displayCenter && styles.hideCenterToggle}`} />
      </div>
    );
  };

  const showCenter = () => {
    if (!displayCenter) {
      setDisplayCenter(true);
    }
  };

  const hideCenter = () => {
    if (displayCenter) {
      setDisplayCenter(false);
    }
  };

  const canRender = checkIfDuplicateExists(graphData, "mac_address") === false;

  return (
    <div
      style={{
        width: "100%",
        height: `${height}px`,
        position: "absolute",
        left: "0px",
        top: "0px",
        pointerEvents: "all",
      }}
      ref={graphRef}
    >
      {/* add div to make svg flex to center */}
      <div
        style={{
          width,
          height,
          flex: "1 1 auto",
          //"pointerEvents":"none",
          ...centerFlex,
        }}
        ref={graphRef}
      >
        {/* use lesserDimension to make it square same as radarDecor */}
        <svg
          style={{
            // height:"100%",
            // width:"100%",
            height: `${lesserDimension}px`,
            width: `${lesserDimension}px`,
            margin: "0px",
            padding: "0px",
          }}
        >
          <defs>
            <filter
              id="filter0_d_3_1615"
              x="-25"
              y="-25"
              width="50"
              height="50"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_3_1615"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.51175 0 0 0 0 0.2125 0 0 0 0 1 0 0 0 0.6 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_1615" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3_1615"
                result="shape"
              />
            </filter>
          </defs>
          <g className="plot-area">
            {width > 0 &&
              height > 0 &&
              canRender &&
              graphData.map((d, i) => {
                // console.log(d.x, xScale(d.x));
                const isHovered = d.mac_address === hoveredMac;
                return (
                  <g
                    key={d.mac_address}
                    transform={`translate(${xScale(d.x)}, ${yScale(d.y)})`}
                    filter={`${isHovered ? "url(#filter0_d_3_1615)" : ""}`}
                  >
                    <circle
                      cx={0}
                      cy={0}
                      r={dotR}
                      stroke={d.mac_address === hoveredMac ? "rgba(213, 198, 255, 0.66)" : "none"}
                      strokeWidth={d.mac_address === hoveredMac ? "2px" : "0"}
                      fill={dotColor(d.status)}
                      onMouseEnter={() => {
                        setHoveredMac(d.mac_address);
                      }}
                      onMouseOut={() => {
                        setHoveredMac("");
                      }}
                    />
                  </g>
                );
              })}
          </g>
        </svg>

        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "0px",
            top: "0px",
            pointerEvents: "none",
            ...centerFlex,
          }}
        >
          <RadarCenter
            total={graphData.length}
            displayCenter={displayCenter}
            hideCenter={hideCenter}
            isMobile={isMobile}
          />
        </div>
        {toggleCenter()}
        {displayTooltip && tooltip()}
      </div>
    </div>
  );
};

const DeviceCountMobile = ({ count, isMobile, plexyzID }) => {
  return (
    <>
      {isMobile && (
        <div className={styles.deviceCount}>
          <p className={styles.countInfo}>
            <span className={styles.countNum}>{count + " "}</span>
            device
            {count > 0 && "s"} connected to {plexyzID}
          </p>
        </div>
      )}
    </>
  );
};

const ZoomButtons = ({ zoomIn, zoomOut, centerView, resetTransform }) => {
  const [collapse, setCollapse] = useState(false);
  //additional state to prevent color indicator open at first time component is loaded;
  const [firstCollapseClick, setFirstClick] = useState(false);
  // const { openAll, collapseAll, allWidgetCollapsed, fullwidthVisualization } =
  //   useContext(TopControlContext);

  // useEffect(() => {
  //   console.log({ openAll, collapseAll, allWidgetCollapsed, fullwidthVisualization });
  // }, [openAll, collapseAll, allWidgetCollapsed, fullwidthVisualization]);

  // useEffect(() => {
  //   if (firstCollapseClick) {
  //     if (collapse) {
  //       collapseAll();
  //     } else {
  //       console.log({ openAll });
  //       openAll();
  //     }
  //   }
  // }, [collapse]);

  // useEffect(() => {
  //   if (allWidgetCollapsed) {
  //     setCollapse(true);
  //   }
  // }, [allWidgetCollapsed]);

  /*
  useEffect(()=>{
    centerView();
  }, [fullwidthVisualization]);*/

  return (
    <div className={styles.zoomButtons}>
      <div
        className={`${styles.zoomButton} ${tooltipStyles.tooltip}`}
        style={{
          backgroundImage: "url(/images/addButton.png",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => {
          zoomIn();
        }}
      >
        <span className={tooltipStyles.tooltiptext}>Zoom In</span>
      </div>
      <div
        className={`${styles.zoomButton} ${tooltipStyles.tooltip}`}
        style={{
          backgroundImage: "url(/images/substractButton.png",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => {
          zoomOut();
        }}
      >
        <span className={tooltipStyles.tooltiptext}>Zoom Out</span>
      </div>
      <div
        className={`${styles.zoomButton}  ${tooltipStyles.tooltip}`}
        onClick={() => {
          centerView();
        }}
      >
        <img
          src={process.env.PUBLIC_URL + `/images/centerZoom.svg`}
          alt={"center"}
          style={{ width: "55%", height: "55%" }}
        />
        <span className={tooltipStyles.tooltiptext}>Center Radar</span>
      </div>
      <div
        className={`${styles.zoomButton} ${tooltipStyles.tooltip}`}
        onClick={() => {
          resetTransform();
        }}
      >
        <img
          src={process.env.PUBLIC_URL + `/images/reset.svg`}
          alt={"center"}
          style={{ width: "55%", height: "55%" }}
        />
        <span className={tooltipStyles.tooltiptext}>Reset Radar</span>
      </div>
      <div
        className={`${styles.zoomButton} ${tooltipStyles.tooltip}`}
        onClick={() => {
          if (!firstCollapseClick) {
            setFirstClick(true);
          }
          setCollapse(!collapse);
          // console.log('value', collapseSide);
        }}
      >
        <img
          src={
            !collapse
              ? process.env.PUBLIC_URL + `/images/collapse.png`
              : process.env.PUBLIC_URL + `/images/expand.png`
          }
          alt={"center"}
          style={{ width: "40%", height: "40%" }}
        />
        <span className={tooltipStyles.tooltiptext}>
          {collapse ? "Show Widgets" : "Hide Widgets"}
        </span>
      </div>
    </div>
  );
};
