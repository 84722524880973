import React, { useState, useRef, useEffect } from "react";
// import { Button, Modal,ModalHeader, ModalBody,ModalFooter,Label,Input,Row, Col,} from "reactstrap";
import { Modal } from "../../global/Modal";
import "react-toastify/dist/ReactToastify.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import { ToastContainer, toast } from "react-toastify";
import { version } from "../../../../utils/const";
import axios from "axios";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

export const EditUnit = ({data, siteList, areaList, deviceID, setShowEdit, activeItem, refetchUnit}) => {
  const [areaUID, setAreaUID] = useState("");
  const [areaName, setAreaName] = useState("");
  const [siteUID, setSiteUID] = useState("");
  const [siteName, setSiteName] = useState("");
  const [ssid, setSSID] = useState("");
  const [password, setPassword] = useState("");
  const [inputAreaList, setInputAreaList] = useState([]);
  const [coor, setCoor] = useState(true);
  const [xCoor, setXCoor] = useState("0");
  const [yCoor, setYCoor] = useState("0");

  useEffect(() => {
    let str = activeItem.space.unit_address
    let AREA = str.slice(str.indexOf("/") + 1);
    let SITE = str.split("/", 1).toString();
    setAreaName(AREA)
    setSiteName(SITE)
    setSiteUID(activeItem.space.site_uid)
    setAreaUID(activeItem.space.area_uid)
    setSSID(activeItem.space.unit_wifi.ssid)
    setPassword(activeItem.space.unit_wifi.password)
    const x = activeItem.space.unit_coordinate.x
    const y = activeItem.space.unit_coordinate.y
    setXCoor(x)
    setYCoor(y)
    x == 0 & y == 0 ? setCoor(false) : setCoor(true)
    
  }, [])

  const updateXYCoor = (areaUID) => {
    let same_area = data.filter(unit_data => {
      return (unit_data.space?.area_uid === areaUID && unit_data.unit_uid !== deviceID)
    })
    console.log(same_area)
    same_area.length === 0 ? setCoor(false) : setCoor(true); setXCoor(0); setYCoor(0)
  }
  const editSpace = async() => {
    const URL = `${version.expURL}/api2/space/edit_space`;
    var userID = localStorage.getItem("UID");

    if (password === "") {
      toast.error("Wifi Password is required");
      return;
    } else if(ssid === "") {
        toast.error("Wifi SSID is required");
        return;
    } else {
      try {

        var body = {
          user_uid: userID,
          site_uid: siteUID,
          area_uid: areaUID,
          unit_uid: deviceID,
          unit_coordinate: {
            x: parseFloat(xCoor),
            y: parseFloat(yCoor)
          },
          unit_wifi: {
            password: password,
            ssid: ssid
          }
        };
    
        console.log('boo', body);
    
        await axios.post(URL, body).then(async (res) => {
          console.log('edit_binded_unit', res);
          if(res.data.status === 'success'){
            toast.success("Binded unit has been updated!");
            try {
              const mysql_URL = `${version.expURL}/api2/mysql/update_dashboard_mqtt_topic`;
              var mysql_body = {
                user_uid: userID,
                client_id: deviceID,
                unitName: deviceID,
                area_uid: areaUID,
                site_uid: siteUID,
              };
              console.log({ mysql_body });
              let mysql_respond = await axios.post(mysql_URL, mysql_body);
              console.log({ mysql_respond });
            } catch (error) {
              console.log(error);
            }
            refetchUnit();
            setShowEdit(false)
      
          }
        });

      } catch (err) {
        toast.error(err.message);
      }
    }
  }

  const checkSite = (x) => {

    let areass = areaList.filter(area => area.site_uid === x.site_uid)

    if(areass.length === 0){
      setShowEdit(false);
      let MSG = x.site_name  + " has no area yet. Please create at least one area for the site first."
      toast.error(MSG)
      return
      
    } else {
      setAreaName("Select your area name")
      setAreaUID("")
      setSiteName(x.site_name)
      setSiteUID(x.site_uid)
      setInputAreaList(areass)
    }
  }
  
  return (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Edit Binded Unit</h3>
              <div className={modalStyles.addDeviceSelect}>
                <div className={modalStyles.inputContainer}>
                    <h5 className={modalStyles.inputLabel}>Site Name</h5>
                    <div>
                        <SelectBar
                            options={siteList}
                            display='site_name'
                            value={siteName}
                            changeValue={(x)=>{ checkSite(x) }}   
                        />
                    </div>     
                </div>  
              </div>

              <div className={modalStyles.addDeviceSelect}>
                <div className={modalStyles.inputContainer}>
                    <h5 className={modalStyles.inputLabel}>Area Name</h5>
                    <div>
                        <SelectBar2
                            options={inputAreaList}
                            display='area_name'
                            value={areaName}
                            changeValue={(x)=>{
                                setAreaName(x.area_name)
                                setAreaUID(x.area_uid)
                                updateXYCoor(x.area_uid)
                            }}   
                        />
                    </div> 
                </div>
              </div>
              <div className={modalStyles.addDeviceSelect}>
                <div className={modalStyles.inputContainer}>
                    <div>
                    <InputContainer
                        disabled={true}
                        label="Unit's Wifi SSID"
                        inputId="ssid"
                        value={ssid}
                        onChange={(newValue) => {
                        setSSID(newValue);
                        }}
                        type="text"
                        placeholder="eg: WIFI-SSID"
                    />
                    </div>     
                </div>  
              </div> 
              <div className={modalStyles.addDeviceSelect}>
                <div className={modalStyles.inputContainer}>
                    <div>
                    <InputContainer
                        label="Unit's Wifi Password"
                        inputId="password"
                        value={password}
                        onChange={(newValue) => {
                        setPassword(newValue);
                        }}
                        type="text"
                        placeholder="eg: WIFI-PASSWORD_1234"
                    />
                    </div>     
                </div>  
              </div> 

              { coor && 
                            <div>
                                <div className={modalStyles.addDeviceSelect}>
                                    <div className={modalStyles.inputContainer}>
                                        <div>
                                        <InputContainer
                                            label="Unit Coordinate X"
                                            inputId="xCoor"
                                            value={xCoor}
                                            onChange={(newValue) => {
                                            setXCoor(newValue);
                                            }}
                                            type="text"
                                            placeholder="eg: 1.234"
                                        />
                                        </div>     
                                    </div>  
                                </div> 
                                <div className={modalStyles.addDeviceSelect}>
                                    <div className={modalStyles.inputContainer}>
                                        <div>
                                        <InputContainer
                                            label="Unit Coordinate Y"
                                            inputId="yCoor"
                                            value={yCoor}
                                            onChange={(newValue) => {
                                                setYCoor(newValue);
                                            }}
                                            type="text"
                                            placeholder="eg: 101.2345"
                                        />
                                        </div>  
                                    </div>  
                                </div> 

                            </div>
                        }


            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  editSpace();
                }}
              >
                Save Edit Unit
              </div>
              <div
                onClick={() => {setShowEdit(false)}}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
        )
}

const InputContainer = ({
  label,
  inputId,
  icon,
  value,
  onChange,
  type,
  placeholder,
}) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};
const SelectBar = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;
  VALUE = VALUE.replace(/[^a-zA-Z0-9 ]/g, " ");
  VALUE = VALUE.charAt(0).toUpperCase() + VALUE.substring(1);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d.site_name;
            NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SelectBar2 = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;
  VALUE = VALUE.replace(/[^a-zA-Z0-9 ]/g, " ");
  VALUE = VALUE.charAt(0).toUpperCase() + VALUE.substring(1);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d.area_name;
            NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
