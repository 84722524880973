import React from "react";
import { Modal } from "./Modal";
import styles from "../../../styles/plexyz/components/global/loader.module.css";

export const Loader = () => {
    return (
        <Modal>
            <div className={ styles.loader} />
        </Modal>
    )
}