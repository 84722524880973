// TotalReportsTable.js
import React from "react";
import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import styles from "../../../../styles/plexyz/components/pages/overview/overview.module.css";

const TotalReportsTable = ({ tableData }) => (
  <table className={styles.devTable}>
    <tbody>
      {tableData.map((d) => (
        <tr key={d._id} className={`${tableStyles.dataRow}`}>
          {/* Add your table data cells here */}
          <td>{d.total}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default TotalReportsTable;
