import React from 'react';
import { TopNavbar } from "./TopNavbar";
import { SideBar } from "./SideBar";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import styles from "../../../styles/plexyz/layout/layout.module.css";

export const Layout = ({children}) => {    

    return (
        <div className={styles.topWrapper}>
            <div
                id="layoutContainer" 
                className={styles.container}
            >
                <TopNavbar />
                <div className={styles.content}>
                    <div className={styles.leftToolbar}  />
                    {
                        children
                    }
                    <SideBar />
                </div>                
            </div>   
            <div 
                id="swipeContainer" 
                className={styles.swipeContainer} 
            />         
            <div 
                id="modalContainer"
                className={styles.modalContainer}
            />
        </div>
    )
}