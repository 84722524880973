import React from 'react';

export const UpgradeLock = () => {
    return (
        <svg width="51" height="68" viewBox="0 0 51 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 47.6714V61.8344C2 64.1338 3.86618 66 6.16558 66H44.5722C46.8716 66 48.7378 64.1338 48.7378 61.8344V35.1747C48.7378 32.8753 46.8716 31.0091 44.5722 31.0091H40.3234V16.9961C40.3234 8.71492 33.6084 2 25.3273 2C17.0461 2 10.3312 8.71492 10.3312 16.9961V31.0091" stroke="#9B85DA" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.1463 23.1106V17.8958C16.1463 12.8805 20.2119 8.81485 25.2273 8.81485C30.2426 8.81485 34.3083 12.8805 34.3083 17.8958V31.1257H5.99896C3.78287 31.1257 2 32.9252 2 35.1247V38.3402" stroke="#9B85DA" strokeWidth="3" strokeiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25.3273 49.7709C27.7475 49.7709 29.7095 47.809 29.7095 45.3888C29.7095 42.9685 27.7475 41.0066 25.3273 41.0066C22.907 41.0066 20.9451 42.9685 20.9451 45.3888C20.9451 47.809 22.907 49.7709 25.3273 49.7709Z" stroke="#9B85DA" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25.3273 57.0524V49.7543" stroke="#9B85DA" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M48.7545 55.5861H42.4229" stroke="#9B85DA" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}