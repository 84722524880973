import React, { useState, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
import { version } from "../../../../utils/const";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { Select, SelectOptions } from "../../global/Select";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { ChevronDownTable } from "../../../../svg-shapes/ChevronDownTable";
import { UpgradeLock } from "../../../../svg-shapes/UpgradeLock";
import { dotColor } from "../../global/functions";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useTablePagination } from "../../../../hooks/useTablePagination";

import styles from "../../../../styles/plexyz/components/pages/user-account/user-account.module.css";

export const UserInfo = ({ data }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [tier, setTier] = useState("");
  const [subscription, setSubscription ] = useState("");
  const [phone, setPhone] = useState("");
  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  useEffect(() => {    
    const username = localStorage.getItem("username")
    setUsername(username);
    const email = localStorage.getItem("email")
    setEmail(email);
    const tier = localStorage.getItem("tier");
    setTier(tier);
    const phone = localStorage.getItem("phone");
    setPhone(phone);
    const subscription = localStorage.getItem("subscription");
    const role = localStorage.getItem("role");

    setSubscription(role.toLocaleLowerCase()==="admin"?"Admin role needs no subscription":subscription?subscription:"");
  }, [])
  
  const DeviceTable = () => {
    let x = localStorage.getItem("tier");
    console.log(x);
    const [formValue, setFormValue] = useState({
      email: "",
      username: "",
      country: "",
      tier: "",
      phoneNo: "",
    });
    const [toggle, setToggle] = useState(false);

    const editData = async () => {
      try {
        console.log("inside_editData");
        setToggle(!toggle);
        console.log(toggle);
        if (toggle === true) {
          setFormValue({
            username: "",
            email: "",
            country: "",
            tier: "",
          });
        }
        if (toggle === false) {
          setFormValue({
            username: "ali",
            email: "test@test.com",
            country: "MY",
            tier: x,
          });
          console.log(formValue);
        }
        // let URL = `${version.expURL}/api/list-of-user`;
        // console.log(URL);

        // let respond = await axios.get(URL);
        // console.log({ respond });
        // let email = localStorage.getItem("email");

        // setEmail(email);
        // setName("Ali");
        // setCountry("MY");
      } catch (error) {
        console.log(error);
      }
    };

    const updateData = async () => {
      try {
        let URL = `${version.expURL}/api/auth/update_user_data `;
        console.log(URL);
        let payload = {
          username: username,
          email: email,
          country: country,
        };
        let respond = await axios.post(URL, payload);
        console.log(respond);
      } catch (error) {
        console.log(error);
      }
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;

      setFormValue((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });

      //   this.setState({
      //     [name]: value,
      //   });
      // this.handlePasswordChanges();
    };

    // const editProfile = () => {

    // }


    const { email, username, country, tier, phoneNo } = formValue;
    return (
      <table className={styles.devTable}>
        <tbody>
          <tr>
            <td>
              <h4 className={styles.title}>Name</h4>
            </td>
            <td>
              <input
                style={{ color: "black" }}
                placeholder="name"
                disabled={toggle}
                name="username"
                value={username}
                onChange={handleInputChange}
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <h4 className={styles.title}>Email</h4>
            </td>
            <td>
              <input
                style={{ color: "black" }}
                placeholder="email"
                disabled={toggle}
                name="email"
                value={email}
                onChange={handleInputChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <h4 className={styles.title}>Country</h4>
            </td>
            <td>
              {" "}
              <input
                style={{ color: "black" }}
                placeholder="country"
                disabled={toggle}
                name="country"
                value={country}
                onChange={handleInputChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <h4 className={styles.title}>Tier</h4>
            </td>
            <td>
              {" "}
              <input
                style={{ color: "black" }}
                placeholder="tier"
                disabled={toggle}
                name="tier"
                value={tier}
                onChange={handleInputChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <h4 className={styles.title}>Phone Number</h4>
            </td>
            <td>
              {" "}
              <input
                style={{ color: "black" }}
                placeholder="013245678"
                disabled={toggle}
                name="phoneNo"
                value={phoneNo}
                onChange={handleInputChange}
              ></input>
            </td>
          </tr>
          <td>
            <button style={{ color: "black" }} onClick={() => editData()}>
              Edit data
            </button>
          </td>
          <td>
            <button style={{ color: "black" }} onClick={() => updateData()}>
              Update data
            </button>
          </td>

        </tbody>
      </table>
    );
  };

  const updateData = async () => {
    try {
      let URL = `${version.expURL}/api/auth/update_user_data `;
      console.log(URL);
      let payload = {
        username: username,
        email: email,
        phone: phone,
      };
      await axios.post(URL, payload).then(res=>{
        console.log(res);
        toast.success("Profile has been updated");
      })
      .catch(err=>{
        console.log(err);
        toast.error("Error when updating");
      });
      localStorage.setItem("username", username)      
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <h3 className={styles.boxTitle}>
          Profile
        </h3>
        <div className={styles.boxDescription}>
          Profile Settings for your dashboard experience
        </div>
      </div>
      
      <ToastContainer />

      <InputContainer
        label="Name"
        inputId="username"
        value={username}
        onChange={(newValue) => {
          setUsername(newValue);
        }}
        type="text"
        placeholder="eg: Ali, Muthu, Ah Hock"
      />

      <InputContainer
        label="Email"
        inputId="email"
        value={email}
        disabled={true}
        type="text"
        placeholder="Type your Username"
      />
      <InputContainer
        label="Tier"
        inputId="tier"
        value={tier}
        disabled={true}
        type="text"
        placeholder="user tier"
      />
      <InputContainer
        label="Subscription Status"
        inputId="subStatus"
        value={subscription!==""?subscription:"No subsciption data"}
        disabled={true}
        type="text"
        placeholder="Subscription Status"
      />
      <InputContainer
        label="Contact Number"
        inputId="phone"
        value={phone}
        onChange={(newValue) => {
          setPhone(newValue);
        }}
        type="text"
        placeholder="eg: 012-34567890"
      />

      {/* <InputContainer
        label="Change Password"
        inputId="email"
        value={email}
        disabled={true}
        type="text"
        placeholder="Type your new password"
      /> */}

      {/* <div className={styles.tableContainer}>
        {
          <>
            <TableScrollbar>{DeviceTable()}</TableScrollbar>

            
          </>
        }
      </div> */}
      
      <div className={styles.editButton} onClick={(e)=>{updateData(e);}} >
        <span className={styles.editText}>
            Update Profile 
        </span>
      </div>

    </div>
  );
};

export const MobileTablePagination = ({
  itemCount,
  totalItem,
  page,
  maxPage,
  prevPage,
  nextPage,
}) => {
  return (
    <>
      <span className={styles.topBorder} />
      <div className={styles.pageControl}>
        <div className={styles.pageInfo}>
          {`${itemCount} out of ${totalItem} device${totalItem > 1 && "s"}`}
        </div>
        <div className={styles.pageButtons}>
          <div className={styles.pageButton} onClick={prevPage}>
            <img
              style={{ opacity: `${page === 1 ? 0.3 : 1}` }}
              src={process.env.PUBLIC_URL + `/images/prev.svg`}
              alt={"prev"}
            />
          </div>
          <div
            style={{ opacity: `${page === maxPage ? 0.3 : 1}` }}
            className={styles.pageButton}
            onClick={nextPage}
          >
            <img
              src={process.env.PUBLIC_URL + `/images/next.svg`}
              alt={"next"}
            />
          </div>
        </div>
      </div>
    </>
  );
};


const InputContainer = ({
  label,
  inputId,
  icon,
  disabled,
  value,
  onChange,
  type,
  placeholder,
}) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      disabled = {disabled !== undefined ? disabled : false}
      className={disabled !== undefined ? styles.inputDisabled : styles.inputBox}
    />
  );
  return (
    <div className={styles.inputContainer}>
      <h5 className={styles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};



