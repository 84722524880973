import React, { useState, useRef, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
import { Input } from "reactstrap";
import { Modal } from "../../global/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";

import { version } from "../../../../utils/const";
import axios from "axios";

import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import lockStyles from "../../../../styles/plexyz/components/pages/dashboard/upgrade-lock.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import searchstyles from "../../../../styles/plexyz/components/pages/dashboard/nearest-dev.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

export const AdminUnit = ({ data, user, refetchUnit }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showInfoAfter, setShowInfoAfter] = useState(false);

  const [unitType, setUnitType] = useState("test");
  const [unitID, setUnitID] = useState("");
  const [unitName, setUnitName] = useState("");
  const [macAddress, setMAC] = useState("");
  const [ssid, setSSID] = useState("REKA ACCESS POINT");
  const [password, setPassword] = useState("workhardStayHumble!");
  const [mouseOver, setMouseOver] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const [details, setDetails] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [ isOpen, setIsOpen ] = useState(true);
  const [filterString, setFilterString] = useState("");
  const [generatedId, setGeneratedId] = useState("");
  const [otp, setOtp] = useState("");
  const [pubsubUsername, setpubsubUsername ] = useState("")
  const [pubsubPassword, setpubsubPassword ] = useState("")

  const openDets = (d) => {
    // console.log('dets_unit', d);
    setUnitID(d.unit_uid);
    setUnitName(d.device_id);
    setShowDialog2(true);
  };

  const deviceTable = () => {

    let filtered = data.filter(x => x.device_id.toLowerCase().includes(filterString.toLowerCase()));
    let DATA = filtered

    return (
      <table className={styles.devTable}>
        <thead>
          <tr className={styles.tableHeader}>
            <th>Unit Details</th>
            <th style={{textAlign:'center'}} >Wifi Details</th>
            <th  style={{textAlign:'center'}}>Location</th>
            {/* <th>User Email</th> */}
            <th>Status</th>
            <th style={{textAlign:'center'}} >Action</th>
          </tr>
        </thead>
        <tbody>
          {DATA.map((d, i) => {
            var NAME, USER;
            if (d.space !== null) {
              NAME = d.space.unit_address;
              NAME = NAME.replace("/", " , ");
              NAME = NAME.replace("_", "  ");
              // replace(/[^a-zA-Z0-9 ]/g, " ");
            } else {
              NAME = "NA";
            }

            if (d.user_uid !== null) {
              for (const key in user) {
                if (d.user_uid === user[key].user_uid) {
                  USER = user[key].email;
                }
              }
            } else {
              USER = "NA";
            }

            return (
              <tr key={i}>
                <td className={mouseOver && activeRow===i ? styles.dataRowHighlight : null}>
                  <br/> {d.device_id} ({d.device_otp}) <br/>  
                  UNIT UID : {d.unit_uid} <br/> 
                  USER :  {USER} 
                  <br/><br/>
                </td>
                <td className={mouseOver && activeRow===i ? styles.dataRowHighlight : null} style={{textAlign:'center'}}>SSID: {d.wifi_ssid ? d.wifi_ssid : 'NA' } <br/>  PASS: {d.wifi_password ? d.wifi_password : 'NA'} </td>
                <td className={mouseOver && activeRow===i ? styles.dataRowHighlight : null} style={{ minWidth: "66px", textAlign:'center' }}>{NAME} </td>
                {/* <td className={mouseOver && activeRow===i ? styles.dataRowHighlight : null}>{USER} </td> */}
                <td className={mouseOver && activeRow===i ? styles.dataRowHighlight : null}>Offline</td>
                <td className={mouseOver && activeRow===i ? styles.dataRowHighlight : null}>
                  <button
                  className={styles.editButton}
                  onClick={() => {
                    openDets(d);
                  }}
                  onMouseEnter={() => handleMouseOver(i)}
                  onMouseLeave={handleMouseLeave}
                >
                  {" "}
                  DELETE{" "}
                </button>
                <button
                  className={styles.editButton}
                  onClick={() => {
                    setShowInfo(true)
                    setDetails(d);
                  }}
                  onMouseEnter={() => handleMouseOver(i)}
                  onMouseLeave={handleMouseLeave}
                >
                  {" "}
                  INFO{" "}
                </button>
                </td>
                {/* <td className={styles.editButton} onClick={() => { openDets(d)}}> Edit </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // ========= FUNC =========
  const handleMouseOver = (i) => {
    setMouseOver(true)
    setActiveRow(i)
  }
  const handleMouseLeave = () => {
    setMouseOver(false)
    setActiveRow(0)
  }
  const addUnit = async () => {
    setShowDialog(false);
    const URL = `${version.expURL}/api2/admin/create_unit`;
    const mysql_URL = `${version.expURL}/api2/mysql/create_unit_mqtt_credential`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      unit_id: unitName,
      type: unitType,
      mac_address: macAddress,
      wifi_ssid: ssid,
      wifi_password: password,
    };

    let respond = await axios.post(URL, body);
    console.log({respond});
    if (respond !== null || respond !== undefined) {
      if (respond.data.status === "success") {
        refetchUnit();
        // cancelAddUnit();
        try {
          var mysql_body = {
            unit_id: unitName,
            user_uid: userID,
            unit_uid: respond.data?.unit_uid
          };

          let generated_client_id = `${unitName}_${respond.data.unit_uid}`;
          setGeneratedId(generated_client_id);
          setUnitName(unitName)
          setUnitID(respond.data.unit_uid);
          setOtp(respond.data.otp)

          let mySQL_respond = await axios.post(mysql_URL, mysql_body);
          console.log({ mySQL_respond });
          if (mySQL_respond !== null || respond !== undefined) {
            let msg=  "Unit " + unitName +  " has been created and added to inventory"
            setpubsubUsername(mySQL_respond.data.pubsub.username)
            setpubsubPassword(mySQL_respond.data.pubsub.password)
            toast.success(msg);
            setShowInfoAfter(true)
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        let msg=  "Unit " + unitName +  " has already been created. Please use a different ID"
        toast.error(msg);
        cancelAddUnit();
      }
    }

    console.log("create_new_device", respond);
  };

  const editUnit = async () => {
    setShowDialog(false);
    const URL = `${version.expURL}/api2/admin/admin_edit_unit`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      unit_uid: unitID,
      mac_address: macAddress,
      wifi_ssid: ssid,
      wifi_password: password,
    };

    


    let respond = await axios.post(URL, body);
    console.log({respond});
    if (respond !== null || respond !== undefined) {
      if (respond.data.status === "success") {
        toast.success("Unit has been successfully edited");
        refetchUnit();
        cancelAddUnit();
      } else {
        toast.error(respond.error);
        cancelAddUnit();
      }
    }

    console.log("edit_device", respond);
  };

  const checkDevice = async (event) => {
    event.preventDefault();
    if (unitName === "") {
      toast.error("Unit ID is required");
      return;
    } else if (macAddress === "") {
      toast.error("MAC Address is required");
      return;
    } else if (ssid === "") {
      toast.error("Wifi SSID is required");
      return;
    } else if (password === "") {
      toast.error("Wifi Password is required");
      return;
    } else {
      try {
        await addUnit();
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const checkEdit = async (event) => {
    event.preventDefault();
    if (unitID === "") {
      toast.error("Unit ID is required");
      return;
    } else if (macAddress === "") {
      toast.error("MAC Address is required");
      return;
    } else if (ssid === "") {
      toast.error("Wifi SSID is required");
      return;
    } else if (password === "") {
      toast.error("Wifi Password is required");
      return;
    } else {
      try {
        await editUnit();
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const cancelAddUnit = () => {
    setShowDialog(false);
    setShowDialog2(false);
    setShowInfoAfter(false);
    setUnitID("");
    setUnitType("test");
    setUnitName("");
    setMAC("");
    setSSID("REKA ACCESS POINT");
    setPassword("workhardStayHumble!");
    setShowEdit(false);
    setGeneratedId("");
    setOtp("");
  };

  const deleteUnit = async () => {
    try {

      setShowDialog2(false);
      const URL = `${version.expURL}/api2/admin/delete_unit`;
      const mysql_URL = `${version.expURL}/api2/mysql/delete_unit`
      var userID = localStorage.getItem("UID");
  
      var body = {
        user_uid: userID,
        unit_id: unitID,
        device_id: unitName,
      };
  
      let respond = await axios.post(URL, body);
      let mySQL_respond = await axios.post(mysql_URL, body)
  
      if(mySQL_respond !== null || mySQL_respond !== undefined) {
        console.log( "respond",mySQL_respond);
        if (respond !== null || respond !== undefined) {
          if (respond.data.status === "success") {
            refetchUnit();
            cancelAddUnit();
            let msg=  "Unit " + unitName +  " has been deleted"
            toast.error(msg);
          } else {
            cancelAddUnit();
            // let msg=  "Unit " + unitName +  " currently binded to a site"
            toast.error(respond.data.message);
          }
        console.log("remove_new_device", respond);
  
        }
      }
      else {
        console.log("error with mysql respond");
      }
      
    } catch (error) {
      console.log(error); 
    }
  };

  return (
    <div className={styles.container} style={{marginTop:15}}>
      <div className={styles.topBar}>
        <h4 className={styles.title}>All Units</h4>

        <div className={styles.rightSide} >
          <div className={searchstyles.inputContainer} style={{justifyContent:'flex-end', alignItems:'center'}}>
            <input 
                className={`${searchstyles.input} ${!isOpen && searchstyles.closed}`}  style={{color:'#000'}}
                placeholder="Search device"
                value={ filterString }                        
                onChange={(x)=>{
                    setFilterString(x.target.value)}
                }
                type="text"
            /> 
            <img 
                style={{width:25, height:25}}
                className={searchstyles.searchIcon}
                onClick={()=>{
                    setIsOpen(!isOpen);
                }}
                src = {process.env.PUBLIC_URL + "/images/topNavbar/searchIcon.svg"} 
                alt="Search"
            />    
          </div>
          <div
            className={`${modalStyles.basicButton} ${modalStyles.active}`}
            onClick={() => setShowDialog(true)}
          >
            <p className={modalStyles.buttonTitle}>Add unit</p>
          </div>
        </div> 

        
      </div>
      <ToastContainer />

      <div className={styles.tableContainer}>
        {
          <>
            <TableScrollbar>{deviceTable()}</TableScrollbar>
          </>
        }
      </div>

      {showDialog && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Add Unit</h3>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Unit ID"
                inputId="unitID"
                value={unitName}
                onChange={(newValue) => {
                  let val = newValue.toUpperCase()
                  setUnitName(val);
                }}
                type="text"
                placeholder="eg: PLEXYZ0000, PLEXYZ1234"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <div className={modalStyles.inputContainer}>
                <h5 className={modalStyles.inputLabel}>Unit Type</h5>
                <div>
                    <SelectBar
                        options={["test", "basic"]}
                        display='site_name'
                        value={unitType}
                        changeValue={(x)=>{
                          setUnitType(x)
                        }}   
                    />
                </div> 
              </div>
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="MAC Address"
                inputId="macAddress"
                value={macAddress}
                onChange={(newValue) => {
                  setMAC(newValue);
                }}
                type="text"
                placeholder="Type the unit's MAC Address"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Wifi SSID"
                inputId="ssid"
                value={ssid}
                onChange={(newValue) => {
                  setSSID(newValue);
                }}
                type="text"
                placeholder="eg: WIFI-SSID"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Wifi Password"
                inputId="password"
                value={password}
                onChange={(newValue) => {
                  setPassword(newValue);
                }}
                type="text"
                placeholder="eg: WIFI-PASSWORD-123"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  checkDevice(e);
                }}
              >
                Add Unit
              </div>
              <div
                onClick={cancelAddUnit}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showEdit && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Edit Unit</h3>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="MAC Address"
                inputId="macAddress"
                value={macAddress}
                onChange={(newValue) => {
                  setMAC(newValue);
                }}
                type="text"
                placeholder="Type the unit's MAC Address"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Wifi SSID"
                inputId="ssid"
                value={ssid}
                onChange={(newValue) => {
                  setSSID(newValue);
                }}
                type="text"
                placeholder="eg: WIFI-SSID"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Wifi Password"
                inputId="password"
                value={password}
                onChange={(newValue) => {
                  setPassword(newValue);
                }}
                type="text"
                placeholder="eg: WIFI-PASSWORD-123"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  checkEdit(e);
                }}
              >
                Edit Unit
              </div>
              <div
                onClick={() => {cancelAddUnit(); setDetails("")}}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showDialog2 && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Delete Unit</h3>
            <br />
            <h3 className={modalStyles.addDeviceTitle}>
              Are you sure you want to delete {unitName} ?
            </h3>
            <h4 className={modalStyles.addDeviceTitle}>
              Do note that this action cannot be undo.<br></br> You'll have to
              add a new one if you accidentally deleted it.
            </h4>
            <br />

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  deleteUnit(e);
                }}
              >
                Confirm Delete
              </div>
              <div
                onClick={cancelAddUnit}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showInfo && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Unit Details</h3>
            <br />
            <p className={modalStyles.details}>
              Unit: {details.device_id} <br/>
              OTP: {details.device_otp} <br/>
              UID: {details.unit_uid} <br/>
              MAC Address: {details.mac_address ? details.mac_address : 'NA'} <br/><br/>

              User UID: {details.user_uid ? details.user_uid : 'NA' }<br/>
              Unit Location: {details.space ? details.space.unit_address : 'NA' }<br/>
              Wifi SSID: {details.wifi_ssid?details.wifi_ssid:'NA'} <br/>
              Wifi Password: {details.wifi_password?details.wifi_password:'NA'}<br/>
              Date binded: {details.timestamp_binded?details.timestamp_binded.substring(0, details.timestamp_binded.indexOf("T")):'NA' }
            </p>
            <div className={modalStyles.addDeviceSelect}>
              <div className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceFull}`}
                onClick={() => {
                  setShowInfo(false);
                  setShowEdit(true);
                  setUnitID(details.unit_uid);
                  setMAC(details.mac_address);
                  setSSID(details.wifi_ssid);
                  setPassword(details.wifi_password);
                }}>
                  Edit
              </div>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceFull}`}
                onClick={() => {
                  setDetails("");
                  setShowInfo(false)
                }}
              >
                Okay
              </div>
              
            </div>
            
          </div>
        </Modal>
      )}

      {showInfoAfter && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Update Stickers/ Hardware Credentials</h3>
            <br />
            <p className={modalStyles.details}>
              Unit: {unitName} <br/>
              OTP: {otp} <br/>
              UID: {unitID} <br/>
              MAC Address: {macAddress} <br/><br/>

              Wifi SSID: {ssid} <br/>
              Wifi Password: {password}<br/>
              Pubsub Username: {pubsubUsername}<br/>
              Pubsub Password: {pubsubPassword}<br/>
              Client ID: {generatedId}
            </p>
            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceFull}`}
                onClick={() => {
                  cancelAddUnit()
                  setShowInfoAfter(false)
                }}
              >
                Okay
              </div>
              
            </div>
            
          </div>
        </Modal>
      )}
    </div>
  );
};

const InputContainer = ({
  label,
  inputId,
  icon,
  value,
  onChange,
  type,
  placeholder,
  disabled,
}) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
      disabled={disabled ? disabled : false}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};

const SelectBar = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d;

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
