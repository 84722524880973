import React, { useEffect, useState } from "react";
// import { ConnectionSpeed } from "./ConnectionSpeed";
// import { UnitConnection } from "./UnitConnection";

export const ConnectionMeasure = ({ socketTicks }) => {    

    return (
        <div style={{display:"flex", flexWrap:"wrap", backgroundColor:"var(--dark)", height: 300, padding:"20px 25px 30px"}}>            
            {/* <ConnectionSpeed /> */}
            {/* <UnitConnection socketTicks={socketTicks} /> */}
        </div>
    )
}