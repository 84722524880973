// TotalUnitsTable.js
import React from "react";
import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import styles from "../../../../styles/plexyz/components/pages/overview/overview.module.css";

const TotalUnitsTable = ({ tableData }) => {
  // Helper function to count occurrences of device IDs
  const countDeviceOccurrences = (data) => {
    const deviceCount = {};
    data.forEach((item) => {
      const deviceId = item.device_id === "None" ? "Unknown" : item.device_id;
      deviceCount[deviceId] = (deviceCount[deviceId] || 0) + 1;
    });
    return deviceCount;
  };

  // Calculate device occurrences
  const deviceOccurrences = countDeviceOccurrences(tableData);

  // Calculate the total sum of occurrences
  const totalOccurrences = Object.values(deviceOccurrences).reduce((sum, count) => sum + count, 0);

  return (
    <table>
      <tbody>
        <tr>
          {/* <td className={`${styles.centeredText} ${styles.increasedTextSize}`}> */}
          <td>{totalOccurrences}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalUnitsTable;
