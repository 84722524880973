import React from 'react';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import styles from "../../../../styles/plexyz/components/pages/dashboard/peak-hours.module.css";

export const PeakHours = ({peak_12to1, peak_4to7}) => {
    return (
        <div className={styles.container}>
            <h4 className={styles.title} >
                Peak Hours
            </h4>
            <p className={styles.about}>
                Number of visitors and its statistics
            </p>
            <VisitorVis visColor="blue" myPeak={peak_12to1} />
            <VisitorVis visColor="yellow" myPeak={peak_4to7} />
        </div>
    )
}

const VisitorVis = ({visColor, myPeak}) => {
    const { width } = useWindowDimensions();
    const isMobile = width <= 600;

    const dividePeakBy = 5000;
    const timeText = visColor === "blue"?"12 pm - 1 pm":"4 pm - 7 pm";
    const percentage = myPeak > 0?(myPeak/dividePeakBy*100).toFixed(1):0;
    const percentageText = percentage + "%";

    return (
        <div className={styles.visitorVis}>
            <div className={styles.visContainer}>
                <p className={styles.time}>
                {
                    timeText
                }
                </p>
                <div className={styles.vis}>
                    <div className={styles.barContainer}>
                        <div 
                            className={`${styles.bar} ${visColor==="blue"?styles.blueBar:styles.yellowBar}`} 
                            style={{width:`${percentage}%`, transition:"all 0.25s"}}
                        />
                    </div>
                    <h6 className={styles.percentage}>
                    {
                        percentageText
                    }
                    </h6>
                </div>
                <p className={styles.content}>{myPeak > 0?myPeak:0} visitors</p>
            </div>
            {
                width > 1190 &&
                <div className={styles.legendContainer}>
                {
                    ["blue", "yellow", "white"].map(d => <DotLegend color={d} key={d} />)
                }
                </div>
            }            
        </div>
    )
}

const DotLegend = ({color}) => {
    
    const textContent = `${color==="blue"?"3000":color==="yellow"?"2000":"1000"} visitors`;

    return (
        <div className={styles.dotLegend}>
            <div className={`${styles.dot} ${color==="blue"?styles.blueDot:color==="yellow"?styles.yellowDot:styles.whiteDot}`} />
            <p className={styles.content}>
            {
                textContent
            }
            </p>
        </div>
    )
}