import React, { useState, useEffect } from "react";
import styles from "../../../../styles/plexyz/components/pages/analytics/analytics.module.css";
import axios from "axios";
import { Client, connect as mqttConnect } from "mqtt";
import { Login } from "../../../../constant";
import filter from "lodash.filter";

export const Analytics = () => {
  const [clientMQTT, setClientMQTT] = useState(null);
  const [test, setTest] = useState("not connect yet");
  const [areaUID, setAreaUID] = useState(null);
  const [siteUID, setSiteUID] = useState(null);
  const [listData, setListData] = useState([]);
  const [lastData, setLastData] = useState([]);

  const _mqttConnect = () => {
    console.log("doing connect");
    let host2 = "ws://mqtt.svc.reka.tech:8080/mqtt";
    let options2 = {
      username: process.env.REACT_APP_MQTT_USERNAME,
      password: process.env.REACT_APP_MQTT_PASSWORD,
      clientId: process.env.REACT_APP_MQTT_CLIENTID,
      keepalive: 60,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: false,

      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
    };
    console.log(host2, options2);
    const client3 = mqttConnect(host2, options2);
    setTest("connected");
    setClientMQTT(client3);
    console.log(client3);
    // console.log(clientMQTT);
  };

  useEffect(() => {}, [listData]);

  useEffect(() => {
    try {
      console.log(clientMQTT);
      console.log(areaUID, siteUID);
      let array = [];
      let data_before_1_min = [];

      if (clientMQTT) {
        try {
          clientMQTT.on("error", () => {
            console.log("error");
          });

          clientMQTT.on("close", () => {
            console.log("close");
          });

          clientMQTT.on("end", () => {
            console.log("end");
          });

          clientMQTT.on("reconnect", () => {
            console.log("reconnect");
          });

          clientMQTT.on("connect", () => {
            console.log("Client connected");
          });

          // clientMQTT.subscribe("#", { qos: 0 }, () => {
          //     console.log("connect");
          // });
          let mqttDistanceTopic = `distance/${siteUID}/${areaUID}`;
          let mqttPositionTopic = `position/${siteUID}/${areaUID}`;
          console.log(mqttDistanceTopic);
          clientMQTT.subscribe(
            // mqttDistanceTopic,
            "raw/site_76e7a239-c433-4e4c-ba46-c1e5ceb09f3c/area_638c04e5-5d28-4d58-ad8e-1f056d6b6750",
            // "raw/site_d3023b8c-305a-4365-bb2e-cdf2287e7714/area_8e2216bb-5b07-4072-ac0f-646089d191d8",
            // "$SYS",
            // "#",
            // "test/1",
            { qos: 0 },
            (err, granted) => {
              if (err) {
                console.log(err);
              } else {
                console.log("granted", granted);
                console.log("topic", granted[0].topic);
              }
              console.log("subscribe");
            }
          );


          clientMQTT.on("message", (topic, message, packet) => {
            let MSG = message.toString()
            // // console.log(MSG)
            // // console.log(MSG.timestamp_)
            // // console.log(typeof(MSG))
            // // console.log(JSON.parse(MSG))
            let now = Date.now()
            // console.log(now)
            let min = 1
            let one_min = now - (1000 * 60 * min)
            // // console.log(one_min)

            let MGS_json = JSON.parse(MSG)
            // console.log(MGS_json.timestamp_, MGS_json)

            let msg_in = parseInt(MGS_json.timestamp_) * 1000
            console.log(now, msg_in  , one_min)

            // if (msg_in === NaN){
            //   // 
            // } else {
            let new_data = {
              timestamp : msg_in/1000
            }
              if ( msg_in > one_min) {
                console.log("within min", (now - msg_in)/1000, "sec")
                setListData(msg => [...msg, new_data])
                setLastData(msg_in)
              } else {
                console.log("older than min", (one_min - msg_in)/1000, "sec")
              }
            // }

            // setListData(msg => [...msg, MGS_json.timestamp_])




            // setListData([...listData, MGS_json.timestamp_]);
            // console.log(
            //   "Received Message: " + message.toString() + "\nOn topic: " + topic
            // );
            let json_data = JSON.parse(message);
            // console.log(json_data);





            let before_1_minute = new Date() - 60000;
            let string_value = JSON.stringify(before_1_minute);
            let proper_timestamp = string_value.substring(
              0,
              string_value.length - 3
            );
            // console.log(
            //   json_data.timestamp_,
            //   { before_1_minute },
            //   "--",
            //   +proper_timestamp
            // );

            // console.log(json_data.timestamp_, +proper_timestamp);

            // if (json_data.timestamp_ < +proper_timestamp) {
            //   // console.log(`<_1_min`);
            // } else {
            //   // console.log(`>_1_min`);
            //   // console.log(json_data);
            //   // array.filter(Boolean);
            //   // console.log(array.length);

            //   array.push(json_data);

            //   setListData([...array, array]);
            // }

            // let current_time = new Date().getTime() / 1000;
            // console.log(current_time);

            // if (json_data.timestamp_ < before_1_minute) {
            //   console.log("kurang_1_minit", { json_data }, "---", { array });

            //   array.push(json_data);
            //   // console.log({ array });
            //   data_before_1_min = array;
            //   // console.log(data_before_1_min);
            //   // array.slice(0, 3);
            //   // console.log({ array });

            //   // setInterval(setListData([...array, array]), 60000);
            //   setListData([...array, array]);
            //   console.log(listData);

            //   // renderData(array);
            // } else {
            //   console.log("lebih_drpd_1_minit", { data_before_1_min }, "---", {
            //     array,
            //   });

            //   try {
            //     let cuba_sini = array.map((data, index) => {
            //       console.log(data.timestamp_, "---", +proper_timestamp);
            //       console.log(data.timestamp_ > +proper_timestamp);
            //     });
            //     let test_data = data_before_1_min.filter((array_data) => {
            //       console.log(array_data.timestamp_, "---", +proper_timestamp);
            //       console.log(array_data.timestamp_ < +proper_timestamp);
            //       return array_data.timestamp_ > +proper_timestamp;
            //     });
            //     console.log({ test_data });
            //   } catch (error) {
            //     return error;
            //   }

            // }
          });

          // console.log(clientMQTT);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("no clientMQTT");
        _mqttConnect();
      }
      return () => {
        console.log(clientMQTT);
        if (clientMQTT) {
          clientMQTT.end(() => {
            console.log("disconnect");
          });
        } else {
          console.log("do nothing");
        }
      };
    } catch (error) {
      console.log(error);
    }
  }, [test, clientMQTT]);

  useEffect(async () => {
    try {
      let user_uid = localStorage.getItem("UID");
      // console.log(user_uid);

      let arraySite = [];
      let arrayArea = [];

      let post_data = {
        user_uid: "user_de5ca718-2aa0-427f-9ee8-5c2d4a914c56",
        // user_uid: "user_uid",
      };

      let get_list_site = await axios.post(
        "http://localhost:8080/api2/site/user_site_list",
        post_data
      );
      // console.log( "get_list_site", get_list_site.data);
      get_list_site.data.data.find((data) =>
        arraySite.push(data.site_uid)
      );
      // console.log({arraySite });
      setSiteUID(arraySite[0]);

      let get_list_area = await axios.post(
        "http://localhost:8080/api2/area/user_area_list",
        post_data
      );
      // console.log({ get_list_area });
      get_list_area.data.data.find((data) =>
        arrayArea.push(data.area_uid)
      );
      // console.log({ arrayArea });
      setAreaUID(arrayArea[0]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const renderData = () => {
    try {
      // console.log(listData);
      let array = [];
      let cuba = ["1", "2", "3"];

      array.push(
        <>
          <td>Hello world</td>

          {(listData || []).map((data2, index2) => {
            // console.log("hello");
            // // let new_time = [];
            // let before_1_minute = new Date() - 60000;
            // console.log(data2.timestamp_, "---", before_1_minute);
            // if (data2.timestamp_ > before_1_minute) {
            //   console.log("data_more_than_1_min");
            //   listData.shift();
            //   console.log({ listData });
            // }

            return (
              <>
                <tr key="index2">
                  <td>{`${data2.timestamp_ || []}, ali is here`}</td>
                  {/* <td></td> */}
                </tr>
              </>
            );
          })}
        </>
      );

      return array;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect( () => {
    console.log(listData)
    let now = Date.now()
    console.log(now)
    let filtered = listData.filter(function(value, index, arr) {
      console.log(now, value.timestamp*1000, now - 60000)
      return (value.timestamp * 1000) > (now - 60000) ;
    });
    setListData(filtered)
    console.log(filtered)
    listData.forEach( (data, index) => {
      data.ago = (now-data.timestamp*1000)/1000

    })


  }, [lastData])

  const renderlist = () => {
    let display = []
    listData.forEach( (data,index) => {
      // console.log(data, new Date(data), Date(data))
      // date.push(Date(data))
      // display.push(<li>{Date(data)}, {data}</li>)
      display.push(<li>{(data.timestamp)}, {data.ago} sec ago</li>)
    })
    return display
  }
  return (
    <div className={styles.container}>
      <h3>Welcome to Analytics Page!</h3>
      <h3>raqif test websocket here {test}</h3>
      {/* {renderSiteDropdown()} */}
      {/* <button onClick={() => renderData}>trigger Data</button> */}
      {/* {listData?.map((data, index) => {
        console.log(data);
        return (
          <>
            <tr key={index}>
              <td>{data.timestamp_}</td>
            </tr>
            <tr key="index2 ">
              <td>{data.internet}</td>
            </tr>
          </>
        );
      })} */}
      {/* {renderData()} */}
      {/* {listData} */}
      {renderlist()}
    </div>
  );
};
