import React,{useState} from "react";
import { ChevronDown } from "../../../svg-shapes/ChevronDown";
import styles from "../../../styles/plexyz/components/global/select.module.css";

export const Select = ({options, value, changeValue}) => {
    const [selectionsVisible, setSelectionsVisible] = useState(false);

    return (
        <div className={styles.sortSelection}
            onClick={()=>{setSelectionsVisible(!selectionsVisible);}}
        >
            {value}
            <div className={`${styles.chevronContainer} ${selectionsVisible && styles.selectOpen}`}>
                <ChevronDown />    
            </div>
            {
                selectionsVisible &&
                <div 
                    className={styles.options}
                    onMouseLeave={()=>{setSelectionsVisible(false)}}
                >
                {
                    options.map((d, i)=>{
                        return (
                            <div 
                                className={styles.option} 
                                key={i}
                                onClick={()=>{changeValue(d);}}    
                            >
                            {
                                d
                            }
                            </div>
                        )
                    })
                }
                </div>
            }
            
        </div>
    )
}

export const SelectOptions = ({ options, value, changeValue, name, logThis }) => {
  
    return (
        <select 
            name={name} 
            className={styles.sortSelection}
            value={value}
            onChange={e=>{changeValue(e.target.value)}}            
        >
        {
            options.map((d, i)=>(
                <option className={styles.option} key={i} value={d} >{ d }</option>
            ))
        }   
        </select>
    )
}