import React, {useRef } from 'react';
import { Select, SelectOptions } from "../../../global/Select";
import { DevDemographicVis } from "./DevDemographicVis";
import styles from "../../../../../styles/plexyz/components/pages/dashboard/device-demographic.module.css";

export const DeviceDemographic = () => {

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <h4 className={styles.title}>Device Demographic</h4>
                <Select                    
                    options={["Current Month"]}
                    value={"Current Month"}
                    changeValue={()=>{}}   
                />
            </div>
            <div className={styles.legendRow}>
                {
                    ["purple", "yellow", "white"].map((d)=><Legend color={d} key={d} />)
                }
            </div>
            <div className={styles.demographicVisRow}>
            {
                ["Number of visitors", "Average Time Spent"].map((d, i)=>(
                    <DevDemographicVis title={d} key={i} />
                ))
            }
            </div>
        </div>
    )
}

const Legend = ({color}) => {
    const legendText = color === "purple"?"80%-95%":color === "yellow"?"50%-79%":">49%";
    return (
        <div className={styles.legend}>
            <div className={`${styles.dot} ${color==="purple"?styles.purpleDot:color==="yellow"?styles.yellowDot:styles.whiteDot}`} />
            <div className={styles.legendText}>
            {
                legendText
            }
            </div>
        </div>
    )
}