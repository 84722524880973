import React, { useState, useRef, useEffect, useContext } from "react";
import TableScrollbar from "react-table-scrollbar";
// import { Button, Modal,ModalHeader, ModalBody,ModalFooter,Label,Input,Row, Col,} from "reactstrap";
import { AddUnit } from "./AddUnit";
import { EditUnit } from "./EditUnit";
import { RemoveUnit } from "./removeUnit";
import { UnbindUnit } from "./unbindUnit";

import { Modal } from "../../global/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { version } from "../../../../utils/const";
import axios from "axios";

import { Select, SelectOptions } from "../../global/Select";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { ChevronDownTable } from "../../../../svg-shapes/ChevronDownTable";
import { UpgradeLock } from "../../../../svg-shapes/UpgradeLock";
import { dotColor } from "../../global/functions";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useTablePagination } from "../../../../hooks/useTablePagination";

import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import lockStyles from "../../../../styles/plexyz/components/pages/dashboard/upgrade-lock.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";
import GRID from "../../../../assets/images/grid.svg";
import { ModalBody, ModalHeader } from "reactstrap";
import { createHashHistory } from "history";
import { AdminEditUnit } from "./EditUnitRefactor";

const history = createHashHistory();

const InputContainer = ({ label, inputId, icon, value, onChange, type, placeholder }) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};

export const BeaconList = ({ data, tier, refetchUnit, siteList, areaList }) => {
  const [sortBy, setSortBy] = useState("Status");
  const [sortType, setSortType] = useState("desc");
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [editDialog, setEditDialog] = useState(false);

  const [unitID, setUnitID] = useState("");
  const [unitOTP, setUnitOTP] = useState("");
  const [tableData, setTableData] = useState([]);
  const [unitType, setUnitType] = useState("");
  const [areaUID, setAreaUID] = useState("");
  const [areaName, setAreaName] = useState("");
  const [siteUID, setSiteUID] = useState("");
  const [siteName, setSiteName] = useState("");
  const [unitCoor, setUnitCoor] = useState("");
  const [ssid, setSSID] = useState("");
  const [password, setPassword] = useState("");
  const [inputAreaList, setInputAreaList] = useState([]);
  const [mouseOver, setMouseOver] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const [coor, setCoor] = useState(false);
  const [xCoor, setXCoor] = useState("0");
  const [yCoor, setYCoor] = useState("0");
  const [unitName, setUnitName] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [selectedRemove, setSelectedRemove] = useState(null);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [unbindDialog, setUnbindDialog] = useState(false);

  const role = localStorage.getItem("role");

  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  const distanceRef = useRef(null);
  const visitingRef = useRef(null);
  const speedRef = useRef(null);
  const locationRef = useRef(null);

  const { width: distanceColWidth } = useContainerDimensions(distanceRef, [data]);
  const { width: visitingColWidth } = useContainerDimensions(visitingRef, [data]);
  const { width: speedColWidth } = useContainerDimensions(speedRef, [data]);
  const { width: locationColWidth } = useContainerDimensions(locationRef, [data]);

  useEffect(() => {
    let areass = areaList.filter((area) => area.site_uid === siteUID);
    setInputAreaList(areass);
  }, [siteUID]);

  const toggleSortType = () => {
    setSortType(sortType === "asc" ? "desc" : "asc");
  };

  const statusOrder = ["80-95%", "50-79%", "<=49%"];

  const devicesData = () => {
    let sortedDevices = data.slice();

    if (sortBy === "Type") {
      sortedDevices.sort((a, b) => {
        if (a.name < b.name) {
          return sortType === "asc" ? 1 : -1;
        } else if (a.name > b.name) {
          return sortType === "asc" ? -1 : 1;
        }
        return 0;
      });
    } else if (sortBy === "Status") {
      sortedDevices.sort((a, b) => {
        if (statusOrder.indexOf(a.status) < statusOrder.indexOf(b.status)) {
          return sortType === "asc" ? 1 : -1;
        } else if (statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status)) {
          return sortType === "asc" ? -1 : 1;
        }
        return 0;
      });
    } else if (sortBy === "Distance") {
      sortedDevices.sort((a, b) => {
        if (sortType === "asc") {
          return a.distance - b.distance;
        } else if (sortType === "desc") {
          return b.distance - a.distance;
        }
        return 0;
      });
    } else if (sortBy === "Visiting Time") {
      sortedDevices.sort((a, b) => {
        const durationA = a.duration ? a.duration : 0;
        const durationB = b.duration ? b.duration : 0;
        if (sortType === "asc") {
          return durationA - durationB;
        } else if (sortType === "desc") {
          return durationB - durationA;
        }
        return 0;
      });
    } else if (sortBy === "Speed") {
      sortedDevices.sort((a, b) => {
        const speedA = a.duration ? a.distance / a.duration : 0;
        const speedB = b.duration ? b.distance / b.duration : 0;
        if (sortType === "asc") {
          return speedA - speedB;
        } else if (sortType === "desc") {
          return speedB - speedA;
        }
        return 0;
      });
    } else {
      return sortedDevices;
    }

    return sortedDevices;
  };

  //const tableData = !isMobile?devicesData():devicesData().filter((d, i) => i >= minItemIdx && i <= maxItemIdx);
  // const pageSize = 10;
  // const { page, maxPage, prevPage, nextPage, tableData } = useTablePagination(devicesData(), pageSize);

  // const upgraded = (d) => {
  //     return (
  //         <>
  //             <td><p style={{textAlign:"center"}}>{d.distance + "m"}</p></td>
  //             <td>{ d.duration ? d.duration.toFixed(2) + " " + "s" : "Not available" }</td>
  //             <td>{ d.duration ? ((d.distance/d.duration).toFixed(4) + " " + "m/s ") : "Not available" }</td>
  //             <td>{ `${d.x}, ${d.y}`}</td>
  //         </>
  //     )
  // }

  useEffect(() => {
    // fetchList()
  }, []);

  const handleMouseOver = (i) => {
    setMouseOver(true);
    setActiveRow(i);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
    setActiveRow(0);
  };

  const checkRemove = (d) => {
    setConfirmDialog(true);
    setSelectedRemove(d);
  };

  const checkUnbind = (d) => {
    setUnbindDialog(true);
    setSelectedRemove(d);
  };

  const viewRealtime = (data) => {
    try {
      console.log("dlm_viewRealtime", data.device_id);
      // setPlexyzID(data.device_id);
      // selectData(data.device_id);
      const dataToStore = { viewDevices: true, deviceId: data.device_id };
      localStorage.setItem("viewAccess", JSON.stringify(dataToStore));

      history.push("/dashboard2");
    } catch (error) {
      console.log({ error });
    }
  };

  // const deviceTable = () => {
  //   return (
  //     <table className={styles.devTable}>
  //       <thead>
  //         <tr className={styles.tableHeader}>
  //           <th style={{ width: "15%" }}>
  //             {sortBy === "Type" ? (
  //               <SortableColumn
  //                 sortBy={sortBy}
  //                 sortType={sortType}
  //                 toggleSortType={toggleSortType}
  //               />
  //             ) : (
  //               "Unit ID"
  //             )}
  //           </th>
  //           {/* <th style={{ minWidth: "66px" }}>
  //             {sortBy === "Status" ? (
  //               <SortableColumn
  //                 sortBy={sortBy}
  //                 sortType={sortType}
  //                 toggleSortType={toggleSortType}
  //               />
  //             ) : (
  //               "Unit Name"
  //             )}
  //           </th> */}
  //           {/* <th ref={distanceRef}>
  //                       {
  //                           sortBy === "Distance" ?
  //                           <SortableColumn
  //                               sortBy={sortBy}
  //                               sortType={sortType}
  //                               toggleSortType={toggleSortType}
  //                           />
  //                           :
  //                           "Subscription"
  //                       }
  //                       </th> */}
  //           {/* <th ref={visitingRef}>
  //                       {
  //                           sortBy === "Visiting Time" ?
  //                           <SortableColumn
  //                               sortBy={sortBy}
  //                               sortType={sortType}
  //                               toggleSortType={toggleSortType}
  //                           />
  //                           :
  //                           "Status"
  //                       }
  //                       </th> */}
  //           <th style={{ width: "15%" }}>Space</th>
  //           {/* <th ref={speedRef}>
  //                       {
  //                           sortBy === "Speed" ?
  //                           <SortableColumn
  //                               sortBy={sortBy}
  //                               sortType={sortType}
  //                               toggleSortType={toggleSortType}
  //                           />
  //                           :
  //                           "Type"
  //                       }
  //                       </th> */}
  //           <th style={{ width: "15%" }}>Area</th>
  //           <th style={{ minWidth: "66px" }}>Wifi SSID</th>
  //           <th style={{ width: "15%" }}> </th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {data.map((d, i) => {
  //           var SPACE, AREA, WIFI;
  //           if (d.space !== null) {
  //             if (d.space.unit_address !== null) {
  //               var str = d.space.unit_address;
  //               SPACE = str.split("/", 1).toString();
  //               SPACE = SPACE.replace(/[^a-zA-Z0-9 ]/g, " ");
  //               SPACE = SPACE.charAt(0).toUpperCase() + SPACE.substring(1);

  //               AREA = str.slice(str.indexOf("/") + 1);
  //               AREA = AREA.replace(/[^a-zA-Z0-9 ]/g, " ");
  //               AREA = AREA.charAt(0).toUpperCase() + AREA.substring(1);

  //               WIFI = d.space.unit_wifi ? d.space.unit_wifi.ssid : "-";
  //             }
  //           } else {
  //             SPACE = "-";
  //             AREA = "-";
  //             WIFI = "-";
  //           }

  //           return (
  //             <tr key={i}>
  //               <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
  //                 {d.device_id === "None" ? "Unknown" : d.device_id}
  //               </td>
  //               {/* <td
  //                 className={
  //                   mouseOver && activeRow === i
  //                     ? styles.dataRowHighlight
  //                     : null
  //                 }
  //               >
  //                 Expired
  //               </td> */}
  //               <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
  //                 {SPACE}{" "}
  //               </td>
  //               <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
  //                 {" "}
  //                 {AREA}{" "}
  //               </td>
  //               <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
  //                 {" "}
  //                 {WIFI}{" "}
  //               </td>

  //               <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
  //                 {d.space ? (
  //                   <div style={{ flexDirection: "row", display: "flex" }}>
  //                     <button
  //                       className={styles.editButton}
  //                       onClick={() => {
  //                         openDets(d);
  //                       }}
  //                       onMouseEnter={() => {
  //                         handleMouseOver(i);
  //                       }}
  //                       onMouseLeave={handleMouseLeave}
  //                     >
  //                       {" "}
  //                       EDIT{" "}
  //                     </button>
  //                     <button
  //                       className={styles.editButton}
  //                       onClick={() => {
  //                         checkUnbind(d);
  //                       }}
  //                       onMouseEnter={() => {
  //                         handleMouseOver(i);
  //                       }}
  //                       onMouseLeave={handleMouseLeave}
  //                     >
  //                       {" "}
  //                       UNBIND{" "}
  //                     </button>
  //                   </div>
  //                 ) : (
  //                   <button
  //                     className={styles.editButton}
  //                     onClick={() => {
  //                       checkRemove(d);
  //                     }}
  //                     onMouseEnter={() => {
  //                       handleMouseOver(i);
  //                     }}
  //                     onMouseLeave={handleMouseLeave}
  //                   >
  //                     {" "}
  //                     REMOVE{" "}
  //                   </button>
  //                 )}
  //               </td>
  //             </tr>
  //           );
  //         })}
  //         {/* {
  //                       tableData.map((d, i)=>(
  //                           <tr key={i} className={styles.dataRow}>
  //                               <td>{ d.name === "None"?"Unknown":d.name }</td>
  //                               <td style={{minWidth:"66px"}}>
  //                                   <div
  //                                       style={{
  //                                               display:"flex",
  //                                               flexDirection:"row",
  //                                               justifyContent:"flex-start",
  //                                               alignItems:"stretch",
  //                                               minWidth:"66px"
  //                                           }}
  //                                   >
  //                                       <div
  //                                           className={styles.deviceDot}
  //                                           style={{
  //                                               backgroundColor:dotColor(d.status),
  //                                               marginRight: "8px"
  //                                           }}
  //                                       />
  //                                       {
  //                                           d.status
  //                                       }
  //                                   </div>
  //                               </td>
  //                               {
  //                                   tier>1 && upgraded(d)
  //                               }

  //                           </tr>
  //                       ))
  //                   }                     */}
  //       </tbody>
  //     </table>
  //   );
  // };

  const statusTable = () => {
    return (
      <table className={styles.devTable}>
        <thead>
          <tr className={styles.tableHeader}>
            <th>Status</th>
            <th>Unit ID</th>
            <th>Count</th>
            <th>Deployment Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => {
            var SPACE, AREA, WIFI;
            if (d.space !== null) {
              if (d.space.unit_address !== null) {
                var str = d.space.unit_address;
                SPACE = str.split("/", 1).toString();
                SPACE = SPACE.replace(/[^a-zA-Z0-9 ]/g, " ");
                SPACE = SPACE.charAt(0).toUpperCase() + SPACE.substring(1);

                AREA = str.slice(str.indexOf("/") + 1);
                AREA = AREA.replace(/[^a-zA-Z0-9 ]/g, " ");
                AREA = AREA.charAt(0).toUpperCase() + AREA.substring(1);

                WIFI = d.space.unit_wifi ? d.space.unit_wifi.ssid : "-";
              }
            } else {
              SPACE = "-";
              AREA = "-";
              WIFI = "-";
            }

            return (
              <tr key={i}>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {/* {SPACE} */}
                  {d.status || "Offline"}
                </td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {d.device_id === "None" ? "Unknown" : d.device_id}
                </td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {/* {AREA} */}
                  {d.count || "Not Available"}
                </td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {/* {WIFI} */}
                  {d.deployment_type || "Onsite"}
                </td>

                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  {d.space ? (
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <button
                        className={styles.editButton}
                        onClick={() => {
                          openDets(d);
                        }}
                        onMouseEnter={() => {
                          handleMouseOver(i);
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        {" "}
                        EDIT{" "}
                      </button>
                      <button
                        className={styles.editButton}
                        onClick={() => {
                          checkUnbind(d);
                        }}
                        onMouseEnter={() => {
                          handleMouseOver(i);
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        {" "}
                        UNBIND{" "}
                      </button>
                    </div>
                  ) : (
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        checkRemove(d);
                      }}
                      onMouseEnter={() => {
                        handleMouseOver(i);
                      }}
                      onMouseLeave={handleMouseLeave}
                    >
                      {" "}
                      REMOVE{" "}
                    </button>
                  )}
                  {/* {role === "Admin" ? (
                    <div
                      className={`${modalStyles.basicButton} ${modalStyles.active}`}
                      onClick={() => {
                        setEditDialog(true);
                        setSelectedEdit(d);
                      }}
                    >
                      <p className={modalStyles.buttonTitle}>Edit Unit</p>
                    </div>
                  ) : (
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        viewRealtime(d);
                      }}
                      onMouseEnter={() => {
                        handleMouseOver(i);
                      }}
                      onMouseLeave={handleMouseLeave}
                    >
                      View Realtime
                    </button>
                  )} */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const SortBys = ["Type", "Status", "Distance", "Visiting Time", "Speed"]; //, "Location"

  const sortOptions = tier < 2 ? SortBys.filter((d, i) => i < 2) : SortBys;

  // const cancelAddDevice = () => {
  //   setShowDialog(false);
  //   setShowDialog2(false);
  //   setEditDialog(false);
  //   setUnitID("");
  //   setUnitOTP("");
  //   setAreaUID("");
  //   setSiteUID("");
  //   setCoor(false);
  //   setXCoor("0");
  //   setYCoor("0");
  //   setSSID("");
  //   setPassword("");
  //   setUnitName("");
  // };

  // const checkDevice = async (event) => {
  //   event.preventDefault();
  //   if (unitID === "") {
  //     toast.error("Unit ID is required");
  //     return;
  //   } else {
  //     try {
  //       await addDevice();
  //     } catch (err) {
  //       toast.error(err.message);
  //     }
  //   }
  // };

  // const addDevice = async () => {
  //   setShowDialog(false);
  //   const URL = `${version.expURL}/api2/unit/bind_unit`;
  //   var userID = localStorage.getItem("UID");

  //   var body = {
  //     user_uid: userID,
  //     unit_id: unitID,
  //     otp: parseInt(unitOTP),
  //   };

  //   let respond = await axios.post(URL, body);
  //   // console.log(respond);
  //   if (respond.data.status === "success") {
  //     // fetchList()
  //     // refetchUnit();
  //     // cancelAddDevice();
  //     toast.success("Unit is now binded to this account.");
  //   } else {
  //     toast.error(respond.data.message);
  //   }

  //   // axios.post(URL, body).then(async (res) => {
  //   //   // console.log('aa', res);
  //   //   if (res.data.status === "success") {

  //   //   }
  //   // });
  // };

  // const createNewDevice = async () => {
  //   setShowDialog2(false);
  //   const URL = `${version.expURL}/api2/unit/create_unit`;
  //   var userID = localStorage.getItem("UID");

  //   var body = {
  //     user_uid: userID,
  //     unit_id: unitID,
  //     type: "test",
  //   };

  //   let respond = await axios.post(URL, body);

  //   console.log("create_new_device", respond);
  // };

  const openDets = (d) => {
    let str = d.space.unit_address;
    let AREA = str.slice(str.indexOf("/") + 1);
    let SPACE = str.split("/", 1).toString();
    console.log("wifii", d.space);

    let WIFI = d.space.unit_wifi;
    if (WIFI !== undefined) {
      setPassword(WIFI.password);
      setSSID(WIFI.ssid);
    }

    setAreaName(AREA);
    setSiteName(SPACE);
    setUnitName(d.device_id);

    setAreaUID(d.space.area_uid);
    setSiteUID(d.space.site_uid);
    setXCoor(d.space.unit_coordinate.x);
    setYCoor(d.space.unit_coordinate.y);
    setUnitID(d.unit_uid);
    setSSID(d.space.unit_wifi.ssid);
    setPassword(d.space.unit_wifi.password);
    setSelectedEdit(d);
    setEditDialog(true);
  };

  return (
    <div className={styles.container} style={{ marginTop: 15 }}>
      <div className={styles.topBar}>
        <h4 className={styles.title}>
          All Units
          {/* <div 
                        className={`${styles.chevronContainer} ${sortType === "asc" && styles.sortAsc}`} 
                        onClick={()=>{setSortType(sortType==="asc"?"desc":"asc");}}
                    >
                        <ChevronDown />
                    </div> */}
        </h4>
        {/* <div className={styles.selectContainer}>
                    <Select
                        options={sortOptions}
                        value={sortBy}
                        changeValue={(newSortBy)=>{setSortBy(newSortBy);}}   
                    />
                </div> */}
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            className={`${modalStyles.basicButton} ${modalStyles.active}`}
            onClick={() => setShowDialog(true)}
          >
            <p className={modalStyles.buttonTitle}>Claim Unit</p>
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        {
          // tableData.length > 0 &&
          <>
            <TableScrollbar>{statusTable()}</TableScrollbar>
            {tier === 1 && (
              <div
                className={styles.upgradeLock}
                style={{
                  width: `${
                    distanceColWidth + visitingColWidth + speedColWidth + locationColWidth
                  }px`,
                }}
              >
                <Upgrade />
              </div>
            )}
          </>
        }
      </div>
      {/* <MobileTablePagination
                itemCount={tableData.length}
                totalItem={totalItem}
                page={page}
                maxPage={maxPage}
                prevPage={prevPage}
                nextPage={nextPage}
            /> */}

      {showDialog && <AddUnit setShowAdd={setShowDialog} refetchUnit={refetchUnit}></AddUnit>}
      {editDialog && (
        <EditUnit
          data={data}
          deviceID={selectedEdit.device_id}
          setShowEdit={setEditDialog}
          siteList={siteList}
          areaList={areaList}
          activeItem={selectedEdit}
          refetchUnit={refetchUnit}
        ></EditUnit>
      )}
      {confirmDialog && (
        <RemoveUnit
          unitData={selectedRemove}
          setConfirmDialog={setConfirmDialog}
          refetchUnit={refetchUnit}
        ></RemoveUnit>
      )}

      {unbindDialog && (
        <UnbindUnit
          unitData={selectedRemove}
          setUnbindDialog={setUnbindDialog}
          refetchUnit={refetchUnit}
        ></UnbindUnit>
      )}
    </div>
  );
};

export const MobileTablePagination = ({
  itemCount,
  totalItem,
  page,
  maxPage,
  prevPage,
  nextPage,
}) => {
  return (
    <>
      <span className={styles.topBorder} />
      <div className={styles.pageControl}>
        <div className={styles.pageInfo}>
          {`${itemCount} out of ${totalItem} device${totalItem > 1 && "s"}`}
        </div>
        <div className={styles.pageButtons}>
          <div className={styles.pageButton} onClick={prevPage}>
            <img
              style={{ opacity: `${page === 1 ? 0.3 : 1}` }}
              src={process.env.PUBLIC_URL + `/images/prev.svg`}
              alt={"prev"}
            />
          </div>
          <div
            style={{ opacity: `${page === maxPage ? 0.3 : 1}` }}
            className={styles.pageButton}
            onClick={nextPage}
          >
            <img src={process.env.PUBLIC_URL + `/images/next.svg`} alt={"next"} />
          </div>
        </div>
      </div>
    </>
  );
};

const SortableColumn = ({ sortType, sortBy, toggleSortType }) => {
  //onClick={()=>{console.log('sort clicked');toggleSortType();}}
  return (
    <span className={styles.sortableColumn}>
      {sortBy}
      <span className={`${styles.chevron} ${sortType === "asc" && styles.sortAscending}`}>
        <ChevronDownTable />
      </span>
    </span>
  );
};

export const Upgrade = () => {
  return (
    <div className={lockStyles.container}>
      <div className={lockStyles.lockLogo}>
        <UpgradeLock />
      </div>
      <p className={lockStyles.label}>Upgrade your plan to see more analytics</p>
    </div>
  );
};

const SelectBar = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;
  VALUE = VALUE.replace(/[^a-zA-Z0-9 ]/g, " ");
  VALUE = VALUE.charAt(0).toUpperCase() + VALUE.substring(1);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d.site_name;
            NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SelectBar2 = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;
  VALUE = VALUE.replace(/[^a-zA-Z0-9 ]/g, " ");
  VALUE = VALUE.charAt(0).toUpperCase() + VALUE.substring(1);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d.area_name;
            NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
