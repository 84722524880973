// TotalOnlineTable.js
import React from "react";
import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import styles from "../../../../styles/plexyz/components/pages/overview/overview.module.css";

const TotalOnlineTable = ({ tableData }) => {
  const countOnlineStatus = (data) => {
    let onlineCount = 0;

    // Iterate over each item in the data array
    data.forEach((item) => {
      // Check if the status is "online"
      if (item.status === "online") {
        // If it is, increment the onlineCount
        onlineCount++;
      }
    });

    // Return the count of "online" statuses
    return onlineCount;
  };

  const totalOnlineUnits = countOnlineStatus(tableData);
  return (
    <table className={styles.devTable}>
      <tbody>
        <tr className={`${tableStyles.dataRow}`}>
          {/* Add your table data cells here */}
          <td>{totalOnlineUnits}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalOnlineTable;
