import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumb from "../../layout/breadcrumb";
import {
  lineChartOptions,
  barChartOptions,
} from "../charts/chartsjs/chartsData";
import { Line, Bar } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Input,
  Button,
} from "reactstrap";
// import DatePicker from "react-datepicker";
import DayPicker from "react-day-picker";
import DatePicker from "react-datepicker";
import Calendar from "react-calendar";

import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";

import { Hourly, Daily, Weekly, Monthly, Yearly } from "../../constant";
import DisplayPlexis from "./DisplayPlexis";
import CAR from "../../assets/images/bluecarvert.png";

import { Map, TileLayer, Polyline } from "react-leaflet";
import moment from "moment";
import TimePicker from "rc-time-picker";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "antd";
import "antd/dist/antd.css";
// import ToggleSwitch from "../switch/toggleSwitch";

import axios from "axios";
import { version } from "../../utils/const";

const today = new Date();
const format = "HH:mm";

const hour00 = moment().hour(0).minute(0);
const hour24 = moment().hour(23).minute(59);
const center = [4.2105, 101.9758];


export default class cubaDashboard extends React.Component {
  state = {
    today: new Date(),
    curHr: new Date().getHours(),
    curMi: new Date().getMinutes(),
    dayTimes: "",
    meridiem: "AM",
    start: "",
    end: "",
    load: "1",
    chartConfig: [],
    className: "Daily",
    dotLabel: "impression per day",

    startTime: moment().hour(0).minute(0).format(format),
    endTime: moment().hour(23).minute(59).format(format),
    list_plexyz_selected: [],
    list_of_coordinates: [],
    list_of_velocity: [],
    list_of_velocity_slow: [],
    list_of_velocity_moderate: [],
    list_of_velocity_fast: [],
    list_of_device: [],

    MAPdata: {},
    date: new Date(),
    buttonToggle: false,
    currentCount: 15,
    startDate: "",
    endDate: "",
  };
  componentDidMount() {
    console.log("Inside function");
    if (this.state.curHr < 12) {
      this.setState({
        dayTimes: "Good Morning",
      });
    } else if (this.state.curHr < 18) {
      this.setState({
        dayTimes: "Good Afternoon",
      });
    } else {
      this.setState({
        dayTimes: "Good Evening",
      });
    }

    if (this.state.curHr >= 12) {
      this.setState({
        meridiem: "Good Evening",
      });
    } else {
      this.setState({
        meridiem: "Good Evening",
      });
    }

    this.fetchList();
    this.fetchPlexyzData();
  }
  fetchList = () => {
    try {
      let URL = `${version.expURL}/api/list-of-device`;
      let URL2 = `${version.expURL}/api/list-of-vehicle`;
      fetch(URL)
        .then((res) => res.json())
        .then((json) =>
          this.setState(
            {
              list_of_device: json,
            },
            () => {
              this.renderTable();
              this.renderCheckbox();
            }
          )
        )
        .catch((err) => console.log(err));

      fetch(URL2)
        .then((res) => res.json())
        .then((json) =>
          this.setState(
            {
              list_of_vehicle: json,
            },
            () => {
              this.renderTable();
            }
          )
        )
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  handleChangeCheck = (e) => {
    console.log(e.target.name);
    let name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
        list_plexyz_selected: {
          ...this.state.list_plexyz_selected,
          [name]: !this.state[name],
        },
      },
      () => {
        console.log(this.state[name]);
        // if (this.state[name] == true) {
        this.checker();
        // }
      }
    );
  };

  renderTable = () => {
    try {
      let list_device = this.state.list_of_device;
      let list_vehicle = this.state.list_of_vehicle;

      let display = [];
      // if (this.state.list_of_device == undefined) {
      if (list_device == undefined || list_vehicle == undefined) {
        display = 0;
      } else {
        // if (this.state.list_of_device.data == undefined) {
        if (list_device.data == undefined || list_vehicle.data == undefined) {
          display = 0;
        } else {
          // let DATA = dummy_data
          let DEVICE = list_device.data;
          let VEHICLE = list_vehicle.data;
          // DEVICE.push(this.state.list_of_device.data)
          // console.log(DATA)

          // Data.map( (data,index) => {

          // })

          display = DEVICE.map((data, index) => (
            // console.log(data)
            // console.log(index)
            <tr key={index}>
              <td
                className="text-uppercase text-muted mb-0"
                style={{ width: "15%" }}
              >
                {data.sponsored_brand}
              </td>
              <td
                className="text-uppercase text-muted mb-0"
                style={{ width: "15%" }}
              >
                {VEHICLE[index].plate_no}
              </td>
              <td
                className="text-uppercase text-muted mb-0"
                style={{ width: "15%" }}
              >
                {VEHICLE[index].car_model}
              </td>
              {/* <td className="text-uppercase text-muted mb-0" style={{ width: "15%" }}>{'last_online_time'}</td> */}
              <td
                className="text-uppercase text-muted mb-0"
                style={{ width: "15%" }}
              >
                {data.device_id}
              </td>
            </tr>
          ));
        }
        return display;
      }
    } catch (error) {
      console.log(error);
    }
  };

  renderCheckbox = () => {
    try {
      let Device = this.state.list_of_device.data || [];
      let display = [];

      display = Device.map((value, index) => (
        <tr key={index}>
          <td>
            <Input
              type="checkbox"
              name={value.device_id}
              checked={this.state[value.device_id]}
              onChange={this.handleChangeCheck}
            ></Input>
            {value.device_id}
          </td>
        </tr>
      ));

      return display;
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = async (collection) => {
    try {
      const datetime = {
        start: this.state.start_date,
        end: this.state.end_date,
      };

      let URL = `${version.expURL}/api/data/${collection}/find-time`;
      let post_data = await axios.post(URL, datetime);
      if (post_data) {
        this.setState(
          {
            APIdata: {
              ...this.state.APIdata,
              [collection]: post_data.data,
            },
          },
          () => {
            this.showData(collection);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  showData = (collection) => {
    try {
      let total_plexyz =
        this.state.APIdata[collection].API_calculation.total_plexyz;
      let total_mac = this.state.APIdata[collection].API_calculation.total_mac;
      let total_distance_travelled =
        this.state.APIdata[collection].API_calculation.total_distance_travelled;
      let fixed_distance = parseFloat(total_distance_travelled).toFixed(2);
      // console.log(total_distance_travelled)

      let list_of_velocity = [];
      // let list_of_velocity_slow = [];
      // let list_of_velocity_moderate = [];
      // let list_of_velocity_fast = [];
      let list_of_speed =
        this.state.APIdata[collection].API_calculation.list_of_speed;
      let list_of_plexyz =
        this.state.APIdata[collection].API_calculation.list_of_plexyz;
      let list_of_coordinates =
        this.state.APIdata[collection].API_calculation.list_of_coordinates;
      list_of_coordinates.forEach((data, index) => {
        data[2] = list_of_plexyz[index];
        list_of_velocity.push([data[0], data[1], list_of_speed[index]]);
        // if (list_of_speed[index] < 40){
        //   list_of_velocity_slow.push([data[0],data[1],list_of_speed[index]])
        // }
        // if (list_of_speed[index] >= 40 && list_of_speed[index] < 80){
        //   list_of_velocity_moderate.push([data[0],data[1],list_of_speed[index]])
        // }
        // if (list_of_speed[index] > 80){
        //   list_of_velocity_fast.push([data[0],data[1],list_of_speed[index]])
        // }
      });
      this.setState(
        {
          MAPdata: {
            ...this.state.MAPdata,
            [collection]: {
              total_distance_travelled: fixed_distance,
              list_of_coordinates: list_of_coordinates,
              list_of_velocity: list_of_velocity,
              // list_of_velocity_slow: list_of_velocity_slow,
              // list_of_velocity_moderate: list_of_velocity_moderate,
              // list_of_velocity_fast: list_of_velocity_fast,
              total_plexyz: total_plexyz,
              total_mac: total_mac,
            },
          },
          load: "3",
        },
        () => {
          // console.log(this.state.list_of_coordinates)
          // console.log(this.state.list_of_velocity_slow)
          if (this.state.switch == true) {
            console.log("refetch");
            setTimeout(() => {
              this.checker();
            }, 7000);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleDayClick = (day, { selected }) => {
    // console.log(day, selected);
    const date = day;

    // To convert normal date to epoch
    let EPOCH = date.getTime() / 1000;

    let DAY = ("0" + date.getDate()).slice(-2);
    let MONTH = date.getMonth();
    let YEAR = date.getFullYear();
    let MONTH_LIST = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    let STRING = YEAR + "-" + MONTH_LIST[MONTH] + "-" + DAY;
    // console.log(STRING);
    this.setState(
      {
        selectedDay: selected ? undefined : day,
        select: selected ? 0 : STRING,
        epochdate: EPOCH,
      },
      () => {
        this.checker();
      }
    );
  };

  handleDayClickChart = (day, { selected }) => {
    try {
      const date = day;
      console.log(date, selected);

      // To convert normal date to epoch
      let EPOCH = date.getTime() / 1000;

      let DAY = ("0" + date.getDate()).slice(-2);
      let MONTH = date.getMonth();
      let YEAR = date.getFullYear();
      let MONTH_LIST = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];

      let STRING = YEAR + "-" + MONTH_LIST[MONTH] + "-" + DAY;
      console.log(STRING);
      this.setState(
        {
          selectedDayChart: selected ? undefined : day,
          startDate: selected ? 0 : STRING,
        },
        () => {
          this.handleListClickHourly();
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  checker = () => {
    try {
      let DATE = this.state.select;
      let TIME = this.state.startTime;
      let ENDTIME = this.state.endTime;

      if (DATE && TIME !== undefined && ENDTIME !== undefined) {
        // console.log("inside date and time");
        let hours = TIME.slice(0, 2);
        let minutes = TIME.slice(-2);

        // For end time
        let end = ENDTIME.slice(0, 2);
        let endminutes = ENDTIME.slice(-2);
        // console.log(hours)
        // console.log(end)

        // Pair date and time
        let finalTime = DATE + " " + hours + ":" + minutes;
        let finalTime1 = DATE + " " + end + ":" + endminutes;

        // setState
        this.setState(
          {
            start_date: finalTime,
            end_date: finalTime1,
            load: "4",
          },
          () => {
            this.checkerPLEXYZ();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  checkerPLEXYZ = () => {
    try {
      let list_selected = this.state.list_plexyz_selected;
      // console.log(list_selected);
      // console.log("check PLEXYZ");
      Object.entries(list_selected).map((data, index) => {
        if (data[1] == true) {
          this.setState(
            {
              load: "2",
            },
            () => {
              this.fetchData(data[0]);
            }
          );
        }
        if (data[1] == false) {
          this.setState({
            load: "4",
            APIdata: {
              ...this.state.APIdata,
              [data[0]]: "",
            },
            MAPdata: {
              ...this.state.MAPdata,
              [data[0]]: {
                total_distance_travelled: 0,
                list_of_coordinates: "",
                list_of_velocity: "",
                // list_of_velocity_slow: list_of_velocity_slow,
                // list_of_velocity_moderate: list_of_velocity_moderate,
                // list_of_velocity_fast: list_of_velocity_fast,
                total_plexyz: 0,
                total_mac: 0,
              },
            },
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderHeatmapandPolyline = () => {
    try {
      let display = [];
      Object.entries(this.state.MAPdata).map((data, index) => {
        let list_of_coordinates = data[1].list_of_coordinates;
        display.push(
          <>
            <HeatmapLayer
              fitBoundsOnLoad
              fitBoundsOnUpdate
              points={list_of_coordinates}
              longitudeExtractor={(m) => m[1]}
              latitudeExtractor={(m) => m[0]}
              intensityExtractor={(m) => parseFloat(m[2])}
            />
            <Polyline color="#0000FF" positions={list_of_coordinates} />
          </>
        );
      });

      return display;
    } catch (error) {
      console.log(error);
    }
  };

  renderDisplaydistance = () => {
    try {
      // console.log(this.state.total_distance_travelled);
      let load = this.state;
      let display = [];
      let total_distance_travelled = 0;

      if (this.state.load === "1") {
        display = (
          <tbody>
            <td style={{ fontSize: "20px" }}>
              Please choose your date and time
            </td>
          </tbody>
        );
      }
      if (this.state.load === "2") {
        display = (
          <tbody>
            <td className="loader"></td>
          </tbody>
        );
      }
      if (this.state.load === "3") {
        Object.entries(this.state.MAPdata).map((data, index) => {
          if (data[1].total_distance_travelled >= 0) {
            total_distance_travelled =
              total_distance_travelled +
              parseFloat(data[1].total_distance_travelled);
          }
        });
        display = total_distance_travelled + " " + "KM";
      }
      if (this.state.load === "4") {
        display = (
          <tbody>
            <td style={{ fontSize: "20x" }}>Please select Vehicle to map</td>
          </tbody>
        );
      }

      return display;
    } catch (error) {
      console.log(error);
    }
  };

  renderDisplayplexyz = () => {
    try {
      // console.log(this.state.total_distance_travelled);
      let load = this.state;
      let display = [];
      let total_plexyz = 0;

      if (this.state.load === "1") {
        display = (
          <tbody>
            <td style={{ fontSize: "20px" }}>
              Please choose your date and time
            </td>
          </tbody>
        );
      }
      if (this.state.load === "2") {
        display = (
          <tbody>
            <td className="loader"></td>
          </tbody>
        );
      }
      if (this.state.load === "3") {
        Object.entries(this.state.MAPdata).map((data, index) => {
          if (data[1].total_plexyz >= 0) {
            // console.log(this.state[data[0]])
            // console.log(total_plexyz)
            total_plexyz = total_plexyz + parseFloat(data[1].total_plexyz);
          }
        });
        // console.log(total_plexyz)
        display = total_plexyz;
      }
      if (this.state.load === "4") {
        display = (
          <tbody>
            <td style={{ fontSize: "20px" }}>Please select Vehicle to map</td>
          </tbody>
        );
      }

      return display;
    } catch (error) {
      console.log(error);
    }
  };

  renderDisplayMac = () => {
    try {
      // console.log(this.state.total_distance_travelled);
      let load = this.state;
      let display = [];
      let total_mac = 0;

      if (this.state.load === "1") {
        display = (
          <tbody>
            <td style={{ fontSize: "20px" }}>
              Please choose your date and time
            </td>
          </tbody>
        );
      }
      if (this.state.load === "4") {
        display = (
          <tbody>
            <td style={{ fontSize: "20px" }}>Please select Vehicle to map</td>
          </tbody>
        );
      }
      if (this.state.load === "2") {
        display = (
          <tbody>
            <td className="loader"></td>
          </tbody>
        );
      }
      if (this.state.load === "3") {
        Object.entries(this.state.MAPdata).map((data, index) => {
          if (data[1].total_mac >= 0) {
            // console.log(this.state[data[0]])
            // console.log(total_mac)
            total_mac = total_mac + parseFloat(data[1].total_mac);
          }
        });
        // console.log(total_mac)
        display = total_mac;
      }

      return display;
    } catch (error) {
      console.log(error);
    }
  };

  fetchPlexyzData = async () => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_day`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_day_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        console.log(total._id);
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchPlexyzDataHourly = async () => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
        startDate: this.state.startDate,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_hourly_by_day`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_hourly_by_day_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      console.log(plexyz0007);
      plexyz0007.map((total) => {
        console.log(total._id);
        plexyz_date_count.push(total._id);
        plexyz_data_count.push({ x: total._id, y: total.count });
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push({ x: total._id, y: total.count });
      });

      let hour_count = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];

      const chart_config = {
        labels: hour_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // parsing: {
            //   // xAxisKey: 'hour',
            //   yAxisKey: 'count'
            // },
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
        className: "Hourly",
        dotLabel: "impression per hour",
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchPlexyzDataWeekly = async () => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_week`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_week_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchPlexyzDataMonthly = async () => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_month`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_month_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchPlexyzDataYearly = async () => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_year`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_year_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
      });
    } catch (error) {
      console.log(error);
    }
  };

  convertWeektoDaily = (weekNo) => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
    let currentWeek = Math.ceil(
      (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
    );
    var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
    d1 = new Date();
    numOfdaysPastSinceLastMonday = d1.getDay() - 1;
    d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    d1.setDate(d1.getDate() + 7 * (weekNo - currentWeek));
    if (d1.getDate() < 10) {
      rangeIsFrom =
        "0" + d1.getDate() + "-" + (d1.getMonth() + 1) + "-" + d1.getFullYear();
    } else {
      rangeIsFrom =
        d1.getDate() + "-" + (d1.getMonth() + 1) + "-" + d1.getFullYear();
    }

    d1.setDate(d1.getDate() + 6);
    if (d1.getDate() < 10) {
      rangeIsTo =
        "0" + d1.getDate() + "-" + (d1.getMonth() + 1) + "-" + d1.getFullYear();
    } else {
      rangeIsTo =
        d1.getDate() + "-" + (d1.getMonth() + 1) + "-" + d1.getFullYear();
    }
    return rangeIsFrom + " to " + rangeIsTo;
  };

  handleListClickDaily = async (e) => {
    // e.persist();
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_day`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_day_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
        className: e.target.innerHTML,
        dotLabel: "impression per day",
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleListClickHourly = async () => {
    console.log(this.state.selectedDayChart);

    // e.persist();
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
        startDate: this.state.startDate,
      };
      // console.log(post_data);
      // console.log(e.target.innerHTML);

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_hourly_by_day`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_hourly_by_day_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      console.log(plexyz0007);
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push({ x: total._id, y: total.count });
        // plexyz_data_count.push([total.count, total._id]);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push({ x: total._id, y: total.count });
      });
      console.log({ plexyz_date_count });
      console.log({ plexyz_data_count });
      console.log({ plexyz_date_count_2 });
      console.log({ plexyz_data_count_2 });

      let hour_count = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];

      const chart_config = {
        labels: hour_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // parsing: {
            //   // xAxisKey: 'hour',
            //   yAxisKey: 'count'
            // },
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
        className: "Hourly",
        dotLabel: "impression per hour",
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleListClickWeekly = async (e) => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_week`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_week_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_data_count.push(total.count);
        this.convertWeektoDaily(total._id);
        plexyz_date_count.push(this.convertWeektoDaily(total._id));
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
        className: e.target.innerHTML,
        dotLabel: "impression per week",
      });
    } catch (error) {
      console.log(error);
    }
    // e.persist();
  };

  handleListClickMonthly = async (e) => {
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_month`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_month_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
        className: e.target.innerHTML,
        dotLabel: "impression per month",
      });
    } catch (error) {
      console.log(error);
    }
    // e.persist();
  };

  handleListClickYearly = async (e) => {
    // e.persist();
    try {
      let token = localStorage.getItem("token");
      let post_data = {
        token: token,
      };

      let plexyz_date_count = [];
      let plexyz_data_count = [];
      let plexyz_date_count_2 = [];
      let plexyz_data_count_2 = [];

      let URL = `${version.expURL}/api/list-of-device/aggregate_year`;
      let URL2 = `${version.expURL}/api/list-of-device/aggregate_year_0008`;

      let fetch_plexyz0007 = await axios.post(URL, post_data);
      let plexyz0007 = fetch_plexyz0007.data;
      plexyz0007.map((total) => {
        plexyz_date_count.push(total._id);
        plexyz_data_count.push(total.count);
      });

      let fetch_plexyz0008 = await axios.post(URL2, post_data);
      let plexyz0008 = fetch_plexyz0008.data;
      plexyz0008.map((total) => {
        plexyz_date_count_2.push(total._id);
        plexyz_data_count_2.push(total.count);
      });

      const chart_config = {
        labels: plexyz_date_count,
        datasets: [
          {
            label: "PLEXYZ0007",
            lagend: "PLEXYZ0007",
            data: plexyz_data_count,
            // fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "PLEXYZ0008",
            lagend: "PLEXYZ0008",
            data: plexyz_data_count_2,
            // fill: false,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderColor: "rgba(247, 49, 100, 0.4)",
          },
        ],
      };

      this.setState({
        chartConfig: chart_config,
        className: e.target.innerHTML,
        dotLabel: "impression per year",
      });
    } catch (error) {
      console.log(error);
    }
  };

  buttonToggle = (e) => {
    try {
      // console.log("Inside toggle");
      if (e) {
        this.refresh = setInterval(() => {
          this.fetchRealtime();
          this.setState({
            currentCount: 15,
          });
        }, 15000);
        this.timer = setInterval(() => {
          // console.log(this.state.currentCount);
          this.setState({ currentCount: this.state.currentCount - 1 });
        }, 1000);
      } else {
        // console.log("Inside else");
        clearInterval(this.refresh);
        clearInterval(this.timer);
        this.setState({
          currentCount: 15,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchRealtime = () => {
    if (this.state.className === "Hourly") {
      this.fetchPlexyzDataHourly();
    }
    if (this.state.className === "Daily") {
      // console.log("refreshing Daily");
      this.fetchPlexyzData();
    }
    if (this.state.className === "Weekly") {
      // console.log("refreshing Weekly");
      this.fetchPlexyzDataWeekly();
    }
    if (this.state.className === "Monthly") {
      // console.log("refreshing Monthly");
      this.fetchPlexyzDataMonthly();
    }
    if (this.state.className === "Yearly") {
      // console.log("refreshing Yearly");
      this.fetchPlexyzDataYearly();
    }
  };

  _renderPlexis = () => {
    // console.log("render PLExyz")
    let display,
      TEMP = [];
    let ARRAY = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    let FIXED = 2.5;
    // console.log(ARRAY)

    ARRAY.forEach(() => {
      let RANDOM = Math.random();
      // console.log(RANDOM*10)
      let D_SQR = Math.pow(5, 2);
      let X_NEG = Math.floor(Math.random() * 2);
      let Y_NEG = Math.floor(Math.random() * 2);
      let X = Math.floor(Math.random() * FIXED) + 0.5;
      let X_SQR = X * X;
      // let Y = Math.sqrt(D_SQR - X_SQR)
      let Y = Math.floor(Math.random() * FIXED) + 0.5;
      let distance = Math.sqrt(X * X + Y * Y).toFixed(1);
      let colour;
      // console.log({ distance });
      if (distance < 1) {
        // purple
        colour = "#5c0fff";
      }
      if (distance >= 1 && distance < 1.7) {
        // orange
        colour = "#ffac16";
      }
      if (distance >= 1.7 && distance < 2.6) {
        // grey purple
        colour = "#c7bee8";
      }
      if (distance >= 2.6) {
        // white
        colour = "#FFFFFF";
      }
      // console.log({ colour });
      let a = {
        x: X_NEG !== 0 ? -X : X,
        y: Y_NEG !== 0 ? -Y : Y,
        d: distance,
        colour: colour,
      };
      TEMP.push(a);
      // console.log('plexo', TEMP)
    });
    display = TEMP.map((plexis, index) => (
      <div
        key={index}
        className="bg-gradient-danger"
        style={{
          fontSize: "0.8rem",
          color: "white",
          position: "absolute",
          zIndex: "6",
          top: "50%",
          left: "50%",
          transform: `translate(${plexis.x * 2.5}rem, ${plexis.y * 2.5}rem)`,
          width: "0.3rem",
          height: "0.3rem",
          borderRadius: "100%",
        }}
      >
        <div
          style={{
            color: plexis.colour,
            position: "absolute",
            bottom: "0.3rem",
            lineHeight: "13px",
            fontSize: "15px",
            fontWeight: "bold",
          }}
        >
          {plexis.d}
        </div>
      </div>
    ));
    // if(this.props.data === undefined && this.props.data !== undefined) {
    // // if(this.props.data._PLEXIS === undefined && this.props.data._vehicle !== undefined) {
    //     display = <div className="bg-gradient-danger text-white shadow" style={{ position:'absolute', zIndex:'6', top:'50%', left:'50%', transform:'translate(-50%,-50%)', padding:'10px 15px', width:'75%', borderRadius:'0.375rem'}}>There is no Plexis data on this device. Please turn the Plexis feature on to display any data.</div>
    // }

    // console.log(display)
    return display;
  };

  render() {
    return (
      <>
        <Fragment>
          {/* <Breadcrumb
            style={{
              backgroundColor: "#FFFFFF",
              color: "#FFFFFF",
            }}
            parent="Dashboard"
            title="PLExyz Crowd Analytics"
          /> */}

          <Container fluid={true}>
            <Col>
              <h4
                style={{
                  color: "white",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                PLExyz Beacon Analytics
              </h4>

              <Row>
                <Col md="3">
                  <Row
                    style={{
                      paddingRight: "15px",
                    }}
                  >
                    {/* <Col md="3"> */}
                    <Card
                      className="earning-card mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <CardBody>
                        {this.state.className === "Hourly" ? (
                          ""
                        ) : (
                          <h5
                            style={{
                              color: "white",
                              // paddingTop: "40px",
                              paddingBottom: "40px",
                            }}
                          >
                            Devices detected around PLExyz
                          </h5>
                        )}
                        <div
                          style={{
                            width: "100%",
                            paddingTop: "100%",
                            margin: "0 auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            zIndex: "5",
                          }}
                        >
                          {/* car image */}
                          {/* <img src={CAR} alt="..." style={{margin:'0 auto', height:'10rem', position:'absolute', top:'50%', transform:'translateY(-50%)', zIndex:'2'}}/> */}
                          {/* <div></div> */}
                          {/* <div style={{color: 'white', position: 'absolute', bottom: '0.3rem', lineHeight: '13px', fontSize: '12px', fontWeight: 'bold',}}>10 Devices</div> */}
                          <div
                            style={{
                              color: "white",
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              lineHeight: "13px",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {Math.floor((Math.random() * 10) / 2) + 10} Devices
                          </div>

                          {/* central dot */}
                          <div
                            className="bg-white"
                            style={{
                              border: "1px solid #adb5bd",
                              borderRadius: "50%",
                              width: "5px",
                              height: "5px",
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "5",
                            }}
                          ></div>

                          {/* outer radius */}
                          <div
                            style={{
                              border: "3px dashed #c7bee8",
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "1",
                            }}
                          ></div>
                          <div
                            style={{
                              border: "3px dashed #ffac16",
                              borderRadius: "50%",
                              width: "75%",
                              height: "75%",
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "1",
                            }}
                          ></div>
                          <div
                            style={{
                              border: "3px dashed #5c0fff",
                              borderRadius: "50%",
                              width: "50%",
                              height: "50%",
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "1",
                            }}
                          ></div>
                          <div
                            style={{
                              border: "3px dashed #adb5bd",
                              borderRadius: "50%",
                              width: "25%",
                              height: "25%",
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "1",
                            }}
                          ></div>

                          {this._renderPlexis()}
                        </div>

                        {/* <DisplayPlexis>TEST</DisplayPlexis> */}
                      </CardBody>
                    </Card>

                    {this.state.className === "Hourly" ? (
                      <Card
                        className="mb-3"
                        style={{
                          border: "none",
                          backgroundColor: "#251e38",
                          width: "100%",
                        }}
                      >
                        <DayPicker
                          className="dayPicker"
                          selectedDays={this.state.selectedDayChart}
                          onDayClick={this.handleDayClickChart}
                          disabledDays={{ after: today }}
                          inline
                        />
                      </Card>
                    ) : (
                      ""
                    )}
                    {/* </Col> */}
                  </Row>
                </Col>

                <Col md="9">
                  <Row>
                    <Card
                      className="earning-card mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <CardBody className="p-0">
                        <Row className="m-0">
                          <Col xl="12" className="p-0">
                            <div className="chart-right">
                              <Row className="m-0 p-tb">
                                <Col
                                  xl="8"
                                  md="8"
                                  sm="8"
                                  className="col-12 p-0"
                                >
                                  <div className="inner-top-left">
                                    <ul className="d-flex list-unstyled">
                                      <li
                                        name="hourly"
                                        className={
                                          this.state.className === "Hourly"
                                            ? "active"
                                            : "none"
                                        }
                                        onClick={(e) =>
                                          this.handleListClickHourly(e)
                                        }
                                      >
                                        {Hourly}
                                      </li>
                                      <li
                                        name="daily"
                                        className={
                                          this.state.className === "Daily"
                                            ? "active"
                                            : "none"
                                        }
                                        onClick={(e) =>
                                          this.handleListClickDaily(e)
                                        }
                                      >
                                        {Daily}
                                      </li>
                                      <li
                                        name="weekly"
                                        className={
                                          this.state.className === "Weekly"
                                            ? "active"
                                            : "none"
                                        }
                                        onClick={(e) =>
                                          this.handleListClickWeekly(e)
                                        }
                                      >
                                        {Weekly}
                                      </li>
                                      <li
                                        className={
                                          this.state.className === "Monthly"
                                            ? "active"
                                            : "none"
                                        }
                                        onClick={(e) =>
                                          this.handleListClickMonthly(e)
                                        }
                                      >
                                        {Monthly}
                                      </li>
                                      <li
                                        className={
                                          this.state.className === "Yearly"
                                            ? "active"
                                            : "none"
                                        }
                                        onClick={(e) =>
                                          this.handleListClickYearly(e)
                                        }
                                      >
                                        {Yearly}
                                      </li>
                                    </ul>
                                  </div>
                                </Col>
                                <Col
                                  xl="4"
                                  md="4"
                                  sm="4"
                                  className="col-12 p-0 justify-content-end"
                                >
                                  <div className="inner-top-right">
                                    {/* <Button
                                      onClick={(e) => this.buttonToggle(e)}
                                    >
                                      Enable realtime{" "}
                                    </Button> */}

                                    {/* <ToggleSwitch label="Realtime Database" /> */}

                                    <ul className="d-flex list-unstyled justify-content-end">
                                      <p>Enable realtime</p>
                                      &nbsp;&nbsp;
                                      <Switch
                                        onClick={(toggle) =>
                                          this.buttonToggle(toggle)
                                        }
                                      ></Switch>
                                      &nbsp;&nbsp;
                                      <p>{this.state.currentCount}s</p>
                                      {/* <li>{Store}</li> */}
                                    </ul>
                                    <p className="d-flex list-unstyled justify-content-end">
                                      {this.state.dotLabel}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <CardBody className="p-0">
                                    <div className="current-sale-container">
                                      {this.state.selectedDayChart ===
                                        undefined &&
                                      this.state.className === "Hourly" ? (
                                        <p>
                                          Select date on calendar to show hourly
                                          chart
                                        </p>
                                      ) : (
                                        <Bar
                                          data={this.state.chartConfig}
                                          options={barChartOptions}
                                        />
                                      )}
                                    </div>
                                  </CardBody>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </Row>

              <h4
                style={{
                  color: "white",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                PLExyz Crowd Analytics
              </h4>

              <Row>
                <Col md="2">
                  <Row>
                    <Card
                      className="card-stats mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <CardBody style={{ padding: "20px" }}>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                              Distance Travelled
                            </CardTitle>
                            <span className="h3 font-weight-bold mb-0">
                              {this.renderDisplaydistance()}
                            </span>
                          </div>
                          {/* <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="ni ni-atom" />
                          </div>
                        </Col> */}
                        </Row>
                        <p className="mt-2 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">
                            <i className="fa fa-arrow-down" />
                            1%
                          </span>
                          <span className="text-nowrap">Since last week</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Row>

                  <Row>
                    <Card
                      className="card-stats mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <CardBody style={{ padding: "20px" }}>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                              Total Impression
                            </CardTitle>
                            <span className="h3 font-weight-bold mb-0">
                              {this.renderDisplayplexyz()}
                            </span>
                          </div>
                          {/* <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col> */}
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" />
                            3.48%
                          </span>
                          <span className="text-nowrap">Since last month</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Row>

                  <Row>
                    <Card
                      className="card-stats mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <CardBody style={{ padding: "20px" }}>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                              Total Device
                            </CardTitle>
                            <span className="h3 font-weight-bold mb-0">
                              {this.renderDisplayMac()}
                            </span>
                          </div>
                          {/* <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col> */}
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" />
                            2.17%
                          </span>
                          <span className="text-nowrap">Since last month</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>

                <Col md="8">
                  <Card
                    className="pb-3"
                    style={{
                      height: "100%",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Map
                      style={{
                        height: "100%",
                        width: "100%",
                        paddingBottom: "30%",
                        borderRadius: "14px",
                      }}
                      center={center}
                      zoom={8}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      {this.renderHeatmapandPolyline()}
                    </Map>
                  </Card>
                </Col>

                <Col md="2">
                  <Row>
                    <Card
                      className="card-stats mb-3"
                      style={{
                        width: "100%",
                        padding: "0px 0px 10px 0px",
                        border: "none",
                        backgroundColor: "#251e38",
                      }}
                    >
                      <span
                        style={{
                          padding: "10px 0px 6px 0px",
                          fontSize: "18px",
                          textAlign: "center",
                        }}
                      >
                        Device Name
                      </span>
                      <div style={{ padding: "0px 0px 10px 40px" }}>
                        {this.renderCheckbox()}
                      </div>
                    </Card>
                  </Row>
                  <Row>
                    <Card
                      className="card-stats mb-3 px-3"
                      style={{
                        width: "100%",
                        padding: "20px 0px 20px 0px",
                        border: "none",
                        backgroundColor: "#251e38",
                      }}
                    >
                      <span className="h2 font-weight-bold mb-0">
                        <div style={{ padding: "0" }}>
                          {this.state.startTime == undefined ? (
                            "Choose a starting and ending time"
                          ) : (
                            <td style={{ fontSize: "20px" }}>
                              Start Time: {this.state.startTime}{" "}
                            </td>
                          )}
                        </div>
                        <div style={{ padding: "0px 0px 10px 0px" }}>
                          {this.state.endTime == undefined ? (
                            ""
                          ) : (
                            <td style={{ fontSize: "20px" }}>
                              End Time: {this.state.endTime}{" "}
                            </td>
                          )}
                          {/* "End Time" + " : " + this.state.endTime} */}
                        </div>
                      </span>
                      <Row className="m-0 pb-2">
                        <a style={{ lineHeight: "27px", width: "80px" }}>
                          Start Time
                        </a>
                        <TimePicker
                          showSecond={false}
                          className="testStyle col pr-0"
                          popupClassName="testStyle"
                          defaultValue={hour00}
                          onChange={(value) => {
                            console.log(value);
                            // console.log(value.format(format))
                            let TIME = value.format(format);
                            // console.log(TIME)

                            this.setState(
                              {
                                startTime: TIME,
                              },
                              () => {
                                this.checker();
                              }
                            );
                          }}
                          showMinute
                          format={format}
                          use12Hours={false}
                          inputReadOnly
                          allowEmpty={false}
                        />
                      </Row>
                      <Row className="m-0">
                        <a style={{ lineHeight: "27px", width: "80px" }}>
                          End Time
                        </a>
                        <TimePicker
                          className="testStyle col pr-0"
                          popupClassName="testStyle"
                          style={{ margin: "0px 0px 0px 0px" }}
                          showSecond={false}
                          defaultValue={hour24}
                          onChange={(time) => {
                            console.log(time);
                            // console.log(time.format(format))
                            let TIME = time.format(format);
                            // console.log(TIME)

                            this.setState(
                              {
                                endTime: TIME,
                              },
                              () => {
                                this.checker();
                              }
                            );
                          }}
                          showMinute
                          format={format}
                          use12Hours={false}
                          inputReadOnly
                          allowEmpty={false}
                        />
                      </Row>
                    </Card>
                  </Row>

                  <Row>
                    <Card
                      className="mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <DayPicker
                        className="dayPicker"
                        selectedDays={this.state.selectedDay}
                        onDayClick={this.handleDayClick}
                        disabledDays={{ after: today }}
                        inline
                      />
                    </Card>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col className="dashboard-sec">
                  <Row>
                    <Card
                      className="earning-card mb-3"
                      style={{
                        border: "none",
                        backgroundColor: "#251e38",
                        width: "100%",
                      }}
                    >
                      <CardBody className="p-0">
                        <Row className="m-0">
                          <Col xl="12" className="p-0">
                            <Table borderless>
                              <thead className="thead-light">
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ borderTopLeftRadius: "10px" }}
                                  >
                                    Sponsored brand
                                  </th>
                                  <th scope="col">No plate</th>
                                  <th scope="col">Type of car</th>
                                  {/* <th scope="col">Status</th> */}
                                  <th
                                    scope="col"
                                    style={{ borderTopRightRadius: "10px" }}
                                  >
                                    Device name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{this.renderTable()}</tbody>
                            </Table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Container>
        </Fragment>
      </>
    );
  }
}

const StyledTimePicker = styled(TimePicker)`
  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    font-size: 15px;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-family: "Consolas", sans-serif;
    font-size: 16px;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .rc-time-picker-input {
    background-color: red;
  }
`;
