import React, { useState, useEffect } from 'react';
import  { NextArrow } from "../../../../svg-shapes/NextArrow";
import styles from "../../../../styles/plexyz/components/pages/dashboard/color-indicator.module.css";

export const ColorIndicator = ({isOpen,toggleOpen}) => {    
    const [colorUI, setColorUI] = useState([
        { color: "blue", text: " 80%-95%" },
        { color: "yellow", text: "50%-79%" },
        { color: "white", text: "<=49%"}
    ]);

    const [distances, setDistances] = useState([
        { color: "#2f2158", text: "closest 2 meters"},
        { color: "#34267d", text: "border for every 2 meters"}
    ]);

    return (
        <div className={`${styles.container} ${!isOpen && styles.closed}`}
            onClick={()=>{
                if(!isOpen){
                    toggleOpen();
                }
            }}
        >
            <div 
                className={styles.nextArrow}
                onClick={toggleOpen}
            >
                <NextArrow />
            </div>
            <h4 className={styles.title}>
                Colour Indicator
            </h4>
            <p>
                The colour stats for user interaction possibility with the unit as in status.
            </p>
            <div className={styles.colorContainer}>
            {
                colorUI.map((d,i)=>(
                    <div key={i} className={styles.colorRow} >
                        <div 
                            className={styles.colorDot} 
                            style={{backgroundColor:d.color === "blue"?"#5c0fff":d.color==="yellow"?"#ffac17":"#c8bde8"}} 
                        />
                        <p>{ d.text }</p>
                    </div>
                ))
            }
            </div>
            <p>
                The colour stats for area or distance from the unit.
            </p>
            <div className={styles.distanceContainer}>
            {
                distances.map((d, i) => (
                    <div key={i} className={styles.distanceRow} >
                        <div className={styles.distanceSquare} style={{backgroundColor:d.color}} />
                        <p>{ d.text }</p>
                    </div>
                ))
            }
            </div>
        </div>
    )
}