import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import styles from "../../../styles/plexyz/layout/sidebar.module.css";

export const SideBar = () => {
  const location = useLocation();
  const { pathname } = location;

  const [open, setOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  const isMobile = width <= 600;

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const menuItems = [
    // { icon: "devices", text: "Overview", style: {}, href: "/overview/" },
    { icon: "analytics", text: "Overview", style: {}, href: "/overview/" },
    { icon: "dashboard", text: "Realtime", style: {}, href: "/dashboard/" },
    { icon: "devices", text: "Devices", style: {}, href: "/devices/", hideAtMobile: true },

    // {
    //   icon: "analytics",
    //   text: "Analytics",
    //   style: {},
    //   href: "/analytics/",
    //   hideAtMobile: true,
    // },

    // { icon: "devices", text: "Reports", style: {}, href: "/reports/", hideAtMobile: true },

    // { icon: "analytics", text: "Analytics", style:{}, href:"/analytics/", hideAtMobile:true},
    {
      icon: "settings",
      text: "Settings",
      style: { marginTop: `${isMobile ? "0px" : "55px"}` },
      href: "/settings/",
    },
    { icon: "account", text: "User Account", style: {}, href: "/user-account/" },
    // { icon: "analytics", text: "Device Count", style: {}, href: "/quadrant-radar/" },

    // below is for stripe checkout
    // { icon: "account", text: "Payment and Billing", style:{}, href:"/payment/"}
  ];

  return (
    <div
      className={`${styles.container} ${open && styles.isOpen}`}
      onMouseEnter={() => {
        if (!isMobile) {
          setOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setOpen(false);
        }
      }}
      onTouchStart={() => {
        if (!isMobile) {
          setOpen(true);
        }
      }}
    >
      {menuItems.map((d, i) => {
        return (
          <Link
            onClick={(e) => {
              if (open) {
                e.stopPropagation();
                //e.preventDefault();
              }
            }}
            className={`${styles.menuItem} 
                                ${d.href === pathname && styles.menuActive}
                                    ${d.hideAtMobile && styles.hideAtMobile}`}
            style={{ ...d.style }}
            key={i}
            to={d.href}
          >
            <img
              className={styles.menuIcon}
              src={
                process.env.PUBLIC_URL +
                `/images/sideBar/${
                  d.href === pathname && isMobile ? d.icon + "_active" : d.icon
                }.svg`
              }
              alt={d.icon}
            />
            {!isMobile && <span className={styles.menuText}>{d.text}</span>}
            {/* {menuItems.children && visibleSubMenus[i] && (
            <div className={styles.subMenu}>
              {menuItems.children.map((subMenuItem, subIndex) => (
                <Link
                  key={subIndex}
                  className={`${styles.menuItem} ${styles.subMenuItem} 
                    ${subMenuItem.href === pathname && styles.menuActive}
                    ${subMenuItem.hideAtMobile && styles.hideAtMobile}`}
                  style={{
                    ...subMenuItem.style,
                    position: 'absolute',
                    top: '100%',
                  }}
                  to={subMenuItem.href}
                >
                  <span className={styles.menuText}>{subMenuItem.text}</span>
                </Link>
              ))}
            </div>
          )} */}
          </Link>
        );
      })}

      <FeedbackButton />
    </div>
  );
};

// const ResourceButton = () => {

//     return (
//         <div className={styles.resourceButton}>
//             <span className={styles.resourceText}>
//                 Resources
//             </span>
//             <span className={styles.resourceLabel}>
//                 Release Notes, Samples
//             </span>
//         </div>

//     )
// }

const FeedbackButton = () => {
  return (
    <Link
      to={{
        pathname: "/settings/",

        state: { from: "SideBar" }, // or any property you like to add
      }}
    >
      <div className={styles.resourceButton}>
        <span className={styles.resourceText}>Got Feedback?</span>
        <span className={styles.resourceLabel}>Submit a ticket</span>
      </div>
    </Link>
  );
};
