import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { version } from "../../../../utils/const";
import { useLocation } from "react-router-dom";
import TableScrollbar from "react-table-scrollbar";

import { Modal } from "../../global/Modal";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { NextArrow } from "../../../../svg-shapes/NextArrow";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";

import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import styles from "../../../../styles/plexyz/components/pages/settings/admin-settings.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

export const AdminSettings = () => {
  const [showFeedback, setShowFeedback] = useState("");
  const [bugsList, setBugsList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [expandBugs, setExpandBugs] = useState(false);
  const [expandFeature, setExpandFeature] = useState(false);

  const { width } = useWindowDimensions();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    fetchFeedback();
  }, []);

  const fetchFeedback = async () => {
    try {
      var UID = localStorage.getItem("UID");
      let URL = `${version.expURL}/api2/feedback/all_feedback `;

      let respond = await axios.post(URL, { user_uid: UID });
      let BUGS = [];
      let FEATURE = [];
      if (respond !== null) {
        console.log(respond);
        let DATA = respond.data.data;
        DATA.map((x) => {
          if (x.type === "bugs") {
            BUGS.push(x);
          } else {
            FEATURE.push(x);
          }
        });

        let sortB = BUGS.sort((a, b) => b.timestamp_created.localeCompare(a.timestamp_created));
        let sortF = FEATURE.sort((a, b) => b.timestamp_created.localeCompare(a.timestamp_created));

        setBugsList(sortB);
        setFeatureList(sortF);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>Bugs</h4>
          <div
            className={styles.collapsible}
            onClick={() => {
              setExpandBugs(!expandBugs);
            }}
          >
            {!expandBugs ? "Show less" : "Show more"}
            <div className={`${styles.chevronContainer} ${!expandBugs && styles.sortAsc}`}>
              <ChevronDown />
            </div>
          </div>
        </div>

        {!expandBugs ? (
          <div className={styles.outerBox}>
            {bugsList.map((x, i) => {
              let TIME = x.timestamp_created;
              TIME = TIME.substring(0, TIME.indexOf("T"));

              return (
                <div className={styles.box}>
                  <div className={styles.topBox}>{TIME}</div>

                  <p>
                    Email: {x.email} <br />
                    <br />
                    User ID: {x.user_uid} <br />
                    <div className={styles.divider} />
                    {x.description} <br />
                  </p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>

      <div className={styles.container}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>Features</h4>
          <div
            className={styles.collapsible}
            onClick={() => {
              setExpandFeature(!expandFeature);
            }}
          >
            {!expandFeature ? "Show less" : "Show more"}
            <div className={`${styles.chevronContainer} ${!expandFeature && styles.sortAsc}`}>
              <ChevronDown />
            </div>
          </div>
        </div>

        {!expandFeature ? (
          <div className={styles.outerBox}>
            {featureList.map((x, i) => {
              let TIME = x.timestamp_created;
              TIME = TIME.substring(0, TIME.indexOf("T"));

              return (
                <div className={styles.box}>
                  <div className={styles.topBox}>{TIME}</div>

                  <p>
                    Email: {x.email} <br />
                    <br />
                    User ID: {x.user_uid} <br />
                    <div className={styles.divider} />
                    {x.description} <br />
                  </p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>

      {/* <LogoutButton />             */}
    </div>
  );
};

const LogoutButton = () => {
  const logout = () => {
    localStorage.removeItem("UID");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("tier");
    localStorage.removeItem("username");

    window.location.href = "/";
  };

  return (
    <div
      className={styles.logoutButton}
      onClick={() => {
        logout();
      }}
    >
      <span className={styles.logoutText}>Log out from account</span>
      {/* <span className={styles.resourceLabel}>
                Release Notes, Samples
            </span> */}
    </div>
  );
};
