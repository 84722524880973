import React, { useState, useRef, useEffect } from "react";
// import { Button, Modal,ModalHeader, ModalBody,ModalFooter,Label,Input,Row, Col,} from "reactstrap";
import { Modal } from "../../global/Modal";
import "react-toastify/dist/ReactToastify.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import { ToastContainer, toast } from "react-toastify";
import { version } from "../../../../utils/const";
import axios from "axios";

const InputContainer = ({
  label,
  inputId,
  icon,
  value,
  onChange,
  type,
  placeholder,
}) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};

export const AddUnit = ({setShowAdd, refetchUnit}) => {
    const [unitID, setUnitID] = useState("");
    const [unitOTP, setUnitOTP] = useState("");
    useEffect(()=>{
      console.log(refetchUnit)
    }, [])

    const checkDevice = async (event) => {
        event.preventDefault();
        if (unitID === "") {
          toast.error("Unit ID is required");
          return;
        } else {
          try {
            await addDevice();
          } catch (err) {
            toast.error(err.message);
          }
        }
      };
    
    const addDevice = async () => {
    setShowAdd(false);
    const URL = `${version.expURL}/api2/unit/bind_unit`;
    var userID = localStorage.getItem("UID");

    var body = {
        user_uid: userID,
        unit_id: unitID,
        otp: parseInt(unitOTP),
    };

    

    let respond = await axios.post(URL, body);
    // console.log(respond);
    if (respond.data.status === "success") {
        // fetchList()
        // refetchUnit();
        cancelAddDevice();
        toast.success("Unit is now binded to this account.");
        refetchUnit()
    }
    else {
        toast.error(respond.data.message)
    }

    // axios.post(URL, body).then(async (res) => {
    //   // console.log('aa', res);
    //   if (res.data.status === "success") {

    //   }
    // });
    };
    const cancelAddDevice = () => {
      setShowAdd(false);
      setUnitID("");
      setUnitOTP("");
    };

    return (
        
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Add Unit</h3>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Unit ID"
                inputId="unitID"
                value={unitID}
                onChange={(newValue) => {

                  setUnitID(newValue.toUpperCase());
                }}
                type="text"
                placeholder="eg: PLExyz1234, PLExyz9870"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Unit OTP"
                inputId="unitOTP"
                value={unitOTP}
                onChange={(newValue) => {
                  setUnitOTP(newValue);
                }}
                type="text"
                placeholder="eg: 123456"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  checkDevice(e);
                }}
              >
                Add Unit
              </div>
              <div
                onClick={cancelAddDevice}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
        )
}