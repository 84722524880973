import React, { useState, useEffect, useContext } from "react";
import { Select, SelectOptions } from "../../global/Select";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { BeaconContext } from "./context/";
import axios from "axios";

import { NextArrow } from "../../../../svg-shapes/NextArrow";
import styles from "../../../../styles/plexyz/components/pages/dashboard/beacon.module.css";
import { Client, connect as mqttConnect } from "mqtt";
import { version } from "../../../../utils/const";
import { local } from "d3-selection";

export const BeaconInfo = ({ isOpenUnit, toggleOpenUnit, fetchType, historyTime }) => {
  const [beaconInfo, setBeaconInfo] = useState({
    _id: "",
    device_id: "",
    type: "",
    address: "",
    space: { unit_address: "" },
    gps: { lat: "" },
  });
  const { _id, device_id, type, address, gps, space } = beaconInfo;
  const beaconContext = useContext(BeaconContext);
  const {
    plexyzID,
    setPlexyzID,
    plexyzIDs,
    bindedUnits,
    selectedUnitData,
    setSelectedUnitData,
    URL,
  } = beaconContext;
  const [listData, setListData] = useState([]);
  const [lastData, setLastData] = useState([]);
  const [test, setTest] = useState("not connect yet");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  // const [deviceId, setDeviceId] = useState(null);

  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  useEffect(() => {
    // console.log("id_plexyz", plexyzID);
    let DATA = axios.post(URL, { device_id: plexyzID }).then((res) => {
      // console.log("beacon_info", res.data.data);
      if (res.data.data !== undefined) {
        const beaconInfo = res?.data?.data;
        setBeaconInfo(beaconInfo);
      }
      // const beaconInfo = res?.data?.data;
      // setBeaconInfo(beaconInfo);
      //return res?.data?.data;
    });
  }, [plexyzID]);

  // useEffect(() => {
  //   if (historyTime !== undefined) {
  //     if (historyTime.startTime !== null) {
  //       let DATE = historyTime.startTime.toLocaleDateString("en-GB");
  //       let S = historyTime.startTime.toLocaleTimeString("en-US");
  //       S = S.split(":")[0] + ":" + S.split(":")[1] + " " + S.slice(-2);
  //       let FS = DATE + " , " + S;
  //       // S= S.substring(0, S.indexOf(","))

  //       let DATEE = historyTime.endTime.toLocaleDateString("en-GB");
  //       let E = historyTime.endTime.toLocaleTimeString("en-US");
  //       E = E.split(":")[0] + ":" + E.split(":")[1] + " " + E.slice(-2);
  //       let FE = DATEE + " , " + E;

  //       setEnd(FE);
  //       setStart(FS);
  //     }
  //   }
  // });

  const beaconImg = () => {
    return (
      <img
        className={styles.beaconImg}
        src={process.env.PUBLIC_URL + `/images/mobile/Beacon.svg`}
        alt={"Beacon"}
      />
    );
  };

  const selectBeacon = () => {
    return (
      <SelectOptions
        options={plexyzIDs}
        value={plexyzID}
        changeValue={(plexyzID) => {
          setPlexyzID(plexyzID);
          setSelectedUnitData(getUnitDataFromPID(plexyzID));
        }}
      />
    );
  };

  const getUnitDataFromPID = (pID) => {
    // console.log("dlm_getUnitDataFromPID", { pID });
    let unit_array = bindedUnits.filter((unit) => {
      if (unit.device_id === pID) {
        // console.log({ unit });
        return unit;
      }
    });
    return unit_array[0];
  };

  return (
    <div
      className={`${styles.container} ${!isOpenUnit && styles.closed}`}
      onClick={() => {
        if (!isOpenUnit) {
          // console.log({ toggleOpenUnit });
          // toggleOpenUnit;
        }
      }}
    >
      <div className={styles.nextArrow} onClick={toggleOpenUnit}>
        <NextArrow />
      </div>
      <div className={styles.bottomInfo}>
        {plexyzIDs.length !== 0 ? (
          <div className={styles.topBar}>
            <h4 className={styles.title}>Unit Info</h4>
            {selectBeacon()}
          </div>
        ) : (
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingTop: 30,
              paddingBottom: 22,
            }}
          >
            <h4 className={styles.title} style={{ textAlign: "left" }}>
              Unit Info <br />
              <div style={{ color: "#fff", fontSize: 12, marginTop: 3 }}>
                No available unit. Please create or bind unit first.
              </div>
            </h4>
          </div>
        )}
        {/* {fetchType === "Historical" ? (
          <div className={styles.infoCell} styles={{ marginBottom: 10 }}>
            <h4 className={styles.infoTitle}>HISTORICAL DATE SELECTED</h4>
            <p className={styles.infoContent}>
              {start} - {end}
            </p>
          </div>
        ) : null} */}

        {/* <InfoCell infoTitle={"Type"} infoContent={[type]} />
        <div className={styles.infoRow1}>
          <div className={styles.row1Cell}>
            <InfoCell
              infoTitle={"Name"}
              infoContent={[device_id]}
              isMobile={isMobile}
              selectBeacon={selectBeacon}
            />
          </div>
          <div className={styles.row1Cell}>
            <InfoCell infoTitle={"Code / Type"} infoContent={[_id]} />
            <InfoCell infoTitle={"Location"} infoContent={[space.unit_address]} />
          </div>
          <div className={styles.row1Cell}>{isMobile && beaconImg()}</div>
        </div> */}
        <div>
          <div className={styles.infoRow1}>
            <InfoCell infoTitle={"Type"} infoContent={[type]} />
            {isMobile && <div className={styles.beaconImgContainer}>{beaconImg()}</div>}
          </div>
          <div className={styles.infoRow1}>
            <InfoCell infoTitle={"Location"} infoContent={[space.unit_address]} />
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoCell = ({ infoTitle, infoContent, isMobile, selectBeacon }) => {
  /*
    Just for 'Name', need to display select options instead of basic text at mobile devices
    */
  const displaySelectAtMobile = infoTitle === "Name" && isMobile;

  return (
    <div className={styles.infoCell}>
      <h4 className={styles.infoTitle}>{infoTitle}</h4>
      {!displaySelectAtMobile
        ? infoContent.map((d, i) => {
            return (
              <p className={styles.infoContent} key={i}>
                {d ? d : "-"}
              </p>
            );
          })
        : selectBeacon()}
    </div>
  );
};
