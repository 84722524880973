import React, { useEffect, useRef } from "react";

const HereMap = ({ coords, zoomLevel, name }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) return;

    // Initialize HERE Maps
    const platform = new window.H.service.Platform({
      apikey: "AweqVyCqUi2wNogeKaAezlK7SJkyieT6F01ZwQBlRNQ", // Replace with your HERE Maps API key
    });
    const defaultLayers = platform.createDefaultLayers();

    // Create the map instance
    const map = new window.H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: coords[0], lng: coords[1] },
      zoom: zoomLevel,
    });

    // Enable map events (optional)
    const mapEvents = new window.H.mapevents.MapEvents(map);
    const behavior = new window.H.mapevents.Behavior(mapEvents);
    const ui = window.H.ui.UI.createDefault(map, defaultLayers);

    // Add marker
    const marker = new window.H.map.Marker({ lat: coords[0], lng: coords[1] });
    map.addObject(marker);

    return () => {
      // Cleanup map when component unmounts
      map.dispose();
    };
  }, [coords, zoomLevel]);

  return <div ref={mapRef} style={{ height: "150px", width: "100%" }} />;
};

export default HereMap;
