import React, { useEffect, useState } from "react";
// import { UserInfo } from "./AccountOverview";
// import { TierOverview } from "./TierOverview";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import { Pagination } from "../../global/Pagination";
import { Modal } from "../../global/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../../../styles/plexyz/components/pages/reports/reports.module.css";
import mainStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";

// import modalStyles from "../../../../styles/plexyz/components/pages/reports/reports-modal-module.css";

import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import { UserInfo } from "../user-account/AccountOverview";
import { TierOverview } from "../user-account/TierOverview";
import { version } from "../../../../utils/const";
import TableScrollbar from "react-table-scrollbar";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";

export const AdminReport = () => {
  const [mouseOver, setMouseOver] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const [data, setData] = useState([
    {
      deploymentDate: "2024-12-12",
      reportDate: "2023-12-24",
      unitId: "0022",
      status: "offline",
      id: 1,
    },
    {
      deploymentDate: "2023-11-11",
      reportDate: "2023-11-22",
      unitId: "0022",
      status: "offline",
      id: 2,
    },
  ]);
  const [showDialog, setShowDialog] = useState(false);
  const [user, setUser] = useState([]);
  const [assignUser, setAssignUser] = useState("Select user");
  const [assignUserId, setAssignUserId] = useState(null);
  const [reports, setReports] = useState([]);

  const [lookerLink, setLookerLink] = useState([]);

  const history = useHistory();

  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchAllUser();
  }, []);

  const fetchAllUser = async () => {
    try {
      const URL = `${version.expURL}/api2/admin/all_user_list`;
      var userID = localStorage.getItem("UID");

      var body = { user_uid: userID };
      const response = await axios.post(URL, body);
      const result = response.data;

      let iterate_data = result.data;
      let array = [];
      let reportArray = [];

      iterate_data.map((data, index) => {
        let { email, user_uid, reports } = data;
        if (reports.length !== 0) {
          reportArray = reportArray.concat(reports);
        }
        array.push([email, user_uid]);
      });

      setUser(array);
      setReports(reportArray);

      // console.log({ result });
    } catch (error) {
      console.log(error);
    }
  };

  const nextPage = (reportId) => {
    try {
      console.log("inside", reportId);
      history.push(`/reports/${reportId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const reportsTable = () => {
    return (
      <table className={styles.devTable}>
        <thead>
          <tr className={styles.tableHeader}>
            <th>Deployment Date</th>
            <th>Report Date</th>
            <th>Unit ID</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {reports.map((d, i) => {
            return (
              <tr key={i}>
                <td>{d?.deployment_date}</td>
                <td>{d?.report_date}</td>
                <td>{d?.unit_id}</td>
                <td>{d?.status}</td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  <button
                    className={styles.editButton}
                    onClick={() => {
                      nextPage(i);
                    }}
                    // onMouseEnter={() => {
                    //   handleMouseOver(i);
                    // }}
                    // onMouseLeave={handleMouseLeave}
                  >
                    View Reports
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const saveAssignedLink = async (d) => {
    try {
      console.log(d);
      const URL = `${version.expURL}/api2/admin/add_report`;

      d.preventDefault();

      if (assignUser !== "" && lookerLink !== "") {
        const body = {
          user: assignUser,
          report_url: lookerLink,
          user_uid: assignUserId,
        };
        const response = await axios.post(URL, body);
        const result = response.data;

        if (result === 200) {
          toast.success("successfully added looker link");
        } else {
          toast.error("error occurred");
        }
      } else {
        toast.warning("Ensure all the form is being filled in");
      }

      setShowDialog(false);
      fetchAllUser();
      console.log("inside_saveAssignedLink", d);
    } catch (error) {
      return error;
    }
  };
  return (
    <div className={mainStyles.container}>
      <div className={styles.container} style={{ marginTop: 15 }}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>All Reports</h4>

          <div style={{ flexDirection: "row", display: "flex" }}>
            {role === "Admin" ? (
              <div
                className={`${styles.basicButton} ${styles.active}`}
                onClick={() => setShowDialog(true)}
              >
                <p className={styles.buttonTitle}>Add Report</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.tableContainer}>
          {
            // tableData.length > 0 &&
            <>
              <TableScrollbar>{reportsTable()}</TableScrollbar>
            </>
          }
          {showDialog && (
            <Modal>
              <div className={modalStyles.addDeviceDialog}>
                <h3 className={modalStyles.addDeviceTitle}>Add Looker Report</h3>

                <div className={modalStyles.addDeviceSelect}>
                  <div className={modalStyles.inputContainer}>
                    <h5 className={modalStyles.inputLabel}>User Email</h5>
                    <div>
                      <SelectBar
                        options={user}
                        value={assignUser}
                        changeValue={(x) => {
                          setAssignUser(x[0]);
                          setAssignUserId(x[1]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={modalStyles.addDeviceSelect}>
                  <InputContainer
                    label="Looker Link"
                    inputId="lookerLink"
                    value={lookerLink}
                    onChange={(newValue) => {
                      setLookerLink(newValue);
                    }}
                    type="text"
                    placeholder="eg: Jalan 2, Bangunan B"
                  />
                </div>

                <div className={modalStyles.addDeviceSelect}>
                  <div
                    className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                    onClick={(e) => {
                      saveAssignedLink(e);
                    }}
                  >
                    Add Looker Link
                  </div>
                  <div
                    // onClick={cancelAddDevice}
                    className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectBar = ({ options, value, changeValue }) => {
  try {
    const [selectionsVisible, setSelectionsVisible] = useState(false);
    return (
      <div
        className={selectStyles.sortSelection}
        onClick={() => {
          setSelectionsVisible(!selectionsVisible);
        }}
      >
        {value}
        <div
          className={`${selectStyles.chevronContainer} ${
            selectionsVisible && selectStyles.selectOpen
          }`}
        >
          <ChevronDown />
        </div>
        {selectionsVisible && (
          <div
            className={selectStyles.options}
            onMouseLeave={() => {
              setSelectionsVisible(false);
            }}
          >
            {options.map((d, i) => {
              return (
                <div
                  className={selectStyles.option}
                  key={i}
                  onClick={() => {
                    changeValue(d);
                  }}
                >
                  {d[0]}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  } catch (error) {
    return null;
  }
};

const InputContainer = ({ label, inputId, icon, value, onChange, type, placeholder }) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};
