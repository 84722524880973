import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { version } from "../../../../utils/const";
import { useLocation } from 'react-router-dom';

import { Modal } from "../../global/Modal";
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import  { NextArrow } from "../../../../svg-shapes/NextArrow";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";


import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import styles from "../../../../styles/plexyz/components/pages/settings/settings.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";


export const Settings = () => {
    const [showFeedback, setShowFeedback] = useState("");
    const [description, setDescription] = useState("");
    const [feedbackName, setFeedbackName] = useState("--Select--");
    const [feedbackType, setFeedbackType] = useState("");
    const [feedbackList, setFeedbackList] = useState([{name: "Bugs Report", id: "bugs"}, {name:"Feature Suggestion", id: "feature"}])

    const { width } = useWindowDimensions();
    const location = useLocation();
    const {pathname} = location;

    // console.log('apa prev path ', pathname);
    // console.log('betul ke',props.location.state.from);
    
    
    // const checkPath = () => {
    //     const location = useLocation();
    //     const path = location.pathname;

        
    // }

    const submitFeedback = async() => {
        var EMAIL = localStorage.getItem("email")
        var UID = localStorage.getItem("UID")

        if (feedbackName === "--Select--"){
            toast.error("Please select Bug Report or Feature Suggestion");
            return;
        } else if (description === "") {
            toast.error("Description is required");
            return;
        } else {
            let URL = `${version.expURL}/api2/feedback/create `;
            // console.log(URL);
            let payload = {
                user_uid: UID,
                email: EMAIL,
                description: description,
                type:feedbackType
            };

            let respond = await axios.post(URL, payload);
            console.log(respond);
            cancelSubmit()
            toast.success("Thank you for your feedback!")
          }
        
    }

    const cancelSubmit = () => {
        setDescription("");
        setFeedbackName("--Select--");
        setFeedbackType("");
        setShowFeedback(false);
    }
    /*
    <div className={styles.boxContent}>
                    <p>Policy</p>
                    <p className={styles.smallDescription}>Learn how we protect your data and privacy</p>
                    <div className={styles.nextArrow}>
                        <NextArrow />
                    </div> 
                </div>
                <div className={styles.boxContent}>
                    <p>Terms & Conditions</p>
                    <p className={styles.smallDescription}>Learn more about our dashboard's terms & conditions</p>
                    <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                    <div className={styles.nextArrow}>
                        <NextArrow />
                    </div>
                    </a>
                </div>
                <div className={styles.boxContent}>
                    <p>FAQ</p>
                    <p className={styles.smallDescription}>Have a question? Find the possible answers here</p>
                    <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                    <div className={styles.nextArrow}>
                        <NextArrow />
                    </div>
                    </a>
                </div>
                <div className={styles.boxContent}>
                    <p>Help Center</p>
                    <p className={styles.smallDescription}>Experiencing issues? Contact us and we’ll guide you through tough times</p>
                    <div className={styles.nextArrow}>
                        <NextArrow />
                    </div>
                </div>
                */

    return (
        <div className={styles.container}>
        
            <ToastContainer />
            <div className={styles.boxContainer}>
                <h3 className={styles.boxTitle}>
                    Support
                </h3>
                <div className={styles.boxDescription}>
                    Settings for your dashboard experience
                </div>               
                <div className={styles.boxContent} onClick={() => setShowFeedback(true)}>
                    <p>Feedback</p>
                    <p className={styles.smallDescription}>Tell us how we can improve your PLExyz experience</p>
                    <div className={styles.nextArrow}>
                        <NextArrow />
                    </div>
                </div>



            </div>

            <div className={styles.boxContainer}>
                <h3 className={styles.boxTitle}>
                    Account
                </h3>
                <div className={styles.boxDescription}>
                    Account related settings
                </div>
                <div className={styles.boxContent}>
                    <p>Deactivate Account</p>
                    <p className={styles.smallDescription}>To deactivate your account, please send an email to biz@reka.re</p>
                    <div className={styles.nextArrow}>
                        <NextArrow />
                    </div> 
                </div>

            </div>


            <LogoutButton />            
            
            {showFeedback && (
                <Modal>
                <div className={modalStyles.addDeviceDialog}>
                    <h3 className={modalStyles.addDeviceTitle}>User Feedback</h3>
                    <div className={modalStyles.addDeviceSelect}>
                        <div className={modalStyles.inputContainer}>
                            <h5 className={modalStyles.inputLabel}>Is it a bug?</h5>
                            <div>
                            <SelectBar
                                options={feedbackList}
                                value={feedbackName}
                                changeValue={(x) => {
                                    setFeedbackType(x.id)
                                    setFeedbackName(x.name)

                                }}
                            />
                            </div>
                        </div>
                    </div>
                    <div className={modalStyles.addDeviceSelect}>
                    <InputContainer
                        label="Description"
                        inputId="description"
                        value={description}
                        onChange={(val) => { setDescription(val);}}
                        type="text"
                        placeholder="Write your feedback here"
                    />
                    </div>
                
                    
                    <div className={modalStyles.addDeviceSelect}>
                    <div
                        className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                        onClick={(e) => { submitFeedback(e);}}
                    >
                        Submit Feedback
                    </div>
                    <div
                        onClick={cancelSubmit}
                        className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
                    >
                        Cancel
                    </div>
                    </div>
                </div>
                </Modal>
            )}

        </div>
    )
}



const LogoutButton = () => {
    const logout = () => {
        localStorage.removeItem("UID");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        localStorage.removeItem("tier");
        localStorage.removeItem("username");
        
        window.location.href = "/";
    }
    
    return (
        <div className={styles.logoutButton} onClick={()=>{logout();}} >
            <span className={styles.logoutText}>
                Log out from account
            </span>
            {/* <span className={styles.resourceLabel}>
                Release Notes, Samples
            </span> */}
        </div>
        
    )
}


const InputContainer = ({
    label,
    inputId,
    icon,
    value,
    onChange,
    type,
    placeholder,
    disabled,
  }) => {
    const inputElement = () => (
      <input
        id={inputId}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className={modalStyles.inputBox}
        disabled={disabled ? disabled : false}
      />
    );
    return (
      <div className={modalStyles.inputContainer}>
        <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
          {label}
        </h5>
        {inputElement()}
      </div>
    );
};

const SelectBar = ({ options, value, changeValue }) => {
    const [selectionsVisible, setSelectionsVisible] = useState(false);
    return (
      <div
        className={selectStyles.sortSelection}
        onClick={() => {
          setSelectionsVisible(!selectionsVisible);
        }}
      >
        {value}
        <div
          className={`${selectStyles.chevronContainer} ${
            selectionsVisible && selectStyles.selectOpen
          }`}
        >
          <ChevronDown />
        </div>
        {selectionsVisible && (
          <div
            className={selectStyles.options}
            onMouseLeave={() => {
              setSelectionsVisible(false);
            }}
          >
            {options.map((d, i) => {
              return (
                <div
                  className={selectStyles.option}
                  key={i}
                  onClick={() => {
                    changeValue(d);
                  }}
                >
                  {d.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
};
  
  
  