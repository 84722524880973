import React, {useState, useEffect, useRef } from 'react';
import {useD3} from "../../../hooks/used3";
import * as d3 from 'd3';
import { useContainerDimensions} from "../../../hooks/useContainerDimensions";
import { centerFlex } from './Radar';
import { heatmapData } from "../consts/";
import { HeatmapSVG } from './HeatmapSVG';

export const Heatmap = ({ height, data, fetchParamsChanged }) => {
    const [ graphData, setGraphData ] = useState([]);   
    const [ prevGraphData, setPrevGraphData ] = useState([]);
    const [ animated, setAnimated ] = useState(false); 
    const graphRef = useRef(null);
    const { width:heatWidth, height:heatHeight } = useContainerDimensions(graphRef);        

    useEffect(() => {        
        let newGraphData = [
            {range:"0 to 250", devices:[]},
            {range:"above 250 to 500", devices:[]},
            {range:"above 500 to 750", devices:[]},
            {range:"above 750 to 1000", devices:[]},
            {range:"above 1000", devices:[]}
        ];

        data.forEach(d => {
            const device = {...d};
            if(0 <= d.distance && d.distance <= 250) {                
                newGraphData[0].devices.push(device);
            }
            else if(250 < d.distance && d.distance <= 500) {                
                newGraphData[1].devices.push(device);
            }
            else if(500 < d.distance && d.distance <= 750) {                
                newGraphData[2].devices.push(device);
            }
            else if(750 < d.distance && d.distance <= 1000) {                
                newGraphData[3].devices.push(device);
            }
            else if(1000 < d.distance) {                
                newGraphData[4].devices.push(device);
            }
        });

        newGraphData.forEach(d => {
            d.proportion = (d.devices.length / data.length) * 100;
        });

        setGraphData((oldGraphData)=>{            
            setPrevGraphData([...oldGraphData]);
            return newGraphData;
        });

        setAnimated(false);
        
    }, [data]);     

    useEffect(()=>{
        if(!animated){
            setAnimated(true);
        }
    }, [animated]);

    const heatDimension = Math.min(heatWidth, heatHeight);
    const heatMargin = 10;

    const legends = [
        {density:"1-4", color:"yellow", 
            densityTest:(len)=>{
                if(1 <= len && len <= 4){
                    return true;
                }

                return false;
            } 
        },
        {density:"5-9", color:"#159947",
            densityTest:(len)=>{
                if(5 <= len && len <= 9){
                    return true;
                }

                return false;
            }
        },
        {density:"10-49", color:"blue",
            densityTest:(len)=>{
                if(10 <= len && len <=49){
                    return true;
                }

                return false;
            }
        },
        {density:"above 50", color:"red",
            densityTest:(len)=>{
                if( len >= 50){
                    return true;
                }

                return false;
            }
        },
    ];

    const radius =  heatDimension - heatMargin;
    const radius2 = radius * 0.75;
    const radius3 = radius * 0.5;
    const radius4 = radius * 0.25;

    const coloring = (data, idx) => {
        if(data.length === 0) {
            return "#181818";
        }

        const density = data[idx].devices.length;
        const legendIdx = legends.findIndex(d => d.densityTest(density) === true);

        let myColor = legendIdx > -1?legends[legendIdx].color:"#181818";

        return myColor;        
    }


    return (
        <div 
            style={{
                    width:"100%", 
                    height:`${height}px`, 
                    position:"absolute", 
                    left:"0px", 
                    top:"0px", 
                    pointerEvents:"all",                                            
                    display:"flex",
                    flexDirection:"column"
            }}
            
        > 
            
            <div 
                style={{height: 40, display:"flex", flexWrap:"wrap"}}
            >
                <p 
                    style={{
                        margin:"0 0 0 5px", padding:0,
                            fontStyle:"normal", fontWeight: 600, fontSize: 14, 
                                width:"100%", textAlign:"left", 
                                    color:"#ECE6FF"
                    }}
                >
                    LEGENDS
                </p>
                {
                    graphData.length > 0 &&
                    legends.map((d, i)=>{

                        return (
                            <div 
                                key={d.color} 
                                style={{
                                    /*width: 140, */paddingLeft: 5, display:"flex", 
                                }}                             
                            >                                
                                <div 
                                    style={{
                                        width:20, height:20, borderRadius:10, margin:0, padding:0,
                                            background:`radial-gradient(circle closest-side at center, ${d.color} 0, ${d.color} 65%, rgba(0, 0, 0, 0))`
                                    }}
                                />
                                <p 
                                    style={{
                                        fontStyle:"normal", fontWeight: 400, fontSize: 12, marginLeft: 3, 
                                            color:"#ECE6FF"
                                    }}
                                >
                                    {d.density}{" devices"}
                                </p>                                
                            </div>
                        );
                    })
                }
            </div>
            <div 
                style={{
                    flex:"1 1 auto", 
                    backgroundColor:animated?coloring(graphData, 4):coloring(prevGraphData, 4),
                    transition:"background-color 2s",
                    ...centerFlex                    
                }}
                ref={graphRef}
            >
                <div
                    style={{
                        width:radius,
                        height:radius,
                        borderRadius: radius/2,                        
                        border: "2.16px solid #34267D",
                        backgroundColor:animated?coloring(graphData, 3):coloring(prevGraphData, 3),
                        transition:"background-color 2s",
                        ...centerFlex
                    }}
                >
                    <div
                        style={{
                            width:radius2,
                            height:radius2,
                            borderRadius: radius2/2,                        
                            border: "2.16px solid #34267D",
                            backgroundColor:animated?coloring(graphData, 2):coloring(prevGraphData, 2),
                            transition:"background-color 2s",
                            ...centerFlex
                        }}
                    >
                        <div
                            style={{
                                width:radius3,
                                height:radius3,
                                borderRadius: radius3/2,                        
                                border: "2.16px solid #34267D",
                                backgroundColor:animated?coloring(graphData, 1):coloring(prevGraphData, 1),
                                transition:"background-color 2s",
                                ...centerFlex
                            }}
                        >
                            <div
                                style={{
                                    width:radius4,
                                    height:radius4,
                                    borderRadius: radius4/2,                        
                                    border: "2.16px solid #34267D",
                                    backgroundColor:animated?coloring(graphData, 0):coloring(prevGraphData, 0),
                                    transition:"background-color 2s",
                                    ...centerFlex
                                }}
                            >

                            </div>

                        </div>
                    </div>
                </div>
            </div>
                         
        </div>
    );
}