import React from "react";
import * as ReactDOM from "react-dom";

export const Modal = ({children}) => {
    const container = document.getElementById("layoutContainer");

    return (
        <>
        {
            ReactDOM.createPortal(
                <div style={{
                        width:"100vw", 
                        height:"100vh", 
                        display:"flex", 
                        justifyContent:"center", 
                        alignItems:"center",
                        backgroundColor:"rgba(0, 0, 0, 0.6)",
                        position:"fixed",
                        left:"0px",
                        top:"0px"
                    }}
                >
                    { children }
                </div>, 
                container
            ) 
        }
        </>
        
    )
}