import { connect as mqttConnect } from "mqtt";
import axios from "axios";
import { version } from "./const";

const connectMQTT = async (onMessage) => {
  try {
    const URL = `${version.expURL}/api/auth/user_pubsub`;
    const body = {
      user_uid: localStorage.getItem("UID"),
    };

    const response = await axios.post(URL, body);
    const { username, password, client_id } = response.data.pubsub;

    const mqttOptions = {
      username,
      password,
      clientId: client_id,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
    };

    const client = mqttConnect(version.mqttHost, mqttOptions);

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      client.subscribe("distance/#");
    });

    client.on("message", (topic, message) => {
      const data = JSON.parse(message.toString());
      onMessage(data);
    });

    return client;
  } catch (error) {
    console.error("Error connecting to MQTT:", error);
    return null;
  }
};

export default connectMQTT;
