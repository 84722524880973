import React, { useEffect, useState } from "react";
import { UserInfo } from "./AccountOverview";
import { TierOverview } from "./TierOverview";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import { Pagination } from "../../global/Pagination";
import { Modal } from "../../global/Modal";
import axios from "axios";
import { version } from "../../../../utils/const";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../../../styles/plexyz/components/pages/user-account/user-account.module.css";
import tableStyles  from "../../../../styles/plexyz/components/global/table.module.css";
export const UserAccount = () => {
    const [users, setUsers] = useState([]);
    const [ idEdit, setIdEdit ] = useState("");
    const [ idToDelete, setIdToDelete ] = useState("");
    const [ role, setRole ] = useState("user");

    useEffect(()=>{                
        setRole(localStorage.getItem("role"));
    }, []);

    useEffect(()=>{
        if(role === "Admin"){
            fetchUsers();        
        }
    }, [role]);

    const fetchUsers = () => {
        const url = `${version.expURL}/api2/admin/all_user_list`;
        console.log(url);
        const userId = localStorage.getItem("UID");

        const  body = { user_uid: userId};

        axios.post(url, body).then(res => {            
            const users = res.data.data.reduce((prev, curr)=>{                
                if(curr.user_uid){
                    prev.push(curr);
                }
                return prev;
            }, []);
            console.log(users);
            setUsers(users);            
        });        

    }

    const pageSize = 10;
    const { page, maxPage, prevPage, nextPage, tableData } = useTablePagination(users, pageSize);

    const saveEdit = (_id, newName, newTier, newSubscription) => {

        const url = `${version.expURL}/api2/admin/admin_edit_user`;
        const userIdx = users.findIndex(user => user._id === _id);
        const { username, email, ...rest } = users[userIdx];

        const user_uid = localStorage.getItem("UID");
        const targeted_user_uid = users[userIdx].user_uid;

        const reqParams = { username:newName, tier:newTier, user_uid, targeted_user_uid, subscription: newSubscription};

        console.log(reqParams);

        
        axios.post(url, reqParams).then(res => { 
            console.log(res);

            let updatedUsers = users.slice();
            
            let updatedUser = { username: newName, email, tier:newTier, subscription:newSubscription, ...rest};
            updatedUsers[userIdx] = updatedUser        

            setUsers(updatedUsers);
            setIdEdit("");

            toast.success("Edit save successful.")
        })
        .catch(err=>{
            console.log(err);
        });
                
    }

    const cancelEdit = () => { setIdEdit(""); }

    const userToDeleteIdx = users.findIndex(user =>  user._id === idToDelete);

    const deleteUser = () => {        

        const updatedUser = users.filter((d, i) => i!==userToDeleteIdx);
        setUsers(updatedUser);
        setIdToDelete("");

        toast.success("User deleted.");
    }

    useEffect(()=>{
        if(idToDelete !== ""){
            setIdEdit("");
        }
    }, [idToDelete]);

    const usersTable = () => {        
        return (
            <div className={styles.tableCard}>
                <h2 className={styles.usersTitle}>
                    { users.length}{" "}Active Users
                </h2>
                <div className={tableStyles.tableContainer}>
                    <table className={tableStyles.devTable}>
                        <thead>
                            <tr className={tableStyles.tableHeader}>                            
                                <th>
                                    Username
                                </th>        
                                <th>
                                    Tier  
                                </th>                    
                                <th>
                                    Email
                                </th>
                                <th>
                                    Subscription Status
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map(d => {
                                    const userTimestamp = Date(d.timestamp);
                                    const timestamp = userTimestamp.substring(0, userTimestamp.indexOf('GMT'));

                                    const rowEdited = idEdit === d._id;
                                    if(rowEdited){
                                        return (
                                            <Edited 
                                                key={d._id} 
                                                data={d} 
                                                save={saveEdit}
                                                cancel={cancelEdit} 
                                            />
                                        )
                                    }
                                    return (
                                        <tr 
                                            key={d._id} 
                                            className={`${tableStyles.dataRow}`}                                                                         
                                        > 
                                            <td>
                                                {d.username}
                                            </td>
                                            <td>
                                                {d.tier}
                                            </td>
                                            <td>
                                                {d.email}
                                            </td>
                                            <td>
                                                {d.subscription?d.subscription:"No status data"}
                                            </td>
                                            <td className={tableStyles.actionCell}>
                                                <button 
                                                    className={tableStyles.action}
                                                    onClick={(e)=>{setIdEdit(d._id);}}    
                                                >
                                                    <p className={tableStyles.actionLabel}>
                                                        Edit
                                                    </p>
                                                </button> 
                                                <button 
                                                    className={tableStyles.action}
                                                    onClick={(e)=>{setIdToDelete(d._id);}}    
                                                >
                                                    <img 
                                                        src = {process.env.PUBLIC_URL + "/images/global/trash.png"} 
                                                        className={tableStyles.actionIcon}
                                                        alt="trash"
                                                    />
                                                </button>                                               
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>   
                    </table>
                </div>
                <Pagination
                    itemCount={tableData.length}
                    totalItem={users.length}
                    page={page}
                    maxPage={maxPage}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    noBorder={true}
                />
            </div>
        );
    }
    
    return (        
        <div className={styles.mainContainer}>            
            <ToastContainer />
            {
                role === "Admin" && users.length > 0 && usersTable()
            }
            {
                role === "Admin" && userToDeleteIdx !== -1 && 
                <DeleteUser 
                    data={users[userToDeleteIdx]} 
                    deleteUser={deleteUser} 
                    close={()=>{setIdToDelete("")}} 
                />
            }     
            <div style={{display:"flex", justifyContent:"center"}}>
                <UserInfo />
            </div> 
        </div>              
    );
};

const Edited = ({ data, save, cancel }) => {
    const [ editName, setEditName ] = useState("");
    const [ editTier, setEditTier ] = useState("");
    const [ editSubscription, setEditSubscription ] = useState("");
    
    const { username, tier, email, subscription } = data;
    console.log(subscription);
    useEffect(()=>{
        setEditName(username);
        setEditTier(tier?tier:"");
        setEditSubscription(subscription?subscription:"");
    }, []);

    const tierEdited = () => {
        if(typeof tier === "undefined"){
            if(typeof editTier === "string"){
                return editTier!==""?true:false;
            }
        }else{
            if(editTier !== subscription ){
                return true
            }
        }
        return false
    }

    const subscriptionEdited = () => {
        if(typeof subscription === "undefined"){
            if(typeof editSubscription === "string"){
                return editSubscription!==""?true:false;
            }
        }else{
            if(editSubscription !== subscription ){
                return true
            }
        }
        return false
    }
    const editDirty = editName !== username ||  tierEdited() || subscriptionEdited();

    return (
        <tr>
            <td>
                <input
                    className={tableStyles.editInput}
                    value={editName}
                    onChange={(e)=>{setEditName(e.target.value);}}
                />    
            </td>
            <td>
                <select
                    className={tableStyles.editInput}
                    value={editTier}
                    onChange={e=>{setEditTier(e.target.value)}}
                >
                    {
                        ["", "tier1", "tier2", "tier3"].map((d, i)=>{
                            return (
                                <option
                                    key={i}
                                    value={d}
                                >
                                    {d}
                                </option>
                            );
                        })
                    }
                </select>
            </td>
            <td>{email}</td>
            <td>
                <input
                    className={tableStyles.editInput}
                    value={editSubscription}
                    onChange={(e)=>{setEditSubscription(e.target.value);}}
                /> 
            </td>
            <td  className={tableStyles.actionCell} style={{display:"flex", alignItems:"center"}}>
                <button 
                    className={`${tableStyles.action} ${!editDirty && tableStyles.noAction}`}
                    onClick={()=>{
                        if(editDirty){
                            save(data._id, editName, editTier, editSubscription);
                        }                        
                    }}    
                >
                    <img 
                        src = {process.env.PUBLIC_URL + "/images/global/ok.png"} 
                        className={tableStyles.actionIcon}
                        style={{opacity:editDirty?1:0.5}}
                        alt="ok"
                    />
                </button>
                <button 
                    onClick={()=>{cancel();}}
                    className={tableStyles.action}
                >
                    <img 
                        src = {process.env.PUBLIC_URL + "/images/global/cancel.png"} 
                        className={tableStyles.actionIcon}
                        alt="cancel"
                    />
                </button>
            </td>
        </tr>
    )
}

const DeleteUser = ({ data, deleteUser, close }) => {
    const { username, email } = data;

    return (
        <Modal>
            <div className={styles.deleteCard}>
                <h3 className={styles.userTitle}>Delete this user?</h3>
                <p className={styles.userProp}>
                    { username }
                </p>
                <p className={styles.userProp}>
                    { email }
                </p>
                <div className={styles.dialogBtnRow}>
                    <button 
                        className={styles.dialogBtn}
                        onClick={deleteUser}    
                    >
                        <p className={styles.dialogLbl}>
                            Delete
                        </p>
                    </button>
                    <button 
                        className={styles.dialogBtn}
                        onClick={close}    
                    >
                        <p className={styles.dialogLbl}>
                            Close
                        </p>
                    </button>
                </div>
            </div>
        </Modal>
    )
}
