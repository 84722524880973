import React, { useState, useEffect, useRef } from "react";
import * as ReactDOM from "react-dom";
import { DecoratedRadar } from "../../graph-display/Radar";
import { NearestDevices } from "./NearestDev";
import { BeaconInfo } from "./BeaconInfo";
import { ColorIndicator } from "./ColorIndicator";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import styles from "../../../../styles/plexyz/components/pages/dashboard/dashboard.module.css";

export const MobileContent = ({
  top,
  nearestDevices,
  tier,
  URL,
  canFullSwipe,
  children,
  plexyzID,
}) => {
  const graphRef = useRef(null);
  const [hoveredMac, setHoveredMac] = useState("");
  const { width, height } = useContainerDimensions(graphRef);
  const [swipe, setSwipe] = useState("");
  const [filterMac, setFilterMac] = useState("");

  useEffect(() => {
    setHoveredMac("");
  }, [nearestDevices]);

  const radar = () => {
    return (
      <DecoratedRadar
        data={nearestDevices}
        height={height}
        hoveredMac={hoveredMac}
        setHoveredMac={setHoveredMac}
        filterMac={filterMac}
        plexyzID={plexyzID}
      />
    );
  };

  const swipeContentFull = () => {
    return (
      <div className={styles.swipeContent}>
        <NearestDevices
          data={nearestDevices}
          tier={tier}
          hoveredMac={hoveredMac}
          setHoveredMac={setHoveredMac}
        />
        {canFullSwipe && children}
      </div>
    );
  };

  const swipeEl = () => {
    const swipeTitle =
      swipe === "Ui" ? "Unit Info" : swipe === "Ci" ? "Colour Indicator" : "Analytics";
    const style =
      swipe === "Ui" || swipe === "Ci"
        ? styles.swipeOpen
        : swipe === "full"
        ? styles.swipeOpenFull
        : "";

    return (
      <div className={`${styles.swipeContainer} ${style}`}>
        <div
          className={`${styles.swipeBars} ${styles.swipeTop}`}
          onClick={() => {
            const newSwipe = swipe !== "" ? "" : "full";
            setSwipe(newSwipe);
          }}
        >
          <div className={styles.swipeDecor} />
          <div className={styles.swipeDecor} />
        </div>
        <h4 className={styles.swipeTitle}>
          {swipeTitle}
          {swipe !== "" && (
            <img
              className={styles.closeIcon}
              src={process.env.PUBLIC_URL + `/images/mobile/closeIcon.svg`}
              alt={"Close"}
              onClick={() => {
                setSwipe("");
              }}
            />
          )}
        </h4>
        {swipe === "full" && swipeContentFull()}
        {swipe === "Ui" && <BeaconInfo URL={URL} />}
        {swipe === "Ci" && <ColorIndicator isOpen={true} toggleOpen={() => {}} />}
      </div>
    );
  };

  const swipeContainer = document.getElementById("layoutContainer");

  return (
    <div className={styles.mobileContainer}>
      {top()}
      <div style={{ flex: "1 1 auto", position: "relative" }} ref={graphRef}>
        {radar()}
      </div>
      {["Ui", "Ci"].map((d, i) => {
        return (
          <div
            key={d}
            className={`${styles.smallSwipe} ${styles[d]}`}
            onClick={() => {
              setSwipe(d);
            }}
          >
            <div className={styles.smallTitle}>{d}</div>
          </div>
        );
      })}
      {ReactDOM.createPortal(swipeEl(), swipeContainer)}
    </div>
  );
};
