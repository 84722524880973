import React, { useState, useRef, useEffect } from "react";
import { PopoverHeader, PopoverBody, UncontrolledPopover, Popover } from "reactstrap"
import DEVICE from "../../../../assets/images/device.jpg"
import PLUS from "../../../../assets/images/square-plus-solid.svg"
import LIST from "../../../../assets/images/list.svg";

import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import { AddUnit } from "./AddUnit"
import { EditUnit } from "./EditUnit";
import { RemoveUnit } from "./removeUnit";

export const BeaconGrid = ({data, siteList, areaList, refetchUnit}) => {
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(false);


    useEffect(() => {
        console.log("data")
        console.log(refetchUnit)
    }, [])

    // const gridItems = () => {

    //     let display =
        
    //     console.log(display)

    // }

    return (
        <>
        <div className={styles.gridContainer}>
            
            {data.map((x, i) => {
                return <GridItem x={x} i={i} setShowEdit={setShowEdit} setActiveItem={setActiveItem} setConfirmDialog={setConfirmDialog}/>
            }
            )}
            <div className={styles.gridAddContainer}>
                <div className={styles.gridAdd} style={{backgroundImage:`url(${PLUS})`}} onClick={() => {setShowAdd(true)}} id="item_plus" >
                </div>
            </div>
        
        </div>
        {showAdd &&
            <AddUnit setShowAdd={setShowAdd} refetchUnit={refetchUnit}></AddUnit>
        }
        {showEdit &&
            <EditUnit data={data} deviceID={activeItem.device_id} setShowEdit={setShowEdit} siteList={siteList} areaList={areaList} activeItem={activeItem} refetchUnit={refetchUnit}></EditUnit>
        }
        {confirmDialog &&
            <RemoveUnit unitData={activeItem} setConfirmDialog={setConfirmDialog} refetchUnit={refetchUnit}></RemoveUnit>
        }

        </>
    )
}
export const GridItem = ({x, i, setShowEdit, setActiveItem, setConfirmDialog}) => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [areaName, setAreaName] = useState("N/A")
    const [siteName, setSiteName] = useState("N/A")
    const [ssid, setSsid] = useState("N/A")
    const [coordinate, setCoordinate] = useState("N/A")
    const [registered, setRegistered] = useState("N/A")
    const [spaceBinded, setSpaceBinded] =useState("N/A")
    useEffect( () => {
        setValue()
    },[])

    let unitImageURL


    const handleMouseEnter = (i) => {
        setPopoverOpen(true)
        const el = document.getElementById(`button${i}`)
        el.hidden = false

    }
    const handleMouseLeave = () => {
        setPopoverOpen(false)
        const el = document.getElementById(`button${i}`)
        el.hidden = true
    }
    const setValue = () => {

        setRegistered(x.timestamp_binded.split("T")[0])
        if (x.space === null) {
            setSiteName("N/A")
            setSsid("N/A")
            setCoordinate({x:"N/A", y:"N/A"})
            unitImageURL = "N/A"
        } else {
            setAreaName(x.space.unit_address.split("/")[1])
            setSiteName(x.space.unit_address.split("/")[0])
            setSsid(x.space.unit_wifi.ssid)
            setCoordinate(x.space.unit_coordinate)
            setSpaceBinded(x.space.timestamp_updated.split("T")[0])
            unitImageURL = "N/A"
        }
    }
    const buttonContainer = useRef(null)

    return (
        <div key={i}>
            <div className={styles.gridItem} style={{backgroundImage:`url(${DEVICE})`}} id={"item"+i.toString() } onMouseEnter={() => {handleMouseEnter(i)}} onMouseLeave={() => handleMouseLeave()}>
                <p style={{fontSize:"medium", textShadow:"-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{x.device_id}</p>
                <div id={`button${i}`} hidden={true} ref={buttonContainer}>
                    {x.space ?
                    <button onClick={() => {setActiveItem(x);setShowEdit(true)}}>
                        Edit
                    </button>
                    : 
                    <button onClick={() => {setActiveItem(x); setConfirmDialog(true)}}>
                        Remove
                    </button>
                    }
                </div>
                
            </div>
            <Popover placement="bottom" isOpen={popoverOpen} target={"item"+i.toString() }>
                <PopoverHeader className={styles.popoverHeader}>{x.device_id + " details"}</PopoverHeader>
                <PopoverBody>
                    <div>
                        Site: {siteName}
                    </div>
                    <div>
                        Area: {areaName}
                    </div>
                    <div>
                        Wifi SSID: {ssid}
                    </div>
                    <div>
                        Coordinate: ({coordinate.x}, {coordinate.y})
                    </div>
                    <div>
                        Registered: {registered}
                    </div>
                    <div>
                        Space-Binded: {spaceBinded}
                    </div>

                </PopoverBody>
            </Popover>
        </div>
    )
}