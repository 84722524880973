import React, { useState, useRef, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
// import { Button, Modal,ModalHeader, ModalBody,ModalFooter,Label,Input,Row, Col,} from "reactstrap";
import { Modal } from "../../global/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { version } from "../../../../utils/const";
import axios from "axios";

import { Select, SelectOptions } from "../../global/Select";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { ChevronDownTable } from "../../../../svg-shapes/ChevronDownTable";
import { UpgradeLock } from "../../../../svg-shapes/UpgradeLock";
import { dotColor } from "../../global/functions";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import lockStyles from "../../../../styles/plexyz/components/pages/dashboard/upgrade-lock.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

export const AreaList = ({ siteList, tier, refetchArea, areaList }) => {
  const [sortBy, setSortBy] = useState("Status");
  const [sortType, setSortType] = useState("desc");
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [showDialog3, setShowDialog3] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [siteID, setSiteID] = useState("");
  const [siteName, setSiteName] = useState("Select your site name");
  const [areaName, setAreaName] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [areaID, setAreaID] = useState("");
  const [mouseOver, setMouseOver] = useState(false);
  const [activeRow, setActiveRow] = useState(0);

  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  const distanceRef = useRef(null);
  const visitingRef = useRef(null);
  const speedRef = useRef(null);
  const locationRef = useRef(null);

  const { width: distanceColWidth } = useContainerDimensions(distanceRef, [siteList]);
  const { width: visitingColWidth } = useContainerDimensions(visitingRef, [siteList]);
  const { width: speedColWidth } = useContainerDimensions(speedRef, [siteList]);
  const { width: locationColWidth } = useContainerDimensions(locationRef, [siteList]);

  const toggleSortType = () => {
    setSortType(sortType === "asc" ? "desc" : "asc");
  };

  const statusOrder = ["80-95%", "50-79%", "<=49%"];

  const SortBys = ["Type", "Status", "Distance", "Visiting Time", "Speed"]; //, "Location"
  // const sortOptions = tier < 2 ? SortBys.filter((d, i)=>i < 2):SortBys;

  const deviceTable = () => {
    try {
      return areaList.length !== 0 ? (
        <table className={styles.devTable}>
          <thead>
            <tr className={styles.tableHeader}>
              <th style={{ width: "25%" }}>Site</th>
              <th style={{ width: "25%" }}>Area</th>
              <th style={{ minWidth: "66px" }}>Latitude</th>
              <th style={{ minWidth: "66px" }}>Longitude</th>
              <th style={{ width: "15%" }}></th>
            </tr>
          </thead>

          <tbody>
            {(areaList || []).map((d, i) => {
              var NAME = d.area_name;
              NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
              NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

              var SITE_NAME;
              if (d.site_uid !== null) {
                for (const key in siteList) {
                  if (d.site_uid === siteList[key].site_uid) {
                    SITE_NAME = siteList[key].site_name;
                  }
                }
              } else {
                SITE_NAME = "NA";
              }

              return (
                <tr key={i}>
                  <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                    {SITE_NAME}
                  </td>
                  <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                    {NAME}
                  </td>
                  <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                    {/* {d.area_gps?.lat !== undefined ? d.area_gps.lat : d.area_gps.x} */}
                    {d.area_gps?.lat ? d.area_gps.lat : "0"}
                  </td>
                  <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                    {/* {d.area_gps.long !== undefined ? d.area_gps.long : d.area_gps.y} */}
                    {d.area_gps?.long ? d.area_gps.long : "0"}
                  </td>
                  <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <button
                        className={styles.editButton}
                        onClick={() => {
                          openDets(d);
                        }}
                        onMouseEnter={() => {
                          handleMouseOver(i);
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        {" "}
                        EDIT{" "}
                      </button>
                      <button
                        className={styles.editButton}
                        onClick={() => {
                          checkArea(d);
                        }}
                        onMouseEnter={() => {
                          handleMouseOver(i);
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        {" "}
                        DEL{" "}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null;
    } catch (error) {
      console.log(error);
    }
  };

  // ====== functions =======
  const handleMouseOver = (i) => {
    setMouseOver(true);
    setActiveRow(i);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
    setActiveRow(0);
  };

  const checkDetails = async (event) => {
    event.preventDefault();
    if (siteName === "") {
      toast.error("Site Name is required");
      return;
    } else if (areaName === "") {
      toast.error("Area Name is required");
      return;
      // } else if (lat === "") {
      //   toast.error("Latitude is required");
      //   return;
      // } else if (lat === "") {
      //   toast.error("Longitude is required");
      //   return;
    } else {
      try {
        await addArea();
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const addArea = async () => {
    setShowDialog(false);
    const URL = `${version.expURL}/api2/area/create_area`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      site_uid: siteID,
      site_name: siteName,
      area_name: areaName,
      area_gps: {
        lat: lat !== "" ? parseFloat(lat) : 0,
        long: long !== "" ? parseFloat(long) : 0,
      },
      // area_name: areaName
    };

    // console.log('boo', body);

    let respond = await axios.post(URL, body);
    if (respond !== null || respond !== undefined) {
      if (respond.data.status === "success") {
        toast.success("Area added successfully");
        refetchArea();
        cancelAddDevice();
        //   // fetchList()

        try {
          const mysql_URL = `${version.expURL}/api2/mysql/update_dashboard_mqtt_topic`;
          var mysql_body = {
            user_uid: userID,
            area_uid: respond.data?.area_uid,
            site_uid: respond.data?.site_uid,
          };
          let mysql_respond = await axios.post(mysql_URL, mysql_body);
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Error adding new area");
      }
    }

    // create_site, user_uid, area_gps,site_name //area_name, area_gps
  };
  const cancelAddDevice = () => {
    setShowDialog(false);
    setShowDialog2(false);
    setShowDialog3(false);
    setSiteName("Select your site name");
    setLat("");
    setLong("");
    setAreaName("");
    setAreaID("");
  };

  const checkSite = () => {
    if (siteList.length !== 0) {
      setShowDialog(true);
    } else {
      toast.error("Please add at least one site first");
    }
  };

  const openDets = (d) => {
    // console.log('area_dets', d);
    var NAME = d.area_name;
    NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
    NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

    var LAT, LONG;

    if (d.area_gps !== undefined && d.area_gps !== null) {
      if (d.area_gps.lat !== undefined) {
        LAT = d.area_gps.lat;
      } else {
        LAT = d.area_gps.x;
      }

      if (d.area_gps.long !== undefined) {
        LONG = d.area_gps.long;
      } else {
        LONG = d.area_gps.y;
      }
    }

    setAreaID(d.area_uid);
    setAreaName(NAME);
    setLat(LAT);
    setLong(LONG);
    setShowDialog2(true);
  };

  const editArea = () => {
    setShowDialog2(false);
    const URL = `${version.expURL}/api2/area/edit_area`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      area_name: areaName,
      area_uid: areaID,
      area_gps: {
        lat: parseFloat(lat),
        long: parseFloat(long),
      },
      // area_name: areaName
    };

    // console.log("edit_area", body);

    axios.post(URL, body).then((res) => {
      // console.log("update_area", res);
      toast.success("Area updated successfully");
      refetchArea();
      cancelAddDevice();
      // fetchList()
    });
  };

  const checkArea = (d) => {
    var NAME = d.area_name;
    NAME = NAME.replace(/[^a-zA-Z0-9]/g, " ");
    NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

    setAreaID(d.area_uid);
    setAreaName(NAME);
    setShowDialog3(true);
  };

  const removeArea = () => {
    setShowDialog3(false);
    const URL = `${version.expURL}/api2/area/delete_area`;
    var userID = localStorage.getItem("UID");

    var body = {
      user_uid: userID,
      area_uid: areaID,
    };

    // console.log("delete_area", body);

    axios.post(URL, body).then((res) => {
      // console.log("update_area", res);
      if (res.data.status === "success") {
        toast.success("Area has been removed!");
        refetchArea();
        cancelAddDevice();
      } else {
        toast.error("Area chosen currently has unit binded to it");
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <h4 className={styles.title}>
          All Areas
          {/* <div 
                        className={`${styles.chevronContainer} ${sortType === "asc" && styles.sortAsc}`} 
                        onClick={()=>{setSortType(sortType==="asc"?"desc":"asc");}}
                    >
                        <ChevronDown />
                    </div> */}
        </h4>
        <div className={`${modalStyles.basicButton} ${modalStyles.active}`} onClick={checkSite}>
          <p className={modalStyles.buttonTitle}>Add Area</p>
        </div>
      </div>

      <div className={styles.tableContainer}>
        {
          <>
            {/* <TableScrollbar> */}
            {deviceTable()}
            {/* </TableScrollbar> */}
            {tier === 1 && (
              <div
                className={styles.upgradeLock}
                style={{
                  width: `${
                    distanceColWidth + visitingColWidth + speedColWidth + locationColWidth
                  }px`,
                }}
              >
                <Upgrade />
              </div>
            )}
          </>
        }
      </div>

      {showDialog && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Add Area</h3>

            <div className={modalStyles.addDeviceSelect}>
              <div className={modalStyles.inputContainer}>
                <h5 className={modalStyles.inputLabel}>Site Name</h5>
                <div>
                  <SelectBar
                    options={siteList}
                    value={siteName}
                    changeValue={(x) => {
                      setSiteName(x.site_name);
                      setSiteID(x.site_uid);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Area Name"
                inputId="areaName"
                value={areaName}
                onChange={(newValue) => {
                  setAreaName(newValue);
                }}
                type="text"
                placeholder="eg: Jalan 2, Bangunan B"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Latitude"
                inputId="lat"
                value={lat}
                onChange={(newValue) => {
                  setLat(newValue);
                }}
                type="number"
                placeholder="eg: 1.234"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Longitude"
                inputId="long"
                value={long}
                onChange={(newValue) => {
                  setLong(newValue);
                }}
                type="number"
                placeholder="eg: 101.234"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  checkDetails(e);
                }}
              >
                Add Area
              </div>
              <div
                onClick={cancelAddDevice}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showDialog2 && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Edit Area</h3>

            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Area Name"
                inputId="areaName"
                value={areaName}
                onChange={(newValue) => {
                  setAreaName(newValue);
                }}
                type="text"
                placeholder="eg: Jalan 2, Bangunan B"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Latitude"
                inputId="lat"
                value={lat}
                onChange={(newValue) => {
                  setLat(newValue);
                }}
                type="text"
                placeholder="eg: 1.234"
              />
            </div>
            <div className={modalStyles.addDeviceSelect}>
              <InputContainer
                label="Longitude"
                inputId="long"
                value={long}
                onChange={(newValue) => {
                  setLong(newValue);
                }}
                type="text"
                placeholder="eg: 101.234"
              />
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  editArea(e);
                }}
              >
                Update Area
              </div>
              <div
                onClick={cancelAddDevice}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showDialog3 && (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
            <h3 className={modalStyles.addDeviceTitle}>Remove Area</h3>
            <br />
            <h3 className={modalStyles.addDeviceTitle}>
              Are you sure you want to remove {areaName} ?
            </h3>
            <h4 className={modalStyles.addDeviceTitle}>
              Do note that this action cannot be undo.
              <br></br> You'll have to add a new one if you accidentally deleted it.
            </h4>
            <br />

            <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={(e) => {
                  removeArea(e);
                }}
              >
                Confirm Remove
              </div>
              <div
                onClick={cancelAddDevice}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

const InputContainer = ({ label, inputId, icon, value, onChange, type, placeholder }) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );
  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};

const SelectBar = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {value}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {d.site_name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SortableColumn = ({ sortType, sortBy, toggleSortType }) => {
  //onClick={()=>{console.log('sort clicked');toggleSortType();}}
  return (
    <span className={styles.sortableColumn}>
      {sortBy}
      <span className={`${styles.chevron} ${sortType === "asc" && styles.sortAscending}`}>
        <ChevronDownTable />
      </span>
    </span>
  );
};

export const Upgrade = () => {
  return (
    <div className={lockStyles.container}>
      <div className={lockStyles.lockLogo}>
        <UpgradeLock />
      </div>
      <p className={lockStyles.label}>Upgrade your plan to see more analytics</p>
    </div>
  );
};
