export const dotColor = (status) => {
    return status === "80-95%"?"#5c0fff":status === "50-79%"?"#ffac17":"#c8bde8"
}

export const checkIfDuplicateExists = (arr, prop) => {
    const props = arr.map(d => d[prop]);
    return new Set(props).size !== arr.length
}

export const getSecondsDiff = (startDate, endDate) => {
    const msInSecond = 1000;
  
    return Math.round(
        Math.abs(endDate - startDate) / msInSecond
    );
}

export const getMilisecondsDiff = (startDate, endDate) => {
    return Math.round(Math.abs(endDate - startDate));
}