import React, { useState, useEffect, useRef } from "react";
import TableScrollbar from "react-table-scrollbar";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { dotColor } from "../../global/functions";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import { MobileTablePagination } from "./DeviceList";
import { Pagination } from "../../global/Pagination";
import styles from "../../../../styles/plexyz/components/pages/dashboard/nearest-dev.module.css";
import { NextArrow } from "../../../../svg-shapes/NextArrow";

export const NearestDevices = ({
  data,
  tier,
  hoveredMac,
  setHoveredMac,
  filterMac,
  setFilterMac,
  isOpenNearest,
  toggleOpenNearest,
}) => {
  const rowRef = useRef(null);
  const tableRef = useRef(null);

  const [devices, setDevices] = useState([
    { name: "Samsung A52", status: "80-95%" },
    { name: "Samsung A52", status: "80-95%" },
    { name: "Samsung A52", status: "80-95%" },
    { name: "Samsung A52", status: "80-95%" },
    { name: "Iphone XR", status: "50-79%" },
    { name: "Iphone 11", status: "50-79%" },
    { name: "Oppo Reno 4", status: "<=49%" },
    { name: "Iphone XR", status: "50-79%" },
    { name: "Iphone 11", status: "50-79%" },
    { name: "Oppo Reno 4", status: "<=49%" },
  ]);
  const [example, setExample] = useState([
    { mac_address: "1:2:3", ago: "6" },
    { mac_address: "1:2:0", ago: "9" },
  ]);
  const [isExpand, setIsExpand] = useState(true);
  const [sortType, setSortType] = useState("desc");
  // const [filterString, setFilterString] = useState("");

  const statusOrder = ["80-95%", "50-79%", "<=49%"];
  const devicesData = () => {
    // console.log("valuue mana", data);

    let filtered = data.filter((x) => x.mac_address.includes(filterMac));

    const sortedData = filtered.slice().sort((a, b) => {
      if (statusOrder.indexOf(a.status) < statusOrder.indexOf(b.status)) {
        return sortType === "asc" ? 1 : -1;
      } else if (statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status)) {
        return sortType === "asc" ? -1 : 1;
      }
      return 0;
    });
    return sortedData;
  };

  const pageSize = 6;
  const { page, maxPage, prevPage, nextPage, tableData } = useTablePagination(
    devicesData(),
    pageSize
  );

  const renderTable = () => {
    return (
      <>
        {tableData.map((d, i) => {
          return (
            <tr
              key={i}
              className={`${styles.dataRow} ${d.mac_address === hoveredMac && styles.hoveredMac}`}
              onMouseEnter={() => {
                setHoveredMac(d.mac_address);
              }}
              onMouseLeave={() => {
                setHoveredMac("");
              }}
              ref={i === 4 ? rowRef : null}
            >
              {/* <td>{ d.mac_address? d.mac_address : '-' }</td>     */}
              <td>{d.name ? d.name : "-"}</td>
              <td>
                {d.ago + " seconds ago" || "-"}
                {/* <div 
                                    className={styles.deviceDot} 
                                    style={{
                                        backgroundColor:dotColor(d.status)
                                    }}
                                />     */}
              </td>
              {/* {tier > 1 && (
                <td>{d.distance ? parseFloat(d.distance.toFixed(3)) + "m" : "not available"}</td>
              )} */}
              <td>{d.distance ? parseFloat(d.distance.toFixed(3)) + "m" : "not available"}</td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <div
      className={`${styles.container} ${!isOpenNearest && styles.closed}`}
      onClick={() => {
        if (!isOpenNearest) {
          toggleOpenNearest();
        }
      }}
    >
      <div className={styles.nextArrow} onClick={toggleOpenNearest}>
        <NextArrow />
      </div>
      <div className={styles.bottomInfo}>
        <h4 className={styles.title}>
          Nearest Devices
          {/* <div 
                        className={`${styles.chevronContainer} ${sortType === "asc" && styles.sortAsc}`} 
                        onClick={()=>{setSortType(sortType==="asc"?"desc":"asc");}}
                    >
                        <ChevronDown />
                    </div> */}
          <div
            className={styles.inputContainer}
            style={{ justifyContent: "flex-end", alignItems: "center", marginRight: 18 }}
          >
            <input
              style={{ color: "#000" }}
              className={`${styles.input} ${!isExpand && styles.closedSearch}`}
              placeholder="Search"
              value={filterMac}
              onChange={(x) => {
                setFilterMac(x.target.value);
              }}
              type="text"
            />
            <img
              className={styles.searchIcon}
              onClick={() => {
                setIsExpand(!isExpand);
                // console.log('betul ke,', filterWord);
              }}
              src={process.env.PUBLIC_URL + "/images/topNavbar/searchIcon.svg"}
              alt="Search"
            />
          </div>
        </h4>
        <p className={styles.about}>
          Here is the list of top 100 nearest devices from the unit location.
        </p>
        <div className={styles.tableContainer}>
          <table className={styles.devTable} ref={tableRef}>
            <thead>
              <tr className={styles.tableHeader}>
                {/* <th>
                                    Type
                                </th> */}
                <th>MAC</th>
                {/* <th>
                                    Status
                                </th> */}
                <th>Detection time</th>
                {/* {tier > 1 && <th>Distance</th>} */}
                <th>Distance</th>
              </tr>
            </thead>
            <tbody>{devicesData().length > 0 && renderTable()}</tbody>
          </table>
        </div>
        <Pagination
          itemCount={tableData.length}
          totalItem={data.length}
          page={page}
          maxPage={maxPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );
};

/*
<TableScrollbar>
                        
                    </TableScrollbar>*/
