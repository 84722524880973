import React, { useState, useEffect } from "react";
import axios from "axios";
import { Client, connect as mqttConnect } from "mqtt";
import { TopToolbar } from "./TopToolbar";
import { Upgrade } from "./Upgrade";
import { TotalDevices } from "./TotalDevices";
import { DeviceList } from "./DeviceList";
import { PeakHours } from "./PeakHours";
import { DeviceDemographic } from "./DeviceDemographic/index";
import { TotalVisitorStats } from "./TotalVisitorStats";
import { TotalAvgTime } from "./TotalAvgTime";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { Loader } from "../../global/Loader";
import { BeaconContext } from "./context/";
import { ConnectionMeasure } from "./connection-measure";
import { nearestDevicesRaw } from "../../consts";
import { MobileContent } from "./mobile-dashboard";
import { TopAnalytics } from "./top-analytics";
import { checkIfDuplicateExists, getSecondsDiff } from "../../global/functions";
import { version } from "../../../../utils/const";
import styles from "../../../../styles/plexyz/components/pages/dashboard/dashboard.module.css";
import switchstyles from "../../../../styles/plexyz/components/pages/dashboard/view-switch.module.css";
import { BottomToolbar } from "./bottomToolbar";

export const Home = () => {
  const [tier, setTier] = useState(1);
  const [nearestDevices, setNearestDevices] = useState([]);
  const [duration, setDuration] = useState("10s");
  const [fetchType, setFetchType] = useState("Realtime");
  const [historyTime, setHistoryTime] = useState({
    startTime: null,
    endTime: null,
  });
  const [prevFetchParams, setPrevFetchParams] = useState({
    fetchType: "Historical",
    historyTime: { startTime: null, endTime: null },
    plexyzID: "PLEXYZ0008",
  });
  const [peak12To1, set12to1Peak] = useState(0);
  const [peak4To7, set4to7Peak] = useState(0);
  const [plexyzIDs, setPlexyzIDs] = useState(["--Please create or bind unit to space--"]);
  const [plexyzID, setPlexyzID] = useState("");
  const [loading, setLoading] = useState(false);
  const [bindedUnits, setBindedUnits] = useState([]);
  const [selectedUnitData, setSelectedUnitData] = useState(null);

  const [stopWebsocket, setStopWebsocket] = useState(false);
  const [expiredLimit, setExpiredLimit] = useState(1);
  const [clientMQTT, setClientMQTT] = useState(null);

  const [socketTicks, setSocketTicks] = useState([]);

  const { width } = useWindowDimensions();
  const isMobile = width <= 600;
  const rootUrl = version.expURL;
  const URLBeaconInfo = rootUrl + "/api2/plexyz/beacon_info";

  const matchFetchParams = () => {
    let newFetchParams = { historyTime: {} };
    newFetchParams.fetchType = fetchType;
    newFetchParams.historyTime.startTime = historyTime.startTime;
    newFetchParams.historyTime.endTime = historyTime.endTime;
    newFetchParams.plexyzID = plexyzID;
    setPrevFetchParams(newFetchParams);
  };

  const fetchParamsChanged = () => {
    if (prevFetchParams.fetchType !== fetchType) {
      return true;
    }
    if (prevFetchParams.historyTime.startTime !== historyTime.startTime) {
      return true;
    }
    if (prevFetchParams.historyTime.endTime !== historyTime.endTime) {
      return true;
    }

    if (prevFetchParams.plexyzID !== plexyzID) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   console.log("Screen width:", width);
  //   console.log("isMobile:", isMobile);
  // }, [width]);

  useEffect(() => {
    try {
      fetchBindedUnits();
    } catch (error) {
      console.log(error);
    }

    setTier(parseInt(localStorage.getItem("tier")));
  }, []);

  useEffect(() => {
    if (plexyzID !== "" /* && nearestDevices.length > 0*/) {
      setNearestDevices([]);
      setSocketTicks([]);
    }
  }, [plexyzID]);

  const { startTime, endTime } = historyTime;

  useEffect(() => {
    setNearestDevices([]);
    if (fetchType === "Historical") {
      if (startTime !== null && endTime !== null) {
        // fetchHistorical();
      }
    } else if (fetchType === "Realtime") {
      setHistoryTime({ startTime: null, endTime: null });
    }
    //getHardCodedDevices();
  }, [
    fetchType,
    startTime,
    endTime,
    /*plexyzID, 
        duration, 
        prevFetchParams.fetchType, 
        prevFetchParams.historyTime.startTime,
        prevFetchParams.historyTime.endTime,
        prevFetchParams.plexyzID*/
  ]);

  const fetchBindedUnits = () => {
    try {
      let role = localStorage.getItem("role").toLowerCase();

      const URL =
        role === "admin"
          ? `${version.expURL}/api2/admin/all_binded_units`
          : `${version.expURL}/api2/unit/binded_unit_list`;
      const body = {
        user_uid: localStorage.getItem("UID"),
      };
      axios.post(URL, body).then((res) => {
        // console.log("axios binded_unit_list", res.data.data);
        if (res.data.status === "success") {
          if (res.data.data === 0) {
            setPlexyzIDs([]);
            setPlexyzID("");
          } else {
            let returnlist = res.data.data;
            let IDSarray = returnlist.map((unit) => {
              return unit.device_id;
            });
            setPlexyzIDs(IDSarray);
            setPlexyzID(IDSarray[0]);
            setBindedUnits(returnlist);
            setSelectedUnitData(returnlist[0]);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const _mqttConnect = async () => {
    try {
      // console.log("doing_mqttConnect");
      if (clientMQTT) {
        // console.log("dah ade ClientMQTT la, xyh connect lagi sekali");
      } else {
        // console.log("ok betul2 xde ClientMQTT lagi boleh try _mqttConnect");

        let URL = `${version.expURL}/api/auth/user_pubsub`;
        let body = {
          user_uid: localStorage.getItem("UID"),
        };

        let respond = await axios.post(URL, body);
        let pubsub_username, pubsub_password, pubsub_clientId;

        if (respond.data) {
          pubsub_username = respond.data.pubsub.username;
          pubsub_password = respond.data.pubsub.password;
          pubsub_clientId = respond.data.pubsub.client_id;
        }

        /**
         * * code atas ni fetch user dashboard mqtt pubsub credential
         * * Bawah utk buat connection ke mqtt guna staging punya credential
         */

        let host2 = version.mqttHost;

        let options2 = {
          username: pubsub_username,
          password: pubsub_password,
          clientId: pubsub_clientId,
          keepalive: 60,
          protocolId: "MQTT",
          protocolVersion: 4,
          clean: true, // try false first same like default
          reconnectPeriod: 1000,
          connectTimeout: 30 * 1000,
          // will: {
          //     topic: "WillMsg",
          //     payload: "Connection Closed abnormally..!",
          //     qos: 0,
          //     retain: false,
          // },
        };
        const client3 = mqttConnect(host2, options2);
        setClientMQTT(client3);
        // console.log(clientMQTT);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      // console.log("useEffect client.on", { clientMQTT }, { selectedUnitData });
      // console.log("check clientMQTT 1", JSON.stringify(clientMQTT))
      if (selectedUnitData) {
        if (clientMQTT && fetchType == "Realtime") {
          // console.log("check clientMQTT 2", JSON.stringify(clientMQTT.connected))
          // console.log(
          //   "baruuuuuu la ade",
          //   selectedUnitData.space.site_uid,
          //   selectedUnitData.space.area_uid
          // );
          //, "raw/site_d3023b8c-305a-4365-bb2e-cdf2287e7714/area_8e2216bb-5b07-4072-ac0f-646089d191d8")
          try {
            let TOPIC =
              "distance/" + selectedUnitData.space.site_uid + "/" + selectedUnitData.space.area_uid;

            let return_message = "topic available";

            //     clientMQTT.unsubscribe(TOPIC, error => {
            //     if (error) {
            //       console.log('Unsubscribe error', error)
            //       return
            //     }
            //     else {
            //         console.log("unsubscribe_success");
            //     }
            // })
            // console.log(TOPIC);

            clientMQTT.on("error", () => {
              // console.log("error");
            });

            clientMQTT.on("close", () => {
              // console.log("close");
            });

            clientMQTT.on("end", () => {
              // console.log("end");
            });

            clientMQTT.on("reconnect", () => {
              // console.log("reconnect");
            });

            clientMQTT.on("connect", () => {
              // console.log("Client connected");
            });

            clientMQTT.subscribe(TOPIC, { qos: 0 }, (err, granted) => {
              if (err) {
                return_message = "no topic, this is inside error";
                // console.log(return_message);
              } else {
                // console.log(return_message);
                // console.log("granted", granted);
                // console.log("granted_topic", granted[0].topic);
              }
            });

            clientMQTT.on("message", (topic, message, packet) => {
              // Convert the message to a string and then parse it to JSON
              let MSG = message.toString();
              if (topic === TOPIC) {
                let now = Date.now();
                let min = 1;
                // let six_min = now - 1000 * 60 * 6; // 6 minutes ago
                let one_min = now - 1000 * 60 * 1; // 6 minutes ago
                let one_min_ago = now - 1000 * 60 * min;

                // Parse the incoming message as JSON
                let MSG_json = JSON.parse(MSG);

                // Convert the timestamp to milliseconds
                let msg_in = parseInt(MSG_json.timestamp) * 1000;

                // Check if the message timestamp is within the last minute
                if (msg_in > one_min_ago) {
                  // Process the message if it is within the timeframe
                  const now = Date.now();

                  const { x, y, distance, name } = calculateRandomDistance(MSG_json);
                  MSG_json.name = name;
                  MSG_json.x = x;
                  MSG_json.y = y;
                  MSG_json.distance = distance;
                  MSG_json.lastDetected = now;

                  // Update the nearestDevices state
                  setNearestDevices((nearestDevices) => {
                    // Log the current state before updating
                    // console.log("Current nearestDevices:", nearestDevices);

                    // Check if the device with the same mac_address already exists
                    const deviceIdx = nearestDevices.findIndex(
                      (d) => d.mac_address === MSG_json.mac_address
                    );

                    let updatedDevices;
                    if (deviceIdx === -1) {
                      // If the device does not exist, add it to the array
                      MSG_json.ago = ((now - MSG_json.timestamp * 1000) / 1000).toFixed();
                      updatedDevices = [...nearestDevices, MSG_json];
                    } else {
                      // If the device already exists, update its lastDetected time and other properties
                      nearestDevices[deviceIdx] = { ...nearestDevices[deviceIdx], ...MSG_json };
                      nearestDevices[deviceIdx].ago = (
                        (now - nearestDevices[deviceIdx].timestamp * 1000) /
                        1000
                      ).toFixed();
                      updatedDevices = nearestDevices;
                    }

                    // Remove devices that haven't been detected within the last 6 minutes
                    // console.log("Running 6-minute filter...");
                    const filteredDevices = updatedDevices.filter(
                      (device) => device.lastDetected > one_min
                    );

                    // Log the updated state
                    // console.log("Updated nearestDevices:", filteredDevices);

                    return filteredDevices;
                  });
                } else {
                  // console.log("Older than 1 min:", (one_min_ago - msg_in) / 1000, "sec");
                }

                const secondsToExpire = 60;
                setSocketTicks((socketTicks) => {
                  const newTicks = [...socketTicks, { time: new Date(), value: 1 }].filter(
                    (d) => getSecondsDiff(d.time, new Date()) <= secondsToExpire
                  );
                  return newTicks;
                });
              } else {
                // console.log("No matching topic found");
              }
            });

            // console.log(clientMQTT);
          } catch (error) {
            console.log(error);
          }
        } else {
          if (clientMQTT) {
            // console.log(
            //   "clientMQTT dah adeeeeee, akan run ke line ni???? maybe now will. fetchType is Historical ???"
            // );
          } else {
            // console.log("selectedUnit dh ade, now takde clientMQTT, leklu buat client jap");
            _mqttConnect();
          }
        }
      } else {
        // console.log("no selectedUnitData???? why T.T");
      }

      return () => {
        // console.log("return useEffect client.on", { clientMQTT }, { selectedUnitData });
        if (clientMQTT) {
          clientMQTT.end(() => {
            // console.log("disconnect");
            setClientMQTT(null);
            // console.log(fetchType);
            // if (fetchType == "Historical"){
            //     console.log("betul_ke?");
            //     setClientMQTT(null);
            //     console.log(clientMQTT);
            // }
          });
        } else {
          // console.log("do nothing");
        }
      };
    } catch (error) {
      console.log(error);
    }
  }, [clientMQTT, selectedUnitData, fetchType, stopWebsocket]);

  const calculateRandomDistance = (data) => {
    const { x, y, manufacturer, ...rest } = data;

    let device = {
      name: manufacturer === "None" ? "Unknown" : manufacturer,
      ...rest,
    };

    const { distance } = { ...rest };
    let max = distance;
    let min = 0;
    let plusMinusX = Math.random() < 0.5 ? -1 : 1;
    let plusMinusY = Math.random() < 0.5 ? -1 : 1;
    let random_x = parseFloat(
      (plusMinusX * (Math.random() * (max - min + 0.001) + min)).toFixed(3)
    );
    let random_y = parseFloat(
      (plusMinusY * Math.sqrt(Math.pow(distance, 2) - Math.pow(random_x, 2))).toFixed(3)
    );

    //device.x = random_x;
    //device.y = random_y;
    //device.distance = distance;

    return {
      x: random_x,
      y: random_y,
      distance,
      name: manufacturer === "None" ? "Unknown" : manufacturer,
    };
  };

  const deviceCount = 0; //nearestDevices.length;

  const totalDuration = () => {
    if (deviceCount > 0) {
      return nearestDevices.reduce((prev, curr) => {
        if (curr.duration) {
          prev += curr.duration;
        }
        return prev;
      }, 0);
    }

    return 0;
  };

  const avgTime =
    Math.floor(totalDuration / deviceCount / 60) > 0
      ? Math.floor(totalDuration / deviceCount / 60) +
        ":" +
        Math.floor((totalDuration / deviceCount) % 60)
      : Math.floor((totalDuration / deviceCount) % 60);
  const avgTimeUnit = Math.floor(totalDuration / deviceCount / 60) > 0 ? "min" : "sec";

  const topToolbar = () => {
    return (
      <TopToolbar
        fetchType={fetchType}
        setFetchType={setFetchType}
        setHistoryTime={setHistoryTime}
        plexyzID={plexyzID}
        setStopWebsocket={setStopWebsocket}
        stopWebsocket={stopWebsocket}
        setNearestDevices={setNearestDevices}
      />
    );
  };

  const bottomToolbar = () => {
    return <BottomToolbar />;
  };

  const desktopContent = () => {
    return (
      <div className={styles.container}>
        {topToolbar()}
        <TopAnalytics
          nearestDevices={nearestDevices}
          tier={tier}
          fetchType={fetchType}
          historyTime={historyTime}
          fetchParamsChanged={fetchParamsChanged}
        />
        {bottomToolbar()}
      </div>
    );
  };

  const deviceList = () => <DeviceList data={nearestDevices} tier={tier} />;

  const peakHours = () => <PeakHours peak12To1={peak12To1} peak4To7={peak4To7} />;

  const totalAvgTime = () => <TotalAvgTime avgTime={avgTime} avgTimeUnit={avgTimeUnit} />;

  return (
    <BeaconContext.Provider
      value={{
        plexyzIDs,
        plexyzID,
        setPlexyzID,
        bindedUnits,
        selectedUnitData,
        setSelectedUnitData,
        URL: URLBeaconInfo,
      }}
    >
      {isMobile ? (
        <MobileContent
          top={topToolbar}
          nearestDevices={nearestDevices}
          tier={tier}
          canFullSwipe={false}
          plexyzID={plexyzID}
        >
          <>
            <TotalDevices data={nearestDevices.length} />
            {deviceList()}
            <TotalVisitorStats />
            {totalAvgTime()}
            {peakHours()}
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#40316b",
              }}
            >
              <Upgrade />
            </div>
            <DeviceDemographic />
          </>
        </MobileContent>
      ) : (
        <>{desktopContent()}</>
      )}
      <ConnectionMeasure socketTicks={socketTicks} />
      {loading && <Loader />}
    </BeaconContext.Provider>
  );
};
