import React, { useState } from "react";
import { Modal } from "../../global/Modal";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import axios from "axios";
import { version } from "../../../../utils/const";
import { toast } from "react-toastify";


export const UnbindUnit = ({unitData, setUnbindDialog, refetchUnit}) => {

    const unbindUnit = async (d) => {

        const URL = `${version.expURL}/api2/space/unbind_space`;
        const userID = localStorage.getItem("UID");
    
        const body = {
          user_uid: userID,
          unit_uid: d.unit_uid,
        };
    
        let respond = await axios.post(URL, body);
        // console.log(respond);
        if (respond.data.status === "success") {
          // fetchList()
          refetchUnit();
          toast.success("Unit has successfully been unbinded from its previous space and area");
        }
        else {
          toast.error(respond.data.message)
        }
    }

    return (
        <Modal>
            <div className={modalStyles.addDeviceDialog} >
                <h3 className={modalStyles.addDeviceTitle}>
                    Unbind Unit Confirmation
                </h3>
                <br/>
                <h3 className={modalStyles.addDeviceTitle}>Are you sure you want to unbind {unitData.device_id} ?</h3>
                <h4 className={modalStyles.addDeviceTitle}>
                    Do note that this action cannot be undo.
                    <br></br> Space and area binded to this unit will be cleared.
                    <br></br> You'll have to rebind the unit if you accidentally unbinded it.
                </h4>
                <br/>

                <div className={modalStyles.addDeviceSelect}>
                      
                    <div
                        className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                        onClick={() => {
                            unbindUnit(unitData);
                            setUnbindDialog(false)
                            refetchUnit()
                        }}
                    >
                        Confirm Unbind
                    </div>
                    <div
                        onClick={() => {setUnbindDialog(false)}}
                        className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}>
                        Cancel
                    </div>
                </div>
            </div>
        </Modal>

        
    )
}