import "dotenv/config";

const version = {
  // production, staging or development
  mode: "production",
  expURL: process.env.REACT_APP_EXPRESS_URL_PRODUCTION,
  mqttHost: process.env.REACT_APP_MQTT_HOST_PRODUCTION,
};

export { version };
