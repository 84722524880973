import React, { useEffect, useState } from "react";
// import { UserInfo } from "./AccountOverview";
// import { TierOverview } from "./TierOverview";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import { Pagination } from "../../global/Pagination";
import { Modal } from "../../global/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../../../styles/plexyz/components/pages/overview/overview.module.css";
import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import mainStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import { UserInfo } from "../user-account/AccountOverview";
import { TierOverview } from "../user-account/TierOverview";
import TotalUnitsTable from "./TotalUnitsTable";
import TotalOfflineTable from "./TotalOfflineTable";
import TotalOnlineTable from "./TotalOnlineTable";
import TotalReportsTable from "./TotalReportsTable";
import { version } from "../../../../utils/const";

export const AdminDashboardOverview = () => {
  const [testData, setTestData] = useState([{ _id: 1, total: 10 }]);

  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    const fetchUserUnit = async () => {
      try {
        console.log("inside fetchUserUnit");
        const URL = `${version.expURL}/api2/admin/all_unit_list`;
        var userID = localStorage.getItem("UID");

        var body = { user_uid: userID };

        const response = await axios.post(URL, body);
        const result = response.data;
        setUnitList(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserUnit();
  }, []);

  return (
    // <div className={styles.mainContainer}>
    //   <div className={styles.container} style={{ marginTop: 15 }}>
    //     <div className={styles.topBar}>
    //       <h4 className={styles.title}>Overview</h4>
    //     </div>
    //     <div className={`${styles.tableContainerGrid}`}>
    //       {unitList.length !== 0 ? (
    //         <>
    //           {/* <ToastContainer /> */}
    //           <TotalUnitsTable tableData={unitList} />
    //           <TotalOnlineTable tableData={testData2} />
    //           <TotalOfflineTable tableData={testData3} />
    //           <TotalReportsTable tableData={testData} />
    //         </>
    //       ) : null}
    //     </div>
    //   </div>
    // </div>
    <div className={styles.mainContainer}>
      {/* First Card */}
      <div className={styles.card}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>Total Units</h4>
        </div>
        <div className={`${styles.tableContainer}`}>
          {unitList.length !== 0 ? <TotalUnitsTable tableData={unitList} /> : null}
        </div>
      </div>

      {/* Second Card */}
      <div className={styles.card}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>Total Online Units</h4>
        </div>
        <div className={`${styles.tableContainer}`}>
          {unitList.length !== 0 ? <TotalOnlineTable tableData={unitList} /> : null}
        </div>
      </div>

      {/* Third Card */}
      <div className={styles.card}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>Total Offline Units</h4>
        </div>
        <div className={`${styles.tableContainer}`}>
          {unitList.length !== 0 ? <TotalOfflineTable tableData={unitList} /> : null}
        </div>
      </div>

      {/* Fourth Card */}
      <div className={styles.card}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>Total Reports</h4>
        </div>
        <div className={`${styles.tableContainer}`}>
          {unitList.length !== 0 ? <TotalReportsTable tableData={testData} /> : null}
        </div>
      </div>
    </div>
  );
};
