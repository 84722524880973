import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BeaconList } from "./BeaconList";
import { SpaceList } from "./SpaceList";
import { AreaList } from "./AreaList";
import { BeaconGrid } from "./BeaconGrid";

import { version } from "../../../../utils/const";
import { Modal } from "../../global/Modal";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";

import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import styles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";

import toolbarStyles from "../../../../styles/plexyz/components/pages/dashboard/top-toolbar.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

export const Devices = () => {
  const [unitList, setUnitList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [inputAreaList, setInputAreaList] = useState([]);
  const [showModal1, setShowModal1] = useState(false);

  const [siteID, setSiteID] = useState("");
  const [siteName, setSiteName] = useState("Select your site name");
  const [areaName, setAreaName] = useState("Select your site first");
  const [areaID, setAreaID] = useState("");
  const [areaGPS, setAreaGPS] = useState("");
  const [unitCoor, setUnitCoor] = useState("");
  const [unitID, setUnitID] = useState("");
  const [unitName, setUnitName] = useState("Select your unit");
  const [xCoor, setXCoor] = useState("0");
  const [yCoor, setYCoor] = useState("0");
  const [status, setStatus] = useState(false);
  const [coor, setCoor] = useState(false);
  const [listCoor, setListCoor] = useState([]);
  const [ssid, setSSID] = useState("");
  const [password, setPassword] = useState("");
  const [gridView, setGridView] = useState(false);
  const [wifiNearby, setWifiNearby] = useState([]);
  const [manualWifi, setManualWifi] = useState(false);

  useEffect(() => {
    fetchSite();
    fetchArea();
    fetchUnit();
  }, []);

  const fetchSite = () => {
    const URL = `${version.expURL}/api2/site/user_site_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      var LIST = res.data.data;
      // console.log('site_list',res.data.data );
      LIST && setSiteList(LIST);
      // fetchList()
    });
  };

  const fetchArea = () => {
    const URL = `${version.expURL}/api2/area/user_area_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      // console.log('aaa', res.data.data);
      var LIST = res.data.data;
      LIST && setAreaList(LIST);
    });
  };

  const fetchUnit = () => {
    console.log("rthiahsdiahs is FETCHUNIT");
    const URL = `${version.expURL}/api2/unit/user_unit_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      setUnitList(res.data.data);
    });
  };
  const fetchWifiNearby = (unit_uid) => {
    const URL = `${version.expURL}/api2/unit/wifi_nearby_list`;
    const body = { unit_uid: unit_uid };

    axios.post(URL, body).then((res) => {
      console.log("wifi_nearby", res.data.data);

      if (res.data.data !== null) {
        let ssid_list = res.data.data[0].network_list.map((x) => {
          return x.essid;
        });
        setWifiNearby(ssid_list);
      }
    });
  };

  //============== func ===================
  const toggleGridView = () => {
    setGridView(!gridView);
  };

  const checkSpace = () => {
    if (siteID === "") {
      toast.error("Site Name is required");
      return;
    } else if (areaID === "") {
      toast.error("Area Name is required");
      return;
    } else if (siteID && areaID) {
      const URL = `${version.expURL}/api2/space/check_space`;
      var userID = localStorage.getItem("UID");

      var body = {
        user_uid: userID,
        area_uid: areaID,
        site_uid: siteID,
      };

      axios.post(URL, body).then((res) => {
        console.log("result", res);
        var STATUS = res.data.status;
        if (STATUS === "success") {
          setCoor(true);
          setShowModal1(true);
          setStatus(true);
          setListCoor(res.data.coordinates);
        } else {
          toast.error(
            "The Site and Area selected already has unit binded to it. Please try with a different site or area."
          );
          return;
        }

        //if(failed, unit_coordinate: x:0, y:0 else input value)
        // setAreaList(LIST)
      });
    }
  };

  const bindUnit = () => {
    //checker
    if (unitID === "") {
      toast.error("Unit ID is required");
      return;
    } else {
      setShowModal1(false);

      const URL = `${version.expURL}/api2/space/bind_space`;
      var userID = localStorage.getItem("UID");

      var body = {
        user_uid: userID,
        unit_uid: unitID,
        site_uid: siteID,
        // site_name: siteName,
        area_uid: areaID,
        // area_name: areaName,
        // area_gps: areaGPS,
        unit_coordinate: {
          x: parseFloat(xCoor),
          y: parseFloat(yCoor),
        },
      };

      const URL2 = `${version.expURL}/api2/unit/update_wifi`;

      var body2 = {
        user_uid: userID,
        unit_uid: unitID,
        ssid: ssid,
        password: password,
      };

      // console.log('bodyyy', body);

      axios.post(URL, body).then((res) => {
        console.log("bind space", res);
        var STATUS = res.data.status;
        if (STATUS === "success") {
          // console.log('yummmmmm');
          toast.success("Unit has been binded successfully");
        }

        axios.post(URL2, body2).then(async (res) => {
          console.log("update wifi", res);
          try {
            const mysql_URL = `${version.expURL}/api2/mysql/update_unit_mqtt_topic`;
            var mysql_body = {
              username: userID,
              client_id: unitID,
              unit_id: unitName,
              area_uid: areaID,
              site_uid: siteID,
            };
            console.log({ mysql_body });
            let mysql_respond = await axios.post(mysql_URL, mysql_body);
            console.log({ mysql_respond });
          } catch (error) {
            console.log(error);
          }
          fetchUnit();
        });
      });
    }
  };

  const bindButton = () => {
    let available_unit = [];
    unitList.forEach((d) => {
      if (d.space === null || d.space === undefined) {
        available_unit.push(d);
      }
    });

    if (available_unit.length !== 0) {
      setShowModal1(true);
    } else {
      toast.error("No more available unit to be binded. Please add a new unit");
    }
  };

  // const [values, setValues] = useState({
  //     siteID: "",
  //     siteName: "",
  //     areaID: "",
  //     areaName: "",
  //     unitID: "",
  //     status: false,
  //     xCoor: 0,
  //     yCoor: 0
  // });

  const cancelAddDevice = () => {
    setShowModal1(false);
    setSiteID("");
    setSiteName("Select your site name");
    setAreaID("");
    setAreaName("Select your site first");
    setInputAreaList([]);
    setUnitID("");
    setUnitName("Select your unit");
    setStatus(false);
    setCoor(false);
    setXCoor(0);
    setYCoor(0);
  };

  const SelectBar4 = ({ options, value, changeValue }) => {
    const [selectionsVisible, setSelectionsVisible] = useState(false);

    return (
      <div
        className={selectStyles.sortSelection}
        onClick={() => {
          setSelectionsVisible(!selectionsVisible);
        }}
      >
        {value}
        <div
          className={`${selectStyles.chevronContainer} ${
            selectionsVisible && selectStyles.selectOpen
          }`}
        >
          <ChevronDown />
        </div>
        {selectionsVisible && (
          <div
            className={selectStyles.options}
            onMouseLeave={() => {
              setSelectionsVisible(false);
            }}
          >
            {options.map((ssid_list, i) => {
              // console.log('options_device', d);
              return (
                <div
                  className={selectStyles.option}
                  key={i}
                  onClick={() => {
                    changeValue(ssid_list);
                  }}
                >
                  {ssid_list}
                </div>
              );
            })}
            <div
              className={selectStyles.option}
              onClick={() => {
                setManualWifi(true);
              }}
            >
              none (input ssid manually)
            </div>
          </div>
        )}
      </div>
    );
  };

  const checkSite = (x) => {
    let areass = areaList.filter((area) => area.site_uid === x.site_uid);

    if (areass.length === 0) {
      setShowModal1(false);
      let MSG =
        x.site_name + " has no area yet. Please create at least one area for the site first.";
      toast.error(MSG);
      return;
    } else {
      setAreaName("Select your area name");
      setAreaID("");
      setAreaGPS("");

      setSiteName(x.site_name);
      setSiteID(x.site_uid);
      setInputAreaList(areass);
    }
  };

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.topTitle}>
                Devices
            </h1> */}
      <ToastContainer />
      {/* <div className={toolbarStyles.buttons}>
                    <div className={`${toolbarStyles.movement} ${!gridView && toolbarStyles.active}`}
                        onClick={()=>{
                            setGridView(false)
                            // setShow(false);
                        }}
                    >
                        List
                    </div>     
                    <div className={`${toolbarStyles.basicButton} ${gridView && toolbarStyles.active}`}
                        onClick={()=>{
                            setGridView(true)
                            // setShow(false);
                        }}
                    >
                        Grid    
                    </div>                                   
                </div> */}
      {gridView ? (
        <BeaconGrid
          data={unitList}
          siteList={siteList}
          areaList={areaList}
          refetchUnit={fetchUnit}
        ></BeaconGrid>
      ) : (
        <>
          <BeaconList
            data={unitList}
            refetchUnit={fetchUnit}
            siteList={siteList}
            areaList={areaList}
          />
        </>
      )}

      <SpaceList data={siteList} refetchSite={fetchSite} refetchArea={fetchArea} />
      <AreaList siteList={siteList} areaList={areaList} refetchArea={fetchArea} />
      {showModal1 && (
        <Modal className={modalStyles.longModalContainer}>
          <div className={modalStyles.longModal}>
            <h3 className={modalStyles.addDeviceTitle}>Bind Unit</h3>
            <div className={modalStyles.addDeviceSelect}>
              <div className={modalStyles.inputContainer}>
                <h5 className={modalStyles.inputLabel}>Site Name</h5>
                <div>
                  <SelectBar
                    options={siteList}
                    display="site_name"
                    value={siteName}
                    changeValue={(x) => {
                      checkSite(x);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={modalStyles.addDeviceSelect}>
              <div className={modalStyles.inputContainer}>
                <h5 className={modalStyles.inputLabel}>Area Name</h5>

                <div>
                  <SelectBar2
                    options={inputAreaList}
                    display="area_name"
                    value={areaName}
                    changeValue={(x) => {
                      setAreaName(x.area_name);
                      setAreaID(x.area_uid);
                      setAreaGPS(x.area_gps);
                    }}
                  />
                </div>
              </div>
            </div>

            {status && (
              <div>
                <div className={modalStyles.addDeviceSelect}>
                  <div className={modalStyles.inputContainer}>
                    <h5 className={modalStyles.inputLabel}>Unit ID</h5>
                    <div>
                      <SelectBar3
                        options={unitList}
                        value={unitName}
                        changeValue={(x) => {
                          setUnitName(x.device_id);
                          setUnitID(x.unit_uid);
                          fetchWifiNearby(x.unit_uid);
                          console.log(x.device_id, x.unit_uid);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={modalStyles.addDeviceSelect}>
                  <div className={modalStyles.inputContainer}>
                    <h5 className={modalStyles.inputLabel}>Unit's Wifi SSID</h5>
                    <div>
                      {manualWifi ? (
                        <InputContainer
                          options={wifiNearby}
                          inputId="ssid"
                          value={ssid}
                          onChange={(newValue) => {
                            setSSID(newValue);
                          }}
                          type="text"
                          placeholder="eg: WIFI-SSID"
                        />
                      ) : (
                        <SelectBar4
                          options={wifiNearby}
                          value={ssid}
                          changeValue={(x) => {
                            setSSID(x);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={modalStyles.addDeviceSelect}>
                  <div className={modalStyles.inputContainer}>
                    <div>
                      <InputContainer
                        label="Unit's Wifi Password"
                        inputId="password"
                        value={password}
                        onChange={(newValue) => {
                          setPassword(newValue);
                        }}
                        type="text"
                        placeholder="eg: WIFI-PASSWORD_1234"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {coor && (
              <div>
                <div className={modalStyles.addDeviceSelect}>
                  <div className={modalStyles.inputContainer}>
                    <div>
                      <InputContainer
                        label="Unit Coordinate X"
                        inputId="xCoor"
                        value={xCoor}
                        onChange={(newValue) => {
                          setXCoor(newValue);
                        }}
                        type="text"
                        placeholder="eg: 1.234"
                      />
                    </div>
                  </div>
                </div>
                <div className={modalStyles.addDeviceSelect}>
                  <div className={modalStyles.inputContainer}>
                    <div>
                      <InputContainer
                        label="Unit Coordinate Y"
                        inputId="yCoor"
                        value={yCoor}
                        onChange={(newValue) => {
                          setYCoor(newValue);
                        }}
                        type="text"
                        placeholder="eg: 101.2345"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={modalStyles.addDeviceSelect}>
              {!status ? (
                <div
                  className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                  onClick={(e) => {
                    checkSpace(e);
                  }}
                >
                  Check Space
                </div>
              ) : (
                <div
                  className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                  onClick={(e) => {
                    bindUnit(e);
                  }}
                >
                  Bind Unit
                </div>
              )}
              <div
                onClick={cancelAddDevice}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )}

      {siteList.length !== 0 && areaList.length !== 0 && unitList.length !== 0 ? (
        <div className={styles.bindButton} onClick={bindButton}>
          <span className={styles.bindText}>Bind unit to space</span>
        </div>
      ) : null}
    </div>
  );
};

const SelectBar = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;
  VALUE = VALUE.replace(/[^a-zA-Z0-9 ]/g, " ");
  VALUE = VALUE.charAt(0).toUpperCase() + VALUE.substring(1);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d.site_name;
            NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SelectBar2 = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);
  var VALUE = value;
  VALUE = VALUE.replace(/[^a-zA-Z0-9 ]/g, " ");
  VALUE = VALUE.charAt(0).toUpperCase() + VALUE.substring(1);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {VALUE}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            var NAME = d.area_name;
            NAME = NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            NAME = NAME.charAt(0).toUpperCase() + NAME.substring(1);

            return (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {NAME}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SelectBar3 = ({ options, value, changeValue }) => {
  const [selectionsVisible, setSelectionsVisible] = useState(false);

  return (
    <div
      className={selectStyles.sortSelection}
      onClick={() => {
        setSelectionsVisible(!selectionsVisible);
      }}
    >
      {value}
      <div
        className={`${selectStyles.chevronContainer} ${
          selectionsVisible && selectStyles.selectOpen
        }`}
      >
        <ChevronDown />
      </div>
      {selectionsVisible && (
        <div
          className={selectStyles.options}
          onMouseLeave={() => {
            setSelectionsVisible(false);
          }}
        >
          {options.map((d, i) => {
            // console.log('options_device', d);
            return d.space === null || d.space === undefined ? (
              <div
                className={selectStyles.option}
                key={i}
                onClick={() => {
                  changeValue(d);
                }}
              >
                {d.device_id}
              </div>
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};

const InputContainer = ({ label, inputId, icon, value, onChange, type, placeholder }) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={modalStyles.inputBox}
    />
  );

  return (
    <div className={modalStyles.inputContainer}>
      <h5 className={modalStyles.inputLabel} htmlFor={inputId}>
        {label}
      </h5>
      {inputElement()}
    </div>
  );
};
