import React from "react";

const PLExyzLogo = () => (
  <svg width="185" height="45" viewBox="0 0 185 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.4385 25.413C40.533 25.2292 39.6509 25.8139 39.4671 26.716C39.2232 27.9088 38.8624 29.0783 38.3879 30.1942C36.7006 34.1769 33.7103 37.3244 30.0516 39.2456C29.5137 39.5195 29.0593 39.7167 28.7419 39.8436C28.6751 39.7367 28.5982 39.6298 28.5047 39.5362L5.04605 16.081C4.95918 15.9941 4.86229 15.9206 4.76205 15.8571C5.65415 13.7254 6.96057 11.7307 8.69131 9.99999C9.48986 9.20144 10.3653 8.47306 11.2874 7.84157C12.0492 7.32034 12.2397 6.27789 11.7184 5.51944C11.1972 4.76098 10.1548 4.56719 9.3963 5.08842C8.30707 5.83685 7.27464 6.69554 6.33242 7.63776C-2.11081 16.081 -2.11081 29.8167 6.33242 38.2566C8.94858 40.8727 12.106 42.7204 15.514 43.7261C15.6845 43.8564 15.8816 43.9533 16.1054 44.0101C17.3851 44.3409 18.7082 44.5547 20.0414 44.6449C20.5426 44.6784 21.0404 44.6951 21.5382 44.6951C25.0498 44.6951 28.4345 43.8431 31.4449 42.2927C32.8816 41.5744 34.8095 40.3983 36.577 38.6308C36.6037 38.604 36.6204 38.574 36.6438 38.5472C38.6653 36.5793 40.3192 34.2037 41.4685 31.4973C42.0299 30.1775 42.4575 28.7909 42.7449 27.3809C42.9253 26.4788 42.3406 25.5967 41.4385 25.413ZM3.67616 19.4356L25.1568 40.9162C19.1526 42.0889 13.0382 40.2346 8.69465 35.891C4.2308 31.4271 2.55686 25.2125 3.67616 19.4356Z"
      fill="#9603FF"
    />
    <path
      d="M15.8851 21.2532C16.8707 20.3076 18.1671 19.7897 19.5303 19.7897C22.4372 19.7897 24.7994 22.1553 24.7994 25.0588C24.7994 26.3953 24.2982 27.6683 23.3894 28.6472C22.7612 29.3222 22.798 30.3813 23.4763 31.0095C23.797 31.3102 24.208 31.4572 24.6123 31.4572C25.06 31.4572 25.5077 31.2768 25.8352 30.9259C27.322 29.3288 28.1406 27.2473 28.1406 25.0621C28.1406 20.3143 24.2782 16.4518 19.5303 16.4518C17.2984 16.4518 15.1834 17.3005 13.5729 18.8475C12.908 19.4857 12.8846 20.5448 13.5228 21.2097C14.161 21.8679 15.2168 21.8913 15.8851 21.2532Z"
      fill="#9603FF"
    />
    <path
      d="M16.8539 12.7398C19.6337 11.6172 22.6809 11.6406 25.4374 12.81C28.1972 13.9794 30.3323 16.1545 31.4583 18.9311C32.6778 21.9448 32.5274 25.3362 31.0506 28.233C30.633 29.0549 30.9571 30.0606 31.7824 30.4783C32.0263 30.6019 32.2835 30.662 32.5408 30.662C33.1489 30.662 33.737 30.3279 34.031 29.7499C35.9488 25.9877 36.1393 21.5873 34.5589 17.6781C33.1021 14.073 30.3256 11.2496 26.7472 9.73273C23.1654 8.21248 19.2094 8.18241 15.6042 9.63918C14.7489 9.98666 14.3346 10.959 14.6821 11.8143C15.0229 12.6696 15.9985 13.084 16.8539 12.7398Z"
      fill="#9603FF"
    />
    <path
      d="M41.8729 12.5861C37.8567 3.22403 27.7529 -1.8145 17.8429 0.601189C16.9475 0.818367 16.3962 1.72383 16.6167 2.61928C16.8339 3.51472 17.736 4.06602 18.6348 3.8455C26.941 1.82407 35.4076 6.03399 38.7856 13.8658C39.113 14.7512 39.9016 17.2805 39.8481 18.9511C39.818 19.8733 40.5431 20.6451 41.4652 20.6751C41.4819 20.6751 41.502 20.6751 41.5187 20.6751C42.4175 20.6751 43.1592 19.9601 43.186 19.058C43.2695 16.3984 42.0399 13.0338 41.8996 12.6596L41.8729 12.5861Z"
      fill="#9603FF"
    />
    <path
      d="M76.0867 11.2228C74.8237 10.6114 73.3034 10.3007 71.5694 10.3007H62.1873V34.6012H66.337V25.6368H71.5694C73.3034 25.6368 74.8237 25.3261 76.0867 24.7146C77.353 24.0998 78.3553 23.2077 79.0704 22.0617C79.7854 20.9157 80.1462 19.5391 80.1462 17.9687C80.1462 16.375 79.7854 14.9917 79.0704 13.859C78.3587 12.7264 77.353 11.841 76.0867 11.2228ZM74.6767 20.9892C73.8915 21.6808 72.6385 22.0316 70.9512 22.0316H66.3404V13.8691H70.9512C72.6352 13.8691 73.8882 14.2199 74.6767 14.9115C75.4585 15.5998 75.8561 16.6289 75.8561 17.9687C75.8528 19.2852 75.4585 20.3009 74.6767 20.9892Z"
      fill="#9603FF"
    />
    <path d="M87.9413 10.3007H83.7915V34.6012H101.203V30.9961H87.9413V10.3007Z" fill="#9603FF" />
    <path
      d="M144.976 15.9808H140.181L135.911 22.4594L131.644 15.9808H126.633L133.225 25.1323L126.262 34.4476H131.023L135.647 27.7151L140.144 34.4476H145.083L138.344 25.0521L144.976 15.9808Z"
      fill="#9603FF"
    />
    <path
      d="M158.541 27.1805L157.462 30.5551L156.52 27.5046L152.447 15.9808H147.799L153.556 29.9637L155.467 34.4342L154.685 36.3554C154.284 37.3712 153.827 38.0561 153.329 38.3936C152.828 38.731 152.26 38.9014 151.631 38.9014C151.043 38.9014 150.485 38.8045 149.981 38.6174C149.473 38.427 148.989 38.143 148.541 37.7654L148.397 37.6451L147.365 40.9362L147.442 40.9897C148.01 41.384 148.678 41.7014 149.426 41.9353C150.175 42.1691 151.003 42.2861 151.885 42.2861C152.968 42.2861 153.93 42.099 154.742 41.7281C155.557 41.3572 156.292 40.7424 156.921 39.9038C157.545 39.0718 158.13 37.9325 158.668 36.5225L166.714 15.9841H162.397L158.541 27.1805Z"
      fill="#9603FF"
    />
    <path
      d="M177.001 31.1365L173.844 31.2033L175.822 29.1585L184.669 18.8475V15.9808H169.153V19.2919H177.078L179.938 19.2251L177.927 21.2699L169.079 31.5809V34.4476H185V31.1365H177.001Z"
      fill="#9603FF"
    />
    <path
      d="M104.844 10.3007H104.818V20.879V24.4607V34.6013L104.844 34.5946V34.6013H122.352V31.0229H108.292V24.4607H116.308V20.879H108.292V13.8624H122.352V10.284H104.844V10.3007Z"
      fill="#9603FF"
    />
  </svg>
);

export default PLExyzLogo;
