import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import styles from "../../../styles/plexyz/layout/top-navbar.module.css";
import PLExyzLogo from "../../../components/PLExyzLogo";
// import { Modal } from "../../global/Modal";

export const TopNavbar = () => {
  const [showDialog, setShowDialog] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const tier = localStorage ? parseInt(localStorage.getItem("tier")) : 1;
  const username = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  const logout = () => {
    localStorage.removeItem("UID");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("tier");
    localStorage.removeItem("username");
    localStorage.removeItem("phone");
    localStorage.removeItem("subscription");

    window.location.href = "/";
  };

  const mobileRouteText = () => {
    if (pathname === "/user-account/") {
      return "User Account";
    }

    const firstLetter = pathname.substring(1, 2).toUpperCase();
    const restLetter = pathname.substring(2, pathname.length - 1);

    return firstLetter.concat(restLetter);
  };

  const toggle = () => {
    setShowDialog(!showDialog);
  };

  return (
    <div className={styles.container}>
      {isMobile && <div className={styles.pageLabelMobile}>{mobileRouteText()}</div>}
      <div className={styles.logoContainer}>
        <PLExyzLogo className={styles.logo} />
      </div>
      <div className={styles.toolbarContainer}>
        <div className={styles.inputContainer}>
          <input className={styles.input} placeholder="Search" />
          <img
            className={styles.searchIcon}
            src={process.env.PUBLIC_URL + "/images/topNavbar/searchIcon.svg"}
            alt="Search"
          />
        </div>
        <div className={`${styles.menuWithIcon} ${styles.showAtMobile}`}>
          <img
            src={process.env.PUBLIC_URL + "/images/topNavbar/notifIcon.svg"}
            alt="Notification"
          />
        </div>
        <Dropdown isOpen={showDialog} toggle={toggle} size="sm">
          <DropdownToggle
            tag="span"
            onClick={toggle}
            data-toggle="dropdown"
            aria-expanded={showDialog}
            caret
            className={styles.dropdown_toggle}
          >
            <div
              className={`${styles.menuWithIcon} ${styles.hideAtMobile}`}
              onClick={() => setShowDialog(true)}
            >
              <img src={process.env.PUBLIC_URL + "/images/topNavbar/account.svg"} alt="Account" />
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Link to={"/user-account/"} style={{ textDecoration: "none", color: "black" }}>
                Account
              </Link>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                logout();
              }}
            >
              Logout
            </DropdownItem>
            {/* <DropdownItem divider />
                    <DropdownItem onClick={() => setShowDialog(false)} >Close</DropdownItem> */}
          </DropdownMenu>
        </Dropdown>

        {/* <div 
                    className={`${styles.menuWithIcon} ${styles.hideAtMobile}`}    
                    onClick={() => setShowDialog(true)}
                >
                    
                    <img src = {process.env.PUBLIC_URL + "/images/topNavbar/account.svg"} alt="Account"/>
                    {showDialog && 
                    

                    // <div className={styles.topModal}>
                    //     <Link to={"/user-account/"}>
                    //         <h4>Account</h4>
                    //     </Link>
                    //     <h4>Log Out</h4>
                    //     <h4 onClick={() => setShowDialog(false)} >Close</h4>
                    // </div>}
                    }
                </div> */}
        <div className={styles.userInfo}>
          <p className={styles.user}>Hello, {username.split(" ", 1).toString()}</p>
          <p className={styles.tier}>
            {role} tier {tier}
          </p>
        </div>
      </div>
    </div>
  );
};
