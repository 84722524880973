import React, { useState, useRef, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
// import { Button, Modal,ModalHeader, ModalBody,ModalFooter,Label,Input,Row, Col,} from "reactstrap";
import { Modal } from "../../global/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { version } from "../../../../utils/const";
import axios from "axios";

import { Select, SelectOptions } from "../../global/Select";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { ChevronDownTable } from "../../../../svg-shapes/ChevronDownTable";
import { UpgradeLock } from "../../../../svg-shapes/UpgradeLock";
import { dotColor } from "../../global/functions";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import lockStyles from "../../../../styles/plexyz/components/pages/dashboard/upgrade-lock.module.css";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";

export const AdminSite = ({data, areaData, user}) => {
    const [expandSite, setExpandSite] = useState(true);
    const [expandArea, setExpandArea] = useState(true);

    const deviceTable = () => {
        return (
          <table className={styles.devTable}>
            <thead>
              <tr className={styles.tableHeader}>
                <th>Site</th>
                <th>Location</th>
                <th>User Email</th>
                <th>Date Added</th>
              </tr>
            </thead>
            <tbody>
            {data.map((d,i) => {
                var COOR, TIME, USER, SITE_NAME
                if(d.site_gps !== undefined){
                    if(d.site_gps.lat !== undefined){
                        COOR = d.site_gps.lat + ',' + d.site_gps.long
                    } else {
                        COOR = d.site_gps.x + ',' + d.site_gps.y
                    }
                } else { COOR = 'NA'}

                TIME = d.timestamp_created 
                TIME = TIME.substring(0, TIME.indexOf("T"))

                if(d.user_uid !== null){
                    for (const key in user) {
                        if(d.user_uid === user[key].user_uid){
                            USER = user[key].email
                        }
                    }
                } else { USER = 'NA'}


                if(d.site_name !== undefined){
                    SITE_NAME = d.site_name
                    SITE_NAME = SITE_NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
                    SITE_NAME = SITE_NAME.charAt(0).toUpperCase() + SITE_NAME.substring(1);
                }

                return(
                    <tr key={i} >
                        <td>{SITE_NAME}</td>
                        <td>{COOR} </td>
                        <td> {USER} </td>
                        <td> {TIME} </td>
                    </tr>
                )
            })}
            </tbody>
          </table>
        );
      };

    const areaTable = () => {
    return (
        <table className={styles.devTable}>
        <thead>
            <tr className={styles.tableHeader}>
            <th>Site</th>
            <th>Area</th>
            <th style={{minWidth: 15}}>Location</th>
            <th>User Email</th>
            <th>Date Created</th>
            </tr>
        </thead>
        <tbody>
        {areaData.map((d,i) => {
            var COOR, TIME, USER, AREA_NAME
            if(d.area_gps !== undefined){
                if(d.area_gps.lat !== undefined){
                    COOR = d.area_gps.lat + ',' + d.area_gps.long
                } else {
                    COOR = d.area_gps.x + ',' + d.area_gps.y
                }
            } else { COOR = 'NA'}

            TIME = d.timestamp_created 
            TIME = TIME.substring(0, TIME.indexOf("T"))

            if(d.user_uid !== null){
                for (const key in user) {
                    if(d.user_uid === user[key].user_uid){
                        USER = user[key].email
                    }
                }
            } else { USER = 'NA'}

            var SITE_NAME
            if(d.site_uid !== null){
                for (const key in data) {
                    if(d.site_uid === data[key].site_uid){
                        SITE_NAME = data[key].site_name
                        SITE_NAME = SITE_NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
                        SITE_NAME = SITE_NAME.charAt(0).toUpperCase() + SITE_NAME.substring(1);
                    }
                }
            } else { SITE_NAME = 'NA'}

            AREA_NAME = d.area_name
            AREA_NAME = AREA_NAME.replace(/[^a-zA-Z0-9 ]/g, " ");
            AREA_NAME = AREA_NAME.charAt(0).toUpperCase() + AREA_NAME.substring(1);

          
            return(
                <tr key={i} >
                    <td>{SITE_NAME} </td>
                    <td>{AREA_NAME}</td>
                    <td>{COOR} </td>
                    <td>{USER} </td>
                    <td> {TIME} </td>
                    {/* <td>{d.user_uid !== null ? d.user_uid : 'NA'} </td> */}
                    {/* <td className={styles.editButton} onClick={() => { openDets(d)}}> Edit </td> */}
                </tr>
            )
        })}
        </tbody>
        </table>
    );
    };

    return(
        <div style={{width: '100%', marginLeft:330 }}>
            <div className={styles.container}>
            <div className={styles.topBar}>
                <h4 className={styles.title}>
                Sites Added
                </h4>
                <div className={styles.collapsible}  onClick={() => {setExpandSite(!expandSite)}}>
                {!expandSite ? 'Show less' : 'Show more'}
                <div 
                    className={`${styles.chevronContainer} ${!expandSite && styles.sortAsc}`} 
                   
                >
                    <ChevronDown />
                </div> 
                </div>
                
        
            </div>

            {!expandSite ? 
            <div className={styles.tableContainer}>
                {
                <>
                    <TableScrollbar>{deviceTable()}</TableScrollbar>
                    
                </>
                } 
            </div> : null}
        </div>
            <div className={styles.container}>
                <div className={styles.topBar}>
                    <h4 className={styles.title}>
                    Areas Added
                    </h4>
                    <div className={styles.collapsible}  onClick={() => {setExpandArea(!expandArea)}}>
                        {!expandArea ? 'Show less' : 'Show more'}
                        <div 
                            className={`${styles.chevronContainer} ${!expandArea && styles.sortAsc}`} 
                        
                        >
                            <ChevronDown />
                        </div> 
                    </div>
            
                </div>

                {!expandArea ? 
                <div className={styles.tableContainer}>
                    {
                    <>
                        <TableScrollbar>{areaTable()}</TableScrollbar>
                        
                    </>
                    }
                </div>

                : null }
                
            </div>

        </div>
        
    )
}