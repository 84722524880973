import React, { useEffect, useState } from "react";
// import { UserInfo } from "./AccountOverview";
// import { TierOverview } from "./TierOverview";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import { Pagination } from "../../global/Pagination";
import { Modal } from "../../global/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../../../styles/plexyz/components/pages/reports/reports.module.css";
import mainStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import { UserInfo } from "../user-account/AccountOverview";
import { TierOverview } from "../user-account/TierOverview";
import { version } from "../../../../utils/const";
import TableScrollbar from "react-table-scrollbar";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";

export const Reports = () => {
  const [mouseOver, setMouseOver] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const [data, setData] = useState([]);

  const history = useHistory();

  const role = localStorage.getItem("role");

  useEffect(() => {
    const Report = async () => {
      try {
        console.log("inside fetchUserUnit");
        const URL = `${version.expURL}/api2/user/report`;
        var userID = localStorage.getItem("UID");

        var body = { user_uid: userID };

        const response = await axios.post(URL, body);
        const result = response.data;
        console.log({ result });

        setData(result?.data?.reports);
      } catch (error) {
        return error;
      }
    };

    Report();
  }, []);

  const nextPage = (reportId) => {
    try {
      console.log("inside", reportId);
      history.push(`/reports/${reportId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const reportsTable = () => {
    return (
      <table className={styles.devTable}>
        <thead>
          <tr className={styles.tableHeader}>
            <th>Deployment Date</th>
            <th>Report Date</th>
            <th>Unit ID</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => {
            return (
              <tr key={i}>
                <td>{d?.deployment_date}</td>
                <td>{d?.report_date}</td>
                <td>{d?.unit_id}</td>
                <td>{d?.status}</td>
                <td className={mouseOver && activeRow === i ? styles.dataRowHighlight : null}>
                  <button
                    className={styles.editButton}
                    onClick={() => {
                      nextPage(i);
                    }}
                    // onMouseEnter={() => {
                    //   handleMouseOver(i);
                    // }}
                    // onMouseLeave={handleMouseLeave}
                  >
                    View Reports
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  return (
    <div className={mainStyles.container}>
      <div className={styles.container} style={{ marginTop: 15 }}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>All Reports</h4>

          <div style={{ flexDirection: "row", display: "flex" }}>
            {role === "Admin" ? (
              <div
                className={`${styles.basicButton} ${styles.active}`}
                // onClick={() => setShowDialog(true)}
              >
                <p className={styles.buttonTitle}>Add Report</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.tableContainer}>
          {
            // tableData.length > 0 &&
            <>
              <TableScrollbar>{reportsTable()}</TableScrollbar>
            </>
          }
        </div>
      </div>
    </div>
  );
};
