import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createHashHistory } from "history";
import { version } from "../../utils/const";

const history = createHashHistory();

export default class Login extends React.Component {
  state = {
    email: "",
    password: "",
    // displayLogin: true,
    error: "",
    // loading: false,
    // maintenance: false,
    maintenanceMode: false,
    // data: false,
    togglePassword: false,
    selected: "firebase",
    password: "",
    screen: 768,
  };

  componentDidMount = () => {
    this.fetchMaintenanceMode();
    // console.log(this.props);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    this.setState({ screen: window.innerWidth });
  };

  fetchMaintenanceMode = () => {
    var URL = `${version.expURL}/api/maintenance_mode`;
    // console.log(URL);
    axios
      .get(URL)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === "success") {
          this.setState({
            maintenanceMode: res.data.exp_data[0].mode,
            load: false,
          });
        } else {
          console.log("something wrong with " + URL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  setSelected = (name) => {
    this.setState({
      selected: name,
    });
  };

  HideShowPassword = () => {
    this.setState({
      togglePassword: !this.state.togglePassword,
    });
  };

  handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;

    if (this.state.email === "" && this.state.password === "") {
      this.setState({
        error: "email and password are required",
      });
    } else if (this.state.password === "") {
      this.setState({
        error: "password is required",
      });
    } else if (this.state.email === "") {
      this.setState({
        error: "email is required",
      });
    } else if (this.state.password === "") {
      this.setState({
        error: "password is required",
      });
    } else if (!this.validateEmail(this.state.email)) {
      this.setState({
        error: "Invalid email",
      });
    } else {
      try {
        await this.funcLogin(email, password);
      } catch (error) {
        try {
          await this.funcLogin(email, password);
        } catch (error) {
          this.setState({
            error: "Authentication failed." + error,
          });
        }
      }
    }
  };

  funcLogin = (email, password) => {
    // console.log("fx funcLogin");
    const URL = `${version.expURL}/api/auth/sign-in`;
    // console.log(URL);

    if (email === "" || password === "") {
      alert("Email or password can't be empty");
    } else {
      let final_body = {
        email: email,
        password: password,
      };

      fetch(URL, {
        body: JSON.stringify(final_body),
        headers: { "Content-Type": "application/json" },
        method: "POST",
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          // console.log(res);
          this.setSession(res);
        });
    }
  };

  setSession = (req) => {
    // console.log("fx setSession", req);
    try {
      localStorage.setItem("UID", req.UID);
      localStorage.setItem("token", req.token);
      localStorage.setItem("email", req.email);
      localStorage.setItem("role", req.role);
      if (req.token === null) {
        alert(req.message);
      } else {
        this.checkAuth();
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  checkAuth = () => {
    // console.log("fx checkAuth");
    var token_check = localStorage.getItem("token");
    // console.log(token_check);
    var URL = `${version.expURL}/api/auth/check-auth`;
    // console.log(URL);
    const given_token = "token=" + token_check;
    // console.log(given_token);
    fetch(URL, {
      body: given_token,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
    })
      .then((response) => {
        return response.json();
        // console.log(response);
      })
      .then((res) => {
        // console.log(res);
        if (res.condition === true) {
          toast.success("successfully logged in");
          // console.log("redirecting");
          window.location.href = "/";
        } else {
          toast.error("Error :" + res.message);
        }
        // console.log(res.condition);
      });
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <span
          style={{
            "font-size": "15px",
            "align-self": "center",
            color: "#e60000",
          }}
        >
          {this.state.error}
        </span>
      );
    }
  };

  redirectRegister = () => {
    // console.log("inside redirectRegister");
    history.push("/pages/auth/register");
  };

  renderMaintenance = () => {
    return (
      <div style={{ height: "100vh" }} className="d-flex">
        <div className="login-bg left"></div>
        <div className="d-flex flex-column align-items-stretch justify-content-center login-bg right pl-5 pr-5">
          <h2 className="text-left title mb-0" style={{ width: "100%" }}>
            Under maintenance
          </h2>
          <div className="align-self-start mb-4">
            Oops! Seems like you came at a time we are performing maintenance.
            Please come back again later. If you are interested in trying things
            out, have a look at the Launchpad :)
          </div>
          <Button
            color="outline"
            className="mr-3 btn-outline-danger"
            onClick={() => {
              history.push("/pages/auth/landing-page");
            }}
          >
            Go to Launchpad
          </Button>
        </div>
      </div>
    );
  };

  renderLogin = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "100%" }}
      >
        <div className="login-main login-tab" style={{ width: "65%" }}>
          <Form className="theme-form">
            <FormGroup>
              <Label className="col-form-label">Email</Label>
              <Input
                className="form-control"
                type="email"
                // required=""
                placeholder="Test@gmail.com"
                id="email"
                name="email"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="col-form-label">Password</Label>
              <Input
                className="form-control"
                type={this.state.togglePassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={this.handleChange}
                // onChange={(e) => this.handleChange(e)}
                // required=""
                placeholder="*********"
              />
            </FormGroup>

            <div className="d-flex align-items-center justify-content-between">
              <div
                onClick={() => this.HideShowPassword()}
                className={this.state.togglePassword ? "" : "show"}
              >
                {this.state.togglePassword ? "Hide" : "Show"}
              </div>
              <a href="#javascript">Forgot password?</a>
            </div>

            <Button
              color="primary"
              className="btn-block"
              style={{
                width: "fit-content",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              onClick={this.handleLogin}
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
    );
  };

  render() {
    const { load } = this.state;

    if (load === true)
      return (
        <div className="loader-box" style={{ height: "100vh" }}>
          <div className="loader-1" />
        </div>
      );

    return (
      <>
        <ToastContainer />
        <Container fluid={true} className="p-0">
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "100%",
              zIndex: "1",
            }}
            className="text-right p-3"
          >
            <Button
              color="outline"
              className="mr-3 btn-outline-primary"
              onClick={() => {
                history.push("/pages/auth/landing-page");
              }}
            >
              Go Back
            </Button>
            <Button color="primary" onClick={() => this.redirectRegister()}>
              New User
            </Button>
          </div>
          <Row>
            <Col
              md={5}
              style={{
                background: "#5F00FB",
                display: this.state.screen > 768 ? "block" : "none",
                minHeight: "100vh",
              }}
            >
              <div
                className="text-light d-flex flex-column justify-content-center"
                style={{ height: "100%", padding: "0 4.5rem" }}
              >
                <div
                  className="text-dark bg-light mb-4"
                  style={{
                    borderRadius: "15px",
                    paddingTop: "100%",
                    background: `url(${require("../../assets/images/auth-img.svg")}) no-repeat`,
                    backgroundSize: "cover",
                  }}
                />

                <h2 className="mb-4">
                  Our tech experts help your team adopt faster
                </h2>
                <p className="m-0">
                  Our highly experienced capabilities are embedded with your
                  organization and help ease the transition to go digital.
                </p>
              </div>
            </Col>
            <Col md={7} style={{ minHeight: "100vh" }} className="p-0">
              {this.state.maintenanceMode === true
                ? this.renderMaintenance()
                : this.renderLogin()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}