import React, { useState, useEffect,useRef } from 'react';
import { BarChart, CustomBarchart } from "../../../graph-display/Barchart";
import styles from "../../../../../styles/plexyz/components/pages/dashboard/device-demographic-vis.module.css";

export const DevDemographicVis = ({title}) => {

    const legendRows = [
        {label: "A", content:"3000 visitors", contentAlt:"> 1 hour"},
        {label: "B", content:"2000 visitors", contentAlt:"30 - 59 mins"},
        {label: "C", content:"1000 visitors", contentAlt:"< 30 mins"},
    ];

    return (
        <div className={styles.container}>
            <h5 className={styles.title}>
            {
                title
            }
            </h5>
            <div className={styles.visAndLegend}>
                <BarchartDisplay />
                <div className={styles.legend}>
                    {
                        legendRows.map((d, i)=>(
                            <div className={styles.legendRow} key={i}>
                                <div className={styles.legendLabel}>
                                {
                                    d.label
                                }
                                </div>
                                <div className={styles.legendContent}>
                                {
                                    `${title==="Number of visitors"?d.content:d.contentAlt}`
                                }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

const BarchartDisplay = () => {
    const graphRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isSorted, setSorted] = useState(false);
    
    useEffect(()=>{
        listenToResize();
        window.addEventListener("resize", listenToResize);

        return ()=>window.removeEventListener("resize", listenToResize);
    }, []);

    const listenToResize = () => {
        setWidth(graphRef!==null?(graphRef.current!==null?graphRef.current.offsetWidth:0):0);
        setHeight(graphRef!==null?(graphRef.current!==null?graphRef.current.offsetHeight:0):0);
    }

    return (
        <div className={styles.vis} style={{position:"relative"}} ref={graphRef}>
            {
                width > 0 && height > 0 &&
                <CustomBarchart width={width} height={height} graphData={barchartData[0].dataSet} isSorted={isSorted} />
            }
        </div>
    )
}

export const barchartData = [
    {
        title:"Frequensi huruf dalam Bahasa Inggris",
        dataSet:[
            {label: "A", value: 2900},
            {label: "B", value: 3200},
            {label: "C", value: 2000},
        ]
    }
]