import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./DeviceCount.module.css";
import connectMQTT from "../../utils/mqttService";
import companyLogo from "../../assets/images/LeaveaNest_logo_header.png";
import HereMap from "./HereMap";

const TIME_WINDOW = 60000; // 1 minute in milliseconds
const ALLOWED_PLEXYZ_IDS = ["PLEXYZ0040", "PLEXYZ0041", "PLEXYZ0042"];

const DEVICE_LOCATIONS = {
  PLEXYZ0040: { coords: [2.9231, 101.6559], name: "Cyberjaya, Malaysia" },
  PLEXYZ0041: { coords: [35.6762, 139.6503], name: "Tokyo, Japan" },
  PLEXYZ0042: { coords: [1.4491, 103.8184], name: "Singapore" },
};

const ZOOM_LEVELS = {
  PLEXYZ0040: 10,
  PLEXYZ0041: 11,
  PLEXYZ0042: 7,
};

const DeviceCount = () => {
  const [deviceData, setDeviceData] = useState(() =>
    ALLOWED_PLEXYZ_IDS.reduce(
      (acc, id) => ({
        ...acc,
        [id]: { count: 0, devices: [], lastUpdate: null },
      }),
      {}
    )
  );
  const [clientMQTT, setClientMQTT] = useState(null);

  const updateDeviceData = useCallback((data) => {
    const { plexyz_id, mac_address, timestamp } = data;
    const now = Date.now();
    const oneMinuteAgo = now - TIME_WINDOW;

    if (ALLOWED_PLEXYZ_IDS.includes(plexyz_id) && timestamp * 1000 > oneMinuteAgo) {
      setDeviceData((prevData) => {
        const plexyzData = prevData[plexyz_id] || { count: 0, devices: [], lastUpdate: null };
        const updatedDevices = plexyzData.devices.filter(
          (device) => device.timestamp > oneMinuteAgo
        );
        const newDevice = { mac_address, timestamp: timestamp * 1000 };
        const deviceExists = updatedDevices.some((device) => device.mac_address === mac_address);

        if (!deviceExists) {
          updatedDevices.push(newDevice);
        }

        return {
          ...prevData,
          [plexyz_id]: {
            count: updatedDevices.length,
            lastUpdate: now,
            devices: updatedDevices,
          },
        };
      });
    }
  }, []);

  useEffect(() => {
    const setupMQTT = async () => {
      const client = await connectMQTT((message) => {
        try {
          const data = typeof message === "object" ? message : JSON.parse(message);
          if (data && data.plexyz_id && data.mac_address && data.timestamp) {
            updateDeviceData(data);
          } else {
            console.error("Invalid MQTT message format:", data);
          }
        } catch (error) {
          console.error("Error processing MQTT message:", error, message);
        }
      });
      setClientMQTT(client);
    };

    setupMQTT();

    return () => {
      if (clientMQTT) {
        clientMQTT.end();
      }
    };
  }, [updateDeviceData]);

  const isPlexyzIdActive = useCallback((plexyzData) => {
    if (!plexyzData || plexyzData.count === 0) return false;
    const now = Date.now();
    return plexyzData.lastUpdate && now - plexyzData.lastUpdate < TIME_WINDOW;
  }, []);

  const analytics = useMemo(() => {
    const totalDevices = ALLOWED_PLEXYZ_IDS.length;
    const onlineDevices = ALLOWED_PLEXYZ_IDS.filter((id) =>
      isPlexyzIdActive(deviceData[id])
    ).length;
    const offlineDevices = totalDevices - onlineDevices;
    const totalCount = Object.values(deviceData).reduce((sum, data) => sum + data.count, 0);

    return { totalDevices, onlineDevices, offlineDevices, totalCount };
  }, [deviceData, isPlexyzIdActive]);

  const renderAnalyticsCard = (title, value, color) => (
    <div className={`${styles.analyticsCard} ${styles[color]}`}>
      <h3>{title}</h3>
      <p>{value}</p>
    </div>
  );

  const renderCard = (plexyzId) => {
    const plexyzData = deviceData[plexyzId];
    const { coords, name } = DEVICE_LOCATIONS[plexyzId];
    const zoomLevel = ZOOM_LEVELS[plexyzId];
    const isActive = isPlexyzIdActive(plexyzData);

    return (
      <div key={plexyzId} className={styles.card}>
        <div className={styles.cardHeader}>
          <span className={styles.locationName}>{name}</span>
          <span className={styles.plexyzId}>{plexyzId}</span>
        </div>
        <div className={styles.mapSection}>
          {/* <Map
            center={coords}
            zoom={zoomLevel}
            style={{ height: "150px", width: "100%" }}
            zoomControl={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={coords}>
              <Popup>{name}</Popup>
            </Marker>
          </Map> */}
          <HereMap coords={coords} zoomLevel={zoomLevel} name={name} />
        </div>
        <div className={styles.infoSection}>
          <div className={styles.infoHeader}>
            <span>Device Count</span>
            <span>Last Update</span>
            <span>Status</span>
          </div>
          <div className={styles.infoContent}>
            <span>{plexyzData.count}</span>
            <span>
              {plexyzData.lastUpdate ? new Date(plexyzData.lastUpdate).toLocaleTimeString() : "-"}
            </span>
            <span className={`${styles.status} ${isActive ? styles.active : ""}`}>
              {plexyzData.count > 0 ? (isActive ? "Active" : "Offline") : "No Data"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={companyLogo} alt="Company Logo" className={styles.logo} />
      </div>
      <div className={styles.analyticsWrapper}>
        {renderAnalyticsCard("Total Devices", analytics.totalDevices, "blue")}
        {renderAnalyticsCard("Online Devices", analytics.onlineDevices, "green")}
        {renderAnalyticsCard("Offline Devices", analytics.offlineDevices, "red")}
        {renderAnalyticsCard("Total Count", analytics.totalCount, "purple")}
      </div>
      <div className={styles.cardsWrapper}>
        {ALLOWED_PLEXYZ_IDS.map((plexyzId) => renderCard(plexyzId))}
      </div>
    </div>
  );
};

export default DeviceCount;
