// import React,{useState} from 'react';
// import {Container,Row,Col,Form,FormGroup,Input,Label,Button} from 'reactstrap'
// import {Password,SignIn, EmailAddress ,CreateAccount, YourName, PrivacyPolicy} from '../../constant';
// import { Twitter, Facebook,GitHub } from 'react-feather';

// const Register = (props) => {

//     const [togglePassword,setTogglePassword] = useState(false)
//     const [password,setPassword] = useState("")

//     const handleChange = (e) => {
//       setPassword(e.target.value)
//     }
//     const HideShowPassword  = (tPassword) => {
//       setTogglePassword(!tPassword)
//     }

//     return (
//       <Container fluid={true} className="p-0">
//       <Row>
//         <Col xs="12">
//           <div className="login-card">
//             <div>
//               <div><a className="logo" href="#javascript"><img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="looginpage"/><img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="looginpage"/></a></div>
//               <div className="login-main">
//                 <Form className="theme-form">
//                   <h4>{"Create your account"}</h4>
//                   <p>{"Enter your personal details to create account"}</p>
//                   <FormGroup>
//                     <Label className="col-form-label pt-0">{YourName}</Label>
//                     <div className="form-row">
//                       <Col xs="6">
//                         <Input className="form-control" type="text" required="" placeholder="First name"/>
//                       </Col>
//                       <Col xs="6">
//                         <Input className="form-control" type="text" required="" placeholder="Last name"/>
//                       </Col>
//                     </div>
//                   </FormGroup>
//                   <FormGroup>
//                     <Label className="col-form-label">{EmailAddress}</Label>
//                     <Input className="form-control" type="email" required="" placeholder="Test@gmail.com"/>
//                   </FormGroup>
//                   <FormGroup>
//                     <Label className="col-form-label">{Password}</Label>
//                     <Input className="form-control" type={togglePassword ?  "text" : "password" } name="login[password]" value={password} onChange={(e) => handleChange(e)} required="" placeholder="*********"/>
//                     <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
//                   </FormGroup>
//                   <div className="form-group mb-0">
//                     <div className="checkbox ml-3">
//                       <Input id="checkbox1" type="checkbox"/>
//                       <Label className="text-muted" for="checkbox1">{"Agree with"}<a className="ml-2" href="#javascript">{PrivacyPolicy}</a></Label>
//                     </div>
//                     <Button color="primary" className="btn-block" type="submit">{CreateAccount}</Button>
//                   </div>
//                   <h6 className="text-muted mt-4 or">{"Or signup with"}</h6>
//                   <div className="social mt-4">
//                     <div className="btn-showcase">
//                       <Button color="light">
//                         <Facebook className="txt-fb" />
//                       </Button>
//                       <Button color="light">
//                         <i className="icon-social-google txt-googleplus"></i>
//                       </Button>
//                       <Button color="light">
//                         <Twitter className="txt-twitter" />
//                       </Button>
//                       <Button color="light">
//                         <GitHub />
//                       </Button>
//                     </div>
//                   </div>
//                   <p className="mt-4 mb-0">{"Already have an account?"}<a className="ml-2" href="#javascript">{SignIn}</a></p>
//                 </Form>
//               </div>
//             </div>
//           </div>
//         </Col>
//       </Row>
//       </Container>
//     );
// }

// export default Register;

import React from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  Label,
} from "reactstrap";
import { Password, EmailAddress, PrivacyPolicy } from "../../constant";
import axios from "axios";

// import LOGINBG from "../../assets/images/login-bg.png";
import { createHashHistory } from "history";
import { version } from "../../utils/const";
const history = createHashHistory();
export default class Register extends React.Component {
  state = {
    fullname: "",
    userRole: "",
    email: "",
    officeNo: "",
    mobileNo: "",
    password: "",
    retypePassword: "",
    companyName: "",
    error: "",
    value: "select",

    user: [],
    isCompanyInputShow: true,
    passwordStrength: 0,
    maintenanceMode: false,
    togglePassword: false,
    screen: 768,
    load: true,
  };

  componentDidMount = () => {
    this.fetchMaintenanceMode();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    this.setState({ screen: window.innerWidth });
  };

  redirectLogin = () => {
    // console.log("inside redirectRegister");
    history.push("/pages/auth/login");
  };

  fetchMaintenanceMode = () => {
    var URL = `${version.expURL}/api/maintenance_mode`;
    // console.log(URL);
    axios
      .get(URL)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === "success") {
          this.setState({
            maintenanceMode: res.data.exp_data[0].mode,
            load: false,
          });
        } else {
          console.log("something wrong with " + URL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //TRACK CHANGES ON INPUT
  handleInputChange = (e) => {
    const target = e.target;
    let name = target.name;
    let value = target.value;

    this.setState({
      [name]: value,
    });
    // this.handlePasswordChanges();
  };

  handlePasswordChanges = () => {
    let password = this.state.password;
    let strengths = 0;

    if (password.length > 5) {
      strengths++;
    }
    if (password.length > 7) {
      strengths++;
    }

    let hasNumber = new RegExp(/[0-9]/).test(password);
    let hasSymbol = new RegExp(/[!#@$%^&*)(+=._-]/).test(password);
    let hasMixLetters =
      new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password);

    if (hasNumber) {
      strengths++;
    }

    if (hasSymbol) {
      strengths++;
    }

    if (hasMixLetters) {
      strengths++;
    }

    this.setState({
      passwordStrength: strengths,
    });
  };

  passwordIndicator = () => {
    let strengths = this.state.passwordStrength;

    if (this.state.password === "") {
      return;
    }

    if (strengths < 2) {
      return (
        <span className="text-danger font-weight-700">weak({strengths})</span>
      );
    }

    if (strengths < 4) {
      return (
        <span className="text-warning font-weight-700">
          medium({strengths})
        </span>
      );
    }

    if (strengths < 6) {
      return (
        <span className="text-success font-weight-700">
          strong({strengths})
        </span>
      );
    }
  };

  //SUBMIT BUTTON
  // 1. Error handlers to check if email and password entered is valid
  // 2. Run registerUser to MongoDB
  // 3. Run registerGrafana
  // 4. Run registerGrafana2
  createNewUser = (e) => {
    let email = this.state.email;
    let password = this.state.password;
    let retypePassword = this.state.retypePassword;
    let role = this.state.role;

    this.setState({ error: "" });

    if (email === "") {
      this.setState({
        error: "Email is required",
      });
      return;
    } else if (password === "") {
      this.setState({
        error: "Password is required",
      });
      return;
    } else if (retypePassword === "") {
      this.setState({
        error: "Please retype your password",
      });
      return;
    } else if (password !== retypePassword) {
      this.setState({
        error: "Password does not match",
      });
      return;
    } else if (!this.validateEmail(email)) {
      this.setState({
        error: "Invalid email",
      });
      return;
    } else {
      try {
        // console.log("createNewUser ok");
        this.registerUser(email, password, role);
        // this.setState({
        //   passwordStrength: "",
        // });
      } catch (err) {
        console.log(err);
      }
    }
  };

  // 2. Run registerUser to MongoDB
  registerUser = (email, pwd, role) => {
    var URL = `${version.expURL}/api/auth/sign-up`;
    let body = {
      email: email,
      password: pwd,
      role: role,
    };
    // console.log(URL, body);
    fetch(URL, {
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        alert(res.message);
        this.setState({
          register: res.register,
        });
        // console.log(this.state.register);

        if (this.state.register === true) {
          // console.log("User registered");
          history.push("/pages/auth/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <span
          style={{
            "font-size": "15px",
            "align-self": "center",
            color: "#e60000",
          }}
        >
          {this.state.error}
        </span>
      );
    }
  };

  renderMaintenance = () => {
    return (
      <div style={{ height: "100vh" }} className="d-flex">
        <div className="login-bg left"></div>
        <div className="d-flex flex-column align-items-stretch justify-content-center login-bg right pl-5 pr-5">
          <h2 className="text-left title mb-0" style={{ width: "100%" }}>
            Under maintenance
          </h2>
          <div className="align-self-start mb-4">
            Oops! Seems like you came at a time we are performing maintenance.
            Please come back again later. If you are interested in trying things
            out, have a look at the Launchpad :)
          </div>
          <Button
            color="outline"
            className="mr-3 btn-outline-danger"
            onClick={() => {
              history.push("/pages/auth/landing-page");
            }}
          >
            Go to Launchpad
          </Button>
        </div>
      </div>
    );
  };

  renderRegister = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "100%" }}
      >
        {/* <div>
          <a className="logo" href="#javascript">
            <img
              className="img-fluid for-light"
              style={{ width: "140px" }}
              src={require("../../assets/images/horizontal_logo_orange.png")}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              style={{ width: "140px" }}
              src={require("../../assets/images/horizontal_logo_orange.png")}
              alt=""
            />
          </a>
        </div> */}
        <div>
          <h2>Register Now</h2>
        </div>
        <div className="login-main login-tab" style={{ width: "65%" }}>
          <Form className="theme-form">
            <FormGroup>
              <Label className="col-form-label">Email</Label>
              <Input
                className="form-control"
                type="email"
                // required=""
                placeholder="Test@gmail.com"
                id="email"
                name="email"
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="col-form-label">Password</Label>
              <Input
                className="form-control"
                type={this.state.togglePassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={this.handleInputChange}
                // onChange={(e) => this.handleChange(e)}
                // required=""
                placeholder="*********"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="col-form-label">Repeat Password</Label>
              <Input
                className="form-control"
                type={this.state.togglePassword ? "text" : "password"}
                id="retypePassword"
                name="retypePassword"
                onChange={this.handleInputChange}
                // onChange={(e) => this.handleChange(e)}
                // required=""
                placeholder="*********"
              />
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Role</Label>
              <Input
                className="form-control"
                type="select"
                // required=""
                // placeholder="Test@gmail.com"
                id="role"
                name="role"
                onChange={this.handleInputChange}
              >
                <option>Select role</option>
                <option value="user" name="user">
                  user
                </option>
                <option value="admin" name="admin">
                  admin
                </option>
              </Input>
            </FormGroup>
            <div className="text-muted font-italic">
              <small>password strength: {this.passwordIndicator()}</small>
            </div>
            {this.renderError()}

            <div className="d-flex align-items-center justify-content-between">
              <div
                onClick={() => this.HideShowPassword()}
                className={this.state.togglePassword ? "" : "show"}
              >
                {this.state.togglePassword ? "Hide" : "Show"}
              </div>
            </div>

            <Button
              color="primary"
              className="btn-block"
              style={{
                width: "fit-content",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              onClick={this.createNewUser}
            >
              Create Account
            </Button>
          </Form>
        </div>
        {/* <img
          className="img-fluid for-light"
          style={{ position: "absolute", bottom: "0" }}
          src={LOGINBG}
          alt=""
        /> */}
      </div>
    );
  };

  renderCubaRegister = () => {
    return (
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="#javascript">
                    <img
                      className="img-fluid for-light"
                      src={require("../../assets/images/logo/login.png")}
                      alt="looginpage"
                    />
                    <img
                      className="img-fluid for-dark"
                      src={require("../../assets/images/logo/logo_dark.png")}
                      alt="looginpage"
                    />
                  </a>
                </div>
                <div className="login-main">
                  <Form className="theme-form">
                    <h4>{"Create your account"}</h4>
                    <p>{"Enter your personal details to create account"}</p>

                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        placeholder="Test@gmail.com"
                        type="email"
                        name="email"
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{Password}</Label>
                      <Input
                        className="form-control"
                        type={this.state.togglePassword ? "text" : "password"}
                        name="password"
                        onChange={this.handleInputChange}
                        placeholder="*********"
                      />
                      <div
                        className="show-hide"
                        onClick={() => this.HideShowPassword()}
                      >
                        <span
                          className={this.state.togglePassword ? "" : "show"}
                        ></span>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="retype-password"
                      >
                        Repeat Password
                      </label>
                      <Input
                        className="form-control-alternative"
                        name="retypePassword"
                        placeholder="*********"
                        type={this.state.togglePassword ? "text" : "password"}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <div className="form-group mb-0">
                      <div className="checkbox ml-3">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">
                          {"Agree with"}
                          <a className="ml-2" href="#javascript">
                            {PrivacyPolicy}
                          </a>
                        </Label>
                      </div>
                      <div className="text-muted font-italic">
                        <small>
                          password strength: {this.passwordIndicator()}
                        </small>
                      </div>
                      {this.renderError()}
                      <Button
                        color="primary"
                        className="btn-block"
                        // type="submit"
                        onClick={this.createNewUser}
                      >
                        Create account
                      </Button>
                    </div>
                    <p className="mt-4 mb-0">
                      {"Already have an account?"}
                      <Button onClick={() => this.redirectSignIn()}>
                        Sign In
                      </Button>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  redirectSignIn = () => {
    // console.log("inside redirectSignIn");
    history.push("/pages/auth/login");
  };

  HideShowPassword = () => {
    this.setState({
      togglePassword: !this.state.togglePassword,
    });
  };

  createInterest = () => {
    // Insert function here
    let email = this.state.email;
    let notes = this.state.note;
    if (email === "") {
      this.setState({
        error: "Email is required",
      });
    } else if (!this.validateEmail(email)) {
      this.setState({
        error: "Invalid email",
      });
    } else {
      try {
        // this.registerInterest(email, notes);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // registerInterest = (email, notes) => {
  //   // Insert function here

  //   var URL = `${version.expURL}/api/register_interest`;

  //   console.log(URL);

  //   let body = {
  //     email: email,
  //     notes: notes,
  //   };
  //   console.log(body);

  //   fetch(URL, {
  //     body: JSON.stringify(body),
  //     headers: { "Content-Type": "application/json" },
  //     method: "POST",
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((res) => {
  //       alert(res.message);
  //       this.setState({
  //         registerInterest: res.register,
  //         error: "thank you",
  //       });
  //       console.log(this.state.registerInterest);

  //       if (this.state.registerInterest === true) {
  //         console.log("Interest registered");
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // <>
  //   {/* {"maintenanceMode is " + this.state.maintenanceMode} */}
  //   {this.state.maintenanceMode === true
  //     ? this.renderMaintenance()
  //     : this.renderCubaRegister()}
  // </>
  render() {
    const { load } = this.state;

    if (load === true)
      return (
        <div className="loader-box" style={{ height: "100vh" }}>
          <div className="loader-1" />
        </div>
      );

    return (
      <Container fluid={true} className="p-0">
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "100%",
            zIndex: "1",
          }}
          className="text-right p-3"
        >
          <Button
            color="outline"
            className="mr-3 btn-outline-primary"
            onClick={() => {
              history.push("/pages/auth/landing-page");
            }}
          >
            Go Back
          </Button>
          <Button color="primary" onClick={() => this.redirectLogin()}>
            Login
          </Button>
        </div>
        <Row>
          <Col
            md={5}
            style={{
              background: "#5F00FB",
              display: this.state.screen > 768 ? "block" : "none",
              minHeight: "100vh",
            }}
          >
            <div
              className="text-light d-flex flex-column justify-content-center"
              style={{ height: "100%", padding: "0 4.5rem" }}
            >
              <div
                className="text-dark bg-light mb-4"
                style={{
                  borderRadius: "15px",
                  paddingTop: "100%",
                  background: `url(${require("../../assets/images/auth-img.svg")}) no-repeat`,
                  backgroundSize: "cover",
                }}
              />

              <h2 className="mb-4">
                Our tech experts help your team adopt faster
              </h2>
              <p className="m-0">
                Our highly experienced capabilities are embedded with your
                organization and help ease the transition to go digital.
              </p>
            </div>
          </Col>
          <Col md={7} style={{ minHeight: "100vh" }} className="p-0">
            {this.state.maintenanceMode === true
              ? this.renderMaintenance()
              : this.renderRegister()}
          </Col>
        </Row>
      </Container>
    );
  }
}
