import React, {useState, useEffect} from "react";
import {useD3} from "../../../hooks/used3";
import * as d3 from "d3";

export const LineChart = ({width, height,  graphData, line}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPos, setTooltipPos] = useState([{left:0, top:0}, {left:0, top:0}]);

    const [line1Tip,setLine1Tip] = useState(null);
    const [line2Tip,setLine2Tip] = useState(null);  

    const margin = { top: 0, right: 0, bottom: 22, left: 18 };
  
    const graphWidth = width - margin.left - margin.right;
    const graphHeight = height - margin.top - margin.bottom;

    const parseTime = d3.timeParse("%m/%d/%Y"),
                formatDate = d3.timeFormat("%d-%b"),
                    bisectDate = d3.bisector(function(d) { return d.month; }).left;
    /*
                    graphData.forEach(graphD=>{
                        graphD.forEach(function(d) {
                            d.month = parseTime(d.month);
                            d.value = +d.value;
                        });
                    }); */
    // set the ranges
    const x = d3.scaleTime().range([0, graphWidth]);
    const y = d3.scaleLinear().range([graphHeight, 0]);
            
    const ref = useD3(
        (svg) => {  
            
            // define the line
            const valueline = d3.line()
                .x(function(d) { return x(d.month); })
                .y(function(d) { return y(d.value); })
                .curve(d3.curveMonotoneX);

            const area = d3.area()
                .x(function(d) { return x(d.month); })
                .y0(y(0))
                .y1(function(d) { return y(d.value); })
                .curve(d3.curveMonotoneX);

            graphData.forEach(graphD=>{
                graphD.forEach(function(d) {                   
                    d.month = typeof d.month === "string"?parseTime(d.month):d.month;
                    d.value = +d.value;
                });
            });       
            //console.log(graphData);
                // Scale the range of the data
            x.domain(d3.extent(graphData[1], function(d) { return d.month; }));

            const data1Max = d3.max(graphData[0].map(d=>d.value));
            const data2Max = d3.max(graphData[1].map(d=>d.value));
            const yMax = Math.max(data1Max, data2Max)
            y.domain([0, yMax + 15]);

            const plotArea = svg.select(".plot-area");

            plotArea.select(".area1")
                .datum(graphData[0])
                .attr("className", "area")
                .attr("d", area)
                .style("fill", "url(#svgGradient)")
                .style("stroke", "none")
                .style("stroke-width", 0);
            
            // Add the valueline path.
            plotArea.select(".line1")
                .data([graphData[0]])
                .attr("d", valueline)
                .style("fill", "none")
                .style("stroke", "#ffa910")
                .style("stroke-width", 3.5138);

            plotArea.select(".area2")
                .datum(graphData[1])
                .attr("className", "area")
                .attr("d", area)
                .style("fill", "url(#svgGradient1)")
                .style("stroke", "none")
                .style("stroke-width", 0);
            
            // Add the valueline path.
            plotArea.select(".line2")
                .data([graphData[1]])
                .attr("d", valueline)
                .style("fill", "none")
                .style("stroke", "#8349ff")
                .style("stroke-width", 3.5138);
            
            // Add the X Axis
            const xAxis = svg.select(".x-axis")
                    .attr("transform", "translate(0," + (margin.top + graphHeight) + ")")
                    .call(
                        d3.axisBottom(x)
                            .tickSizeOuter(0)
                            .tickSizeInner(0)
                        );

            xAxis.selectAll("path")
                        .style("stroke-width", "2px")
                        .style("stroke", "#aa86e5");

            xAxis.selectAll("text")
                    .style("font-family", "Poppins")
                    .style("font-weight", 400)
                    .style("font-size","14px")
                    .style("fill", "#ffffff")
                    .attr("dy", "16px")
            
            const focus = svg.select(".focus").style("display", "none");         
                                
            const line1x = focus.select(".line1-x-line");
            const line1y = focus.select(".line1-y-line");
            const line1Dot = focus.select(".line1-dot");
            const line2x = focus.select(".line2-x-line");
            const line2y = focus.select(".line2-y-line");
            const line2Dot = focus.select(".line2-dot");

            svg.select(".hover-receptor")
                .on("mouseover", 
                    function() { 
                        focus.style("display", null); 
                        setShowTooltip(true);
                    })
                .on("mouseout", 
                    function() { 
                        focus.style("display", "none"); 
                        setShowTooltip(false);
                    })
                .on("mousemove", mousemove);
            
            function mousemove(event) {              
                    const x0 = x.invert(d3.pointer(event,this)[0]),
                    i = bisectDate(graphData[0], x0, 1),
                    d0 = graphData[0][i - 1],
                    d1 = graphData[0][i];
                    let d;
                    if(x0 && i && d0 && d1){
                        d = x0 - d0.month > d1.month - x0 ? d1 : d0;
                    }
                    
                    const i1 = bisectDate(graphData[1], x0, 1),
                    d01 = graphData[1][i1 - 1],
                    d11 = graphData[1][i1];
                    let dt1;
                    if(i1 && d01 && d11){
                        dt1 = x0 - d01.month > d11.month - x0 ? d11 : d01;
                    }
                    
                    setLine1Tip(d);
                    setLine2Tip(dt1);

                    const canDisplayTooltip = x0 && i && d0 && d1 && i1 && d01 && d11 && d && dt1;
                    
                    if(canDisplayTooltip){
                        setTooltipPos([
                            {left:x(d.month), top:y(d.value)},
                            {left:x(dt1.month), top:y(dt1.value)}
                        ]);
                        line1Dot
                            .attr("transform",
                                `translate(${d?x(d.month):0}, ${y(d.value)?y(d.value):0})`);

                        line2Dot
                            .attr("transform",
                                `translate(${dt1?x(dt1.month):0}, ${y(dt1.value)?y(dt1.value):0})`);

                        const displayDt = d;// line==="yellow"?d:dt1;                    

                        line1x
                            .attr("transform",
                                `translate(${d?.month?x(d.month):0},
                                                ${d?.value?y(d.value):0})`)
                            .attr("y2", `${d?.value?(graphHeight - y(d.value)):0}`);

                        line2x
                            .attr("transform",
                                `translate(${dt1?.month?x(dt1.month):0},
                                                ${dt1?.value?y(dt1.value):0})`)
                            .attr("y2", `${dt1?.value?(graphHeight - y(dt1.value)):0}`);
                
                        line1y
                            .attr("transform",
                                `translate(${graphWidth * -1},
                                                ${d?.value?y(d.value):0})`)
                            .attr("x2", `${graphWidth + graphWidth}`);

                        line2y
                            .attr("transform",
                                `translate(${graphWidth * -1},
                                                ${dt1?.value?y(dt1.value):0})`)
                            .attr("x2", `${graphWidth + graphWidth}`);
                    }
              
          }
          
        },
        [graphData, width, height]
    );

    const tooltip = () => {    
        const tooltip1Left = tooltipPos[0].left;
        const tip1Left = width - tooltip1Left < 120 ? tooltip1Left - 130:tooltip1Left + 10;
        const tooltip2Left = tooltipPos[1].left;
        const tip2Left = width - tooltip2Left < 120 ? tooltip2Left - 130:tooltip2Left + 10;   
        

        const line1Month = new Date(line1Tip?.month);
        const line1Time = line1Month.getMonth() + "/" + line1Month.getDate() + "/" + line1Month.getFullYear();
        const line1Value = line1Tip?.value;    
        
        const line2Month = new Date(line2Tip?.month);
        const line2Time = line2Month.getMonth() + "/" + line2Month.getDate() + "/" + line2Month.getFullYear();
        const line2Value = line2Tip?.value;      
        
        return (
            <>
            <div 
                style={{
                    position:"absolute", 
                    left: tip1Left,
                    top: tooltipPos[0].top,
                    backgroundColor:"#ffa910",
                    width:"120px", height:"auto",
                    inlineSize: "120px",
                    overflowWrap: "break-word",
                    pointerEvents: "none",
                    paddingLeft:"10px",
                    paddingRight:"10px",
                    paddingTop:"10px",
                    paddingBottom:" 12px",
                    borderRadius:"5px"
                }}
            >
                <p style={{lineHeight:"14px", padding:"0px", margin:"0px"}}>
                {
                    line1Time + " : " + line1Value
                }
                </p>                          
            </div>
            <div 
                style={{
                    position:"absolute", 
                    left: tip2Left,
                    top: tooltipPos[1].top - 40,
                    backgroundColor:"#8349ff",
                    width:"120px", height:"auto",
                    inlineSize: "120px",
                    overflowWrap: "break-word",
                    pointerEvents: "none",
                    paddingLeft:"10px",
                    paddingRight:"10px",
                    paddingTop:"10px",
                    paddingBottom:" 12px",
                    borderRadius:"5px"
                }}
            >
                <p style={{lineHeight:"14px", padding:"0px", margin:"0px"}}>
                    {
                        line2Time + " : " + line2Value
                    }
                </p>
            </div> 
            </> 
        )
    }   

    return (
        <div style={{width, height, overflow:"hidden", position:"relative"}}>
            <svg
                ref={ref}
                style={{
                    height: "100%",
                    width: "100%",
                    margin: "0px"
                }}
            >
                <linearGradient id="svgGradient" x1="0%" x2="100%" y0="0%" y1="100%">
                    <stop offset="0%" stopColor="rgba(255, 169, 16, 0.3)" stopOpacity={1} />
                    <stop offset="40%" stopColor="rgba(255, 169, 16, 0.1)" stopOpacity={1} />
                    <stop offset="100%" stopColor="rgba(255, 169, 16, 0)" stopOpacity={1} />
                </linearGradient>
                <linearGradient id="svgGradient1" x1="0%" x2="100%" y0="0%" y1="100%">
                    <stop offset="0%" stopColor="rgba(131, 73, 255, 0.3)" stopOpacity={1} />
                    <stop offset="40%" stopColor="rgba(131, 73, 255, 0.1)" stopOpacity={1} />
                    <stop offset="100%" stopColor="rgba(131, 73, 255, 0)" stopOpacity={1} />
                </linearGradient>   
                <g className="plot-area">
                    <path className="area1" />
                    <path className="line1" />
                    <path className="area1" />
                    <path className="line2" />
                </g>
                <g className="focus">
                    <line 
                        className="line1-x-line"
                        stroke="#ffa910"
                        strokeDasharray="3, 3"
                        opacity={0.5}
                        y1={0}
                        y2={graphHeight}
                    />
                    <line 
                        className="line1-y-line"
                        stroke="#ffa910"
                        strokeDasharray="3, 3"
                        opacity={0.5}
                        x1={graphWidth}
                        x2={graphWidth}
                    />
                    <line 
                        className="line2-x-line"
                        stroke="#8349ff"
                        strokeDasharray="3, 3"
                        opacity={0.5}
                        y1={0}
                        y2={graphHeight}
                    />
                    <line 
                        className="line2-y-line"
                        stroke="#8349ff"
                        strokeDasharray="3, 3"
                        opacity={0.5}
                        x1={graphWidth}
                        x2={graphWidth}
                    />
                    <circle className="line1-dot" fill="none" stroke="#ffa910" strokeWidth={2.5} r={5} />
                    <circle className="line2-dot" fill="none" stroke="#8349ff" strokeWidth={2.5} r={5} />
                </g>
                <g className="x-axis" />
                <g className="y-axis" />
                <rect 
                    className="hover-receptor" 
                    width={graphWidth} 
                    height={graphHeight} 
                    fill="none" 
                    style={{pointerEvents:"all"}} 
                />
            </svg>
            {
                showTooltip && tooltip()
            }
        </div>
    );
}

export const LineChartAlt = ({width, height,  graphData }) => {
    
    
    
    const ref = useD3(
        (svg) => {
          

            
      
          // append the svg obgect to the body of the page
          // appends a "group" element to "svg"
          // moves the "group" element to the top left margin
      
          // Adding a separate group to "layer" the objects
          const lineSvg = svg.append("g");
      
          const focus = svg.append("g") 
              .style("display", "none");
      
              //console.log(graphData[0]);
              // format the data
          
          
        
              // Scale the range of the data
          

          
        
          // Add the X Axis
          const xAxis = svg.select(".x-axis")
                .attr("transform", "translate(0," + (margin.top + graphHeight) + ")")
                .call(
                    d3.axisBottom(x)
                        .tickSizeOuter(0)
                        .tickSizeInner(0)
                    );

          xAxis.selectAll("path")
                    .style("stroke-width", "2px")
                    .style("stroke", "#aa86e5");

          xAxis.selectAll("text")
                .style("font-family", "Poppins")
                .style("font-weight", 400)
                .style("font-size","14px")
                .style("fill", "#ffffff")
                .attr("dy", "16px")
        
          // append the x line
          focus.append("line")
              .attr("class", "x")
              .style("stroke", "#ffa910")
              .style("stroke-dasharray", "3,3")
              .style("opacity", 0.5)
              .attr("y1", 0)
              .attr("y2", graphHeight);

          focus.append("line")
              .attr("class", "x-1")
              .style("stroke", "#8349ff")
              .style("stroke-dasharray", "3,3")
              .style("opacity", 0.5)
              .attr("y1", 0)
              .attr("y2", graphHeight);
        
              // append the y line
          focus.append("line")
              .attr("class", "y")
              .style("stroke", "#ffa910")
              .style("stroke-dasharray", "3,3")
              .style("opacity", 0.5)
              .attr("x1", graphWidth)
              .attr("x2", graphWidth);

          focus.append("line")
              .attr("class", "y-1")
              .style("stroke", "#8349ff")
              .style("stroke-dasharray", "3,3")
              .style("opacity", 0.5)
              .attr("x1", graphWidth)
              .attr("x2", graphWidth);
        
          // append the circle at the intersection 
          focus.append("circle")
              .attr("class", "y")
              .style("fill", "none")
              .style("stroke", "#ffa910")
              .style("stroke-width", 2.5)
              .attr("r", 5);

          focus.append("circle")
              .attr("class", "y-1")
              .style("fill", "none")
              .style("stroke", "#8349ff")
              .style("stroke-width", 2.5)
              .attr("r", 5);
        
          // place the value at the intersection
          focus.append("text")
              .attr("class", "y1")
              .style("stroke", "white")
              .style("stroke-width", "3.5px")
              .style("opacity", 0.8)
              .attr("dx", 8)
              .attr("dy", "-.3em");
          
          focus.append("text")
              .attr("class", "y2")
              .attr("dx", 8)
              .attr("dy", "-.3em");
        
          // place the month at the intersection
          focus.append("text")
              .attr("class", "y3")
              .style("stroke", "white")
              .style("stroke-width", "3.5px")
              .style("opacity", 0.8)
              .attr("dx", 8)
              .attr("dy", "1em");
                
          focus.append("text")
              .attr("class", "y4")
              .attr("dx", 8)
              .attr("dy", "1em");
              
          // append the rectangle to capture mouse
          svg.append("rect")
              .attr("width", width)
              .attr("height", height)
              .style("fill", "none")
              .style("pointer-events", "all")
              .on("mouseover", function() { focus.style("display", null); })
              .on("mouseout", function() { focus.style("display", "none"); })
              .on("mousemove", mousemove);
        
          function mousemove(event) {              
                const x0 = x.invert(d3.pointer(event,this)[0]),
                  i = bisectDate(graphData[0], x0, 1),
                  d0 = graphData[0][i - 1],
                  d1 = graphData[0][i];
                let d;
                if(x0 && i && d0 && d1){
                    d = x0 - d0.month > d1.month - x0 ? d1 : d0;
                }
                  
                const i1 = bisectDate(graphData[1], x0, 1),
                  d01 = graphData[1][i1 - 1],
                  d11 = graphData[1][i1];
                let dt1;
                if(i1 && d01 && d11){
                    dt1 = x0 - d01.month > d11.month - x0 ? d11 : d01;
                }
                  
                const canDisplayTooltip = x0 && i && d0 && d1 && i1 && d01 && d11 && d && dt1;
                
                if(canDisplayTooltip){
                    focus.select("circle.y")
                        .attr("transform",
                            `translate(${d?x(d.month):0}, ${y(d.value)?y(d.value):0})`);

                    focus.select("circle.y-1")
                        .attr("transform",
                            `translate(${dt1?x(dt1.month):0}, ${y(dt1.value)?y(dt1.value):0})`);

                    const displayDt = d;// line==="yellow"?d:dt1;
                    focus.select("text.y1")
                        .attr("transform",
                            `translate(${displayDt?x(displayDt.month):0},
                                        ${displayDt?y(displayDt.value):0})`)
                        .text(`${displayDt?.value?displayDt.value:""}`);
            
                    focus.select("text.y2")
                        .attr("transform",
                            `translate(${displayDt?x(displayDt.month):0},
                                        ${displayDt?y(displayDt.value):0})`)
                        .text(`${displayDt?displayDt.value:""}`);
            
                    focus.select("text.y3")
                        .attr("transform",
                            `translate(${displayDt?x(displayDt.month):0},
                                            ${displayDt?y(displayDt.value):0})`)
                        .text(`${displayDt?formatDate(displayDt.month):""}`);
            
                    focus.select("text.y4")
                        .attr("transform",
                            `translate(${displayDt?x(displayDt.month):0},
                                            ${displayDt?y(displayDt.value):0})`)
                        .text(`${displayDt?formatDate(displayDt.month):""}`);

                    focus.select(".x")
                        .attr("transform",
                            `translate(${d?.month?x(d.month):0},
                                            ${d?.value?y(d.value):0})`)
                        .attr("y2", `${d?.value?(graphHeight - y(d.value)):0}`);

                    focus.select(".x-1")
                        .attr("transform",
                            `translate(${dt1?.month?x(dt1.month):0},
                                            ${dt1?.value?y(dt1.value):0})`)
                        .attr("y2", `${dt1?.value?(graphHeight - y(dt1.value)):0}`);
            
                    focus.select(".y")
                        .attr("transform",
                            `translate(${graphWidth * -1},
                                            ${d?.value?y(d.value):0})`)
                        .attr("x2", `${graphWidth + graphWidth}`);

                    focus.select(".y-1")
                        .attr("transform",
                            `translate(${graphWidth * -1},
                                            ${dt1?.value?y(dt1.value):0})`)
                        .attr("x2", `${graphWidth + graphWidth}`);
                }
              
          }
          
        },
        [graphData, width, height]
      );

    const margin = { top: 0, right: 0, bottom: 22, left: 18 };
  
    const graphWidth = width - margin.left - margin.right;
    const graphHeight = height - margin.top - margin.bottom;  
    
    // parse the month / time
    const parseTime = d3.timeParse("%m/%d/%Y"),
                formatDate = d3.timeFormat("%d-%b"),
                    bisectDate = d3.bisector(function(d) { return d.month; }).left;        
        
    // set the ranges
    const x = d3.scaleTime().range([0, graphWidth]);
    const y = d3.scaleLinear().range([graphHeight, 0]);
  
    graphData.forEach(graphD=>{
        graphD.forEach(function(d) {
            d.month = parseTime(d.month);
            d.value = +d.value;
        });
    });

    x.domain(d3.extent(graphData[1], function(d) { return d.month; }));

    const data1Max = d3.max(graphData[0].map(d=>d.value));
    const data2Max = d3.max(graphData[1].map(d=>d.value));
    const yMax = Math.max(data1Max, data2Max)
    y.domain([0, yMax + 15]);
        
    // define the line
    const valueline = d3.line()
                .x(function(d) { return x(d.month); })
                .y(function(d) { return y(d.value); })
                .curve(d3.curveMonotoneX);
  
    const area = d3.area()
                .x(function(d) { return x(d.month); })
                .y0(y(0))
                .y1(function(d) { return y(d.value); })
                .curve(d3.curveMonotoneX);

  return (
    <div style={{width:"auto", height:"auto", overflow:"hidden", position:"relative"}}>
        <svg
            style={{
                height: height,
                width: width,
                margin: "0px"
            }}
        >
            <linearGradient id="svgGradient" x1="0%" x2="100%" y0="0%" y1="100%">
                <stop offset="0%" stopColor="rgba(255, 169, 16, 0.3)" stopOpacity={1} />
                <stop offset="40%" stopColor="rgba(255, 169, 16, 0.1)" stopOpacity={1} />
                <stop offset="100%" stopColor="rgba(255, 169, 16, 0)" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="svgGradient1" x1="0%" x2="100%" y0="0%" y1="100%">
                <stop offset="0%" stopColor="rgba(131, 73, 255, 0.3)" stopOpacity={1} />
                <stop offset="40%" stopColor="rgba(131, 73, 255, 0.1)" stopOpacity={1} />
                <stop offset="100%" stopColor="rgba(131, 73, 255, 0)" stopOpacity={1} />
            </linearGradient>
            <g className="plot-area">
                <path 
                    datum={graphData[0]}
                    className="area"
                    d={area}
                    fill="url(#svgGradient)"
                    stroke="none"
                    strokeWidth={0}
                />
                <path 
                    data={graphData[0]}
                    className="area"
                    d={valueline}
                    fill="none"
                    stroke="#ffa910"
                    strokeWidth={3.5138}
                />
                <rect width={100} height={200} fill="red" />
            </g>
            <g className="x-axis" />
            <g className="y-axis" />
        </svg>
    </div>
  );
}

/*
svg.append("path")
              .datum(graphData[0])
              .attr("className", "area")
              .attr("d", area)
              .style("fill", "url(#svgGradient)")
              .style("stroke", "none")
              .style("stroke-width", 0);
        
          // Add the valueline path.
          lineSvg.append("path")
              .data([graphData[0]])
              .attr("d", valueline)
              .style("fill", "none")
              .style("stroke", "#ffa910")
              .style("stroke-width", 3.5138);

          svg.append("path")
              .datum(graphData[1])
              .attr("className", "area")
              .attr("d", area)
              .style("fill", "url(#svgGradient1)")
              .style("stroke", "none")
              .style("stroke-width", 0);
        
          // Add the valueline path.
          lineSvg.append("path")
              .data([graphData[1]])
              .attr("d", valueline)
              .style("fill", "none")
              .style("stroke", "#8349ff")
              .style("stroke-width", 3.5138);
            */