import React from "react";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import styles from "../../../../styles/plexyz/components/pages/dashboard/total-avg-time.module.css";

export const TotalAvgTime = ({ avgTime, avgTimeUnit }) => {
    const { width } = useWindowDimensions();
    const isMobile = width < 600;

    const decorBackgroundMobile = () => {
        return (
            <img 
                className={styles.backgroundImage}
                src = {process.env.PUBLIC_URL + "/images/mobile/totalAvgTimeDecor.svg"} 
                alt="Decor"/>
        );
    }

    const decorBackground = () => {
        return (
            <div className={styles.backgroundContainer}>
                <img 
                    className={styles.backgroundImage}
                    src = {process.env.PUBLIC_URL + 
                        `${isMobile?"/images/mobile/totalAvgTimeDecor.svg":
                            "/images/totalAvgTime.svg"}`} 
                    alt="Decor"/>
            </div>
        );
    }

    return (
        <div className={styles.container}>            
            <h4 className={styles.title}>
                Total Average Time Spent
            </h4>
            <div className={styles.time}>
                {
                    decorBackground()
                }
                <h5 className={styles.timeText}>{ isNaN(avgTime)?0:avgTime }</h5>
                <p className={styles.timeUnit}>{ avgTimeUnit }</p>
            </div>

        </div>
    )

}