import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { Modal } from "../../global/Modal";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import styles from "../../../../styles/plexyz/components/pages/dashboard/top-toolbar.module.css";
import PAUSE from "../../../../assets/images/pause.svg";
import PLAY from "../../../../assets/images/play.svg";

export const TopToolbar = ({
  fetchType,
  setFetchType,
  setHistoryTime,
  plexyzID,
  stopWebsocket,
  setStopWebsocket,
}) => {
  const [newStart, setStart] = useState(null);
  const [newEnd, setEnd] = useState(null);
  const [showDialog, setShow] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  const cancelHistorySelect = () => {
    setShow(false);
  };

  const selectHistory = () => {
    if (newStart !== null && newEnd !== null) {
      setHistoryTime({ startTime: newStart, endTime: newEnd });
      setShow(false);
      setFetchType("Historical");
    }
  };

  const changeData = () => {
    if (fetchType === "Historical") {
      setFetchType("Realtime");
    } else if (fetchType === "Realtime") {
      setStopWebsocket(!stopWebsocket);
    }
  };

  return (
    <>
      <div className={styles.topToolbar}>
        {width > 1024 && <h1 className={styles.topTitle}>{plexyzID ? plexyzID : ""}</h1>}
        <div className={styles.allButtons}>
          <div className={styles.buttons}>
            <div
              className={`${styles.button} ${fetchType === "Realtime" && styles.active}`}
              onClick={changeData}
            >
              <div
                className={styles.pauseButton}
                style={{
                  backgroundImage: `url(${stopWebsocket ? PLAY : PAUSE})`,
                }}
              />
              Realtime
            </div>
            {/* Comment out on 24 July 2023, for AeroDarat preparation */}
            {/* <div
              className={`${styles.button} ${
                fetchType === "Historical" && styles.active
              }`}
              onClick={() => {
                setFetchType("Historical");
                // setFetchType(d);
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let now = new Date();
                if (!newStart) {
                  setStart(yesterday);
                }
                if (!newEnd) {
                  setEnd(now);
                }
                if (!showDialog) {
                  setShow(true);
                }
              }}
            >
              Historical
            </div> */}
          </div>
        </div>
      </div>
      {/* {showDialog && (
        <Modal>
          <div className={styles.dateTimeDialog}>
            <h3 className={styles.dateTimeTitle}>Set Historical Range</h3>
            <div className={styles.dateTimeSelect}>
              <h5 className={styles.timeSelectLabel}>Start Date</h5>
              <DateTimePicker
                className={styles.dateTimeStyle}
                disableClock
                onChange={(e) => {
                  setStart(e);
                }}
                value={newStart}
              />
            </div>
            <div className={styles.dateTimeSelect}>
              <h5 className={styles.timeSelectLabel}>End Date</h5>
              <DateTimePicker
                className={styles.dateTimeStyle}
                disableClock
                onChange={(e) => {
                  setEnd(e);
                }}
                value={newEnd}
              />
            </div>
            <div className={styles.dateTimeSelect}>
              <div
                onClick={selectHistory}
                className={`${styles.dateTimeButton} ${styles.dateTimeOk}`}
              >
                Ok
              </div>
              <div
                onClick={cancelHistorySelect}
                className={`${styles.dateTimeButton} ${styles.dateTimeCancel}`}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </>
  );
};
