import React from 'react';
import * as d3 from 'd3';

export const useD3 = (renderChartFn, dependencies) => {
    const ref = React.useRef();
    const deps = Array.isArray(dependencies)?dependencies:[];

    const some = React.useEffect(() => {
        if(dependencies !== null){
          renderChartFn(d3.select(ref.current));
        }
        // console.log(dependencies)
        return () => {};
      }, [ref, ...deps]);
    return ref;
}