import React from "react";
import DeviceCount from "../../../../components/dashboard/DeviceCount";

const DeviceCountPage = () => {
  return (
    <div>
      <h1>Device Count</h1>
      <DeviceCount />
    </div>
  );
};

export default DeviceCountPage;
