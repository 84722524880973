import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { Modal } from "../../global/Modal";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import styles from "../../../../styles/plexyz/components/pages/dashboard/bottom-toolbar.module.css";

export const BottomToolbar = () => {
  return (
    <>
      <div>
        <div className={styles.disclaimer}>
          <p className={styles.disclaimerText}>
            Disclaimer: Data capture will continue seamlessly, even during connectivity issues,
            ensuring accurate device counts in your report.
          </p>
          <p className={styles.disclaimerText}>
            Disclaimer: The accuracy of device counts displayed on the dashboard relies on internet
            connectivity. However, data capture will continue seamlessly during connectivity issues,
            ensuring accurate foot traffic counts are reflected in your comprehensive reports.
          </p>
        </div>
      </div>
    </>
  );
};
