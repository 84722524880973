import React, { useState, useRef } from 'react';
import TableScrollbar from 'react-table-scrollbar';
import { Select, SelectOptions } from "../../global/Select";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";
import { ChevronDownTable } from "../../../../svg-shapes/ChevronDownTable";
import { UpgradeLock } from "../../../../svg-shapes/UpgradeLock";
import { dotColor } from '../../global/functions';
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useTablePagination } from '../../../../hooks/useTablePagination';
import styles from "../../../../styles/plexyz/components/pages/dashboard/device-list.module.css";
import lockStyles from "../../../../styles/plexyz/components/pages/dashboard/upgrade-lock.module.css";

export const DeviceList = ({data, tier}) => {
    const [sortBy, setSortBy] = useState("Status");
    const [sortType, setSortType] = useState("desc");
    /*const [page, setPage] = useState(1);
    
    const minItemIdx = (page - 1) * pageSize;
    const maxItemIdx = page * pageSize - 1;*/
    const totalItem = data.length;
    /*const maxPage = Math.ceil(totalItem / pageSize);

    const prevPage = () => {
        if(page > 1){
            setPage(page - 1);
        }
    }

    const nextPage = () => {
        if(page < maxPage){
            setPage(page + 1);
        }
    }*/

    const { width } = useWindowDimensions();
    const isMobile = width <= 600;

    const distanceRef = useRef(null);
    const visitingRef = useRef(null);
    const speedRef = useRef(null);
    const locationRef = useRef(null);

    const { width: distanceColWidth} = useContainerDimensions(distanceRef, [data]);
    const { width: visitingColWidth} = useContainerDimensions(visitingRef,[data]);
    const { width: speedColWidth} = useContainerDimensions(speedRef, [data]);
    const { width: locationColWidth} = useContainerDimensions(locationRef, [data]);

    const toggleSortType = () => {
        setSortType(sortType === "asc"?"desc":"asc");
    }

    const statusOrder = ["80-95%", "50-79%", "<=49%"];

    const devicesData = () => {   
        let sortedDevices = data.slice();     

        if(sortBy === "Type"){
            sortedDevices.sort((a, b)=>{
                if(a.name < b.name){
                    return sortType === "asc"? 1:-1;
                }
                else if(a.name > b.name){
                    return sortType === "asc"? -1:1;
                }
                return 0;
            })
        }
        else if(sortBy === "Status"){            
            sortedDevices.sort((a, b)=>{                
                if(statusOrder.indexOf(a.status) < statusOrder.indexOf(b.status)){
                    return sortType === "asc"? 1:-1;
                }
                else if(statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status)){
                    return sortType === "asc"? -1:1;
                }
                return 0;
            })
        }
        else if(sortBy === "Distance"){            
            sortedDevices.sort((a, b)=>{                
                if( sortType === "asc"){
                    return a.distance - b.distance;
                }
                else if( sortType === "desc"){
                    return b.distance - a.distance;
                }
                return 0;
            });
        }
        else if(sortBy === "Visiting Time"){            
            sortedDevices.sort((a, b)=>{     
                const durationA = a.duration ? a.duration : 0;
                const durationB = b.duration ? b.duration : 0;           
                if( sortType === "asc"){
                    return durationA - durationB;
                }
                else if( sortType === "desc"){
                    return durationB - durationA;
                }
                return 0;
            });
        }
        else if(sortBy === "Speed"){            
            sortedDevices.sort((a, b)=>{     
                const speedA = a.duration ? a.distance/a.duration : 0;
                const speedB = b.duration ? b.distance/b.duration : 0;           
                if( sortType === "asc"){
                    return speedA - speedB;
                }
                else if( sortType === "desc"){
                    return speedB - speedA;
                }
                return 0;
            });
        }
        else{
            return sortedDevices;
        }

        return sortedDevices;
    }    
    
    //const tableData = !isMobile?devicesData():devicesData().filter((d, i) => i >= minItemIdx && i <= maxItemIdx);
    const pageSize = 10;
    const { page, maxPage, prevPage, nextPage, tableData } = useTablePagination(devicesData(), pageSize);
    
    const upgraded = (d) => {
        return (
            <>
                <td><p style={{textAlign:"center"}}>{d.distance + "m"}</p></td>
                <td>{ d.duration ? d.duration.toFixed(2) + " " + "s" : "Not available" }</td>
                <td>{ d.duration ? ((d.distance/d.duration).toFixed(4) + " " + "m/s ") : "Not available" }</td>
                <td>{ `${d.x}, ${d.y}`}</td>
            </>
        )
    }

    const deviceTable = () => {
        return (
            <table className={styles.devTable}>
                <thead>
                    <tr className={styles.tableHeader}>
                        <th>
                        {
                            sortBy === "Type" ?
                            <SortableColumn
                                sortBy={sortBy}
                                sortType={sortType}
                                toggleSortType={toggleSortType}
                            />
                            :
                            "Type"
                        }
                        </th>
                        <th style={{minWidth:"66px"}}>
                        {
                            sortBy === "Status" ?                                
                            <SortableColumn
                                sortBy={sortBy}
                                sortType={sortType}
                                toggleSortType={toggleSortType}
                            />
                            :
                            "Status"
                        }
                        </th>
                        <th ref={distanceRef}>
                        {
                            sortBy === "Distance" ?                                
                            <SortableColumn
                                sortBy={sortBy}
                                sortType={sortType}
                                toggleSortType={toggleSortType}
                            />
                            :
                            "Distance (m)"
                        }
                        </th>
                        <th ref={visitingRef}>
                        {
                            sortBy === "Visiting Time" ?                                
                            <SortableColumn
                                sortBy={sortBy}
                                sortType={sortType}
                                toggleSortType={toggleSortType}
                            />
                            :
                            "Visiting Time"
                        }
                        </th>
                        <th ref={speedRef}>
                        {
                            sortBy === "Speed" ?                                
                            <SortableColumn
                                sortBy={sortBy}
                                sortType={sortType}
                                toggleSortType={toggleSortType}
                            />
                            :
                            "Speed"
                        }
                        </th>
                        <th ref={locationRef}>
                            Location
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData.map((d, i)=>(
                            <tr key={i} className={styles.dataRow}>
                                <td>{ d.name === "None"?"Unknown":d.name }</td>    
                                <td style={{minWidth:"66px"}}>
                                    <div 
                                        style={{
                                                display:"flex",
                                                flexDirection:"row", 
                                                justifyContent:"flex-start", 
                                                alignItems:"stretch",
                                                minWidth:"66px"
                                            }}
                                    >
                                        <div 
                                            className={styles.deviceDot} 
                                            style={{
                                                backgroundColor:dotColor(d.status),
                                                marginRight: "8px"
                                            }}
                                        />    
                                        {
                                            d.status
                                        }
                                    </div>
                                </td>
                                {
                                    tier>1 && upgraded(d)
                                }
                                
                            </tr>
                        ))
                    }                    
                </tbody>   
            </table>
        )
    }

    const SortBys = [ "Type", "Status", "Distance", "Visiting Time", "Speed"];//, "Location"

    const sortOptions = tier < 2 ? SortBys.filter((d, i)=>i < 2):SortBys;    
    
    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <h4 className={styles.title}>
                    Devices List
                    <div 
                        className={`${styles.chevronContainer} ${sortType === "asc" && styles.sortAsc}`} 
                        onClick={()=>{setSortType(sortType==="asc"?"desc":"asc");}}
                    >
                        <ChevronDown />
                    </div>
                </h4>  
                <div className={styles.selectContainer}>
                    <Select
                        options={sortOptions}
                        value={sortBy}
                        changeValue={(newSortBy)=>{setSortBy(newSortBy);}}   
                    />
                </div>
            </div>
            <div className={styles.tableContainer}>
                {
                    tableData.length > 0 && 
                    <>
                    <TableScrollbar>
                    {
                        deviceTable()
                    }
                    </TableScrollbar>
                    {
                        tier === 1 &&
                        <div 
                            className={styles.upgradeLock }
                            style={{width:`${distanceColWidth + visitingColWidth + speedColWidth + locationColWidth}px`}}
                        >
                            <Upgrade />
                        </div>
                    } 
                    </>
                }           
                               
            </div>
            <MobileTablePagination
                itemCount={tableData.length}
                totalItem={totalItem}
                page={page}
                maxPage={maxPage}
                prevPage={prevPage}
                nextPage={nextPage}
            />
        </div>
    );
}

export const MobileTablePagination = ({itemCount, totalItem, page, maxPage, prevPage, nextPage}) => {
    return (
        <>
            <span className={styles.topBorder} />
            <div className={styles.pageControl}>
                    <div className={styles.pageInfo}>
                    {
                        `${itemCount} out of ${totalItem} device${totalItem > 1 && "s"}`
                    }                
                    </div>
                    <div className={styles.pageButtons}>
                        <div 
                            className={styles.pageButton}
                            onClick={prevPage}
                        >
                            <img       
                                style={{opacity:`${page===1?0.3:1}`}}                  
                                src = { process.env.PUBLIC_URL + `/images/prev.svg`} 
                                alt = { "prev" }
                            />
                        </div>
                        <div 
                            style={{opacity:`${page===maxPage?0.3:1}`}}
                            className={styles.pageButton}
                            onClick={nextPage}
                        >
                            <img                         
                                src = { process.env.PUBLIC_URL + `/images/next.svg`} 
                                alt = { "next" }
                            />
                        </div>
                    </div>
                </div>
            </>
    )
}

const SortableColumn = ({sortType, sortBy, toggleSortType}) => {
    //onClick={()=>{console.log('sort clicked');toggleSortType();}}
    return (
        <span className={styles.sortableColumn}>
            {sortBy}
            <span 
                className={`${styles.chevron} ${sortType==="asc" && styles.sortAscending}`}
                
            >
                <ChevronDownTable />
            </span>
        </span>
    )
}

export const Upgrade = () => {
    return (
        <div className={lockStyles.container}>
            <div className={lockStyles.lockLogo}>
                <UpgradeLock />
            </div>
            <p className={lockStyles.label} >
                Upgrade your plan to see more analytics
            </p>
        </div>
    )
}