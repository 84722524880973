import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./index.css";
import { firebase_app, auth0 } from "./data/config";
import { HashRouter, BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./store";
import App from "./components/app";
import { Layout } from "./pages/plexyz/Layout";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes, adminRoutes } from "./route/index";

import ConfigDB from "./data/customizer/config";
import { configureFakeBackend, authHeader, handleResponse } from "./services/fack.backend";
import PrivateRoute from "./PrivateRoute.js";

// Signin page
import Signin from "./auth/signin";

// Authentication
import Login from "./pages/authentication/login";
import { PlexyzLogin } from "./pages/plexyz/login";
import LoginWithBgImage from "./pages/authentication/loginWithBgImage";
import LoginWithBgVideo from "./pages/authentication/loginWithBgVideo";
import LoginWithValidation from "./pages/authentication/loginwithValidation";
import Register from "./pages/authentication/register";
import { PlexyzRegister } from "./pages/plexyz/register";
import RegisterWithBgImage from "./pages/authentication/registerWithBgImage";
import RegisterWithBgVideo from "./pages/authentication/registerWithBgVideo";
import UnlockUser from "./pages/authentication/unlockUser";
import Forgetpwd from "./pages/authentication/forgetpwd";
import Resetpwd from "./pages/authentication/resetpwd";

// Error page
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Comming soo
import Comingsoon from "./pages/comingSoon/comingsoon";
import ComingsoonImg from "./pages/comingSoon/comingsoonImg";
import ComingsoonVideo from "./pages/comingSoon/comingsoonVideo";

// Maintenanc
import Maintenance from "./pages/maintenance";

import Callback from "./auth/callback";
import { classes } from "./data/layouts";
import axios from "axios";
import { createHashHistory } from "history";
import { version } from "./utils/const";

const history = createHashHistory();

// setup fake backend
// configureFakeBackend();

export default class Root extends React.Component {
  state = {
    authenticated: false,
    layout: "Dubai",
    anim: "",
    role: "user",
  };

  componentDidMount() {
    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");
      this.setState({ role: role });
      // console.log('role_user', role);

      const URL = `${version.expURL}/api/auth/check-auth`;
      const given_token = "token=" + token;
      // console.log(URL, given_token);

      axios
        .post(URL, given_token)
        .then((res) => {
          // console.log(res);
          if (res.data.condition) {
            // console.log(res.data.condition);
            this.setState(
              {
                authenticated: true,
                loading: false,
              },
              () => {
                history.push("/");
              }
            );
          } else {
            this.setState({
              authenticated: false,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <Fragment>
        {/* <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}> */}
        <Provider store={store}>
          <HashRouter basename={`/`}>
            <Switch>
              {/* <Route  path={`/login`} component={Signin} /> */}
              <Route path={`/pages/auth/login`} component={PlexyzLogin}></Route>
              <Route path={`/pages/auth/register`} component={PlexyzRegister}></Route>

              <Route path={`/pages/auth/loginWithBgImg1`} component={LoginWithBgImage}></Route>
              <Route path={`/pages/auth/loginWithBgImg2`} component={LoginWithBgVideo}></Route>
              <Route
                path={`/pages/auth/loginWithValidation`}
                component={LoginWithValidation}
              ></Route>
              <Route path={`/pages/auth/signup`} component={Register}></Route>
              <Route path={`/pages/auth/signupWithImg1`} component={RegisterWithBgImage}></Route>
              <Route path={`/pages/auth/signupWithImg2`} component={RegisterWithBgVideo}></Route>
              <Route path={`/pages/auth/forgetPwd`} component={Forgetpwd}></Route>
              <Route path={`/pages/auth/unlockUser`} component={UnlockUser}></Route>
              <Route path={`/pages/auth/resetPwd`} component={Resetpwd}></Route>

              {/* <Route  path={`/
              /default/${layout}`} component={Maintenance}></Route> */}

              <Route path={`/pages/errors/error400`} component={Error400}></Route>
              <Route path={`/pages/errors/error401`} component={Error401}></Route>
              <Route path={`/pages/errors/error403`} component={Error403}></Route>
              <Route path={`/pages/errors/error404`} component={Error404}></Route>
              <Route path={`/pages/errors/error500`} component={Error500}></Route>
              <Route path={`/pages/errors/error503`} component={Error503}></Route>

              <Route path={`/pages/comingsoon/comingsoon`} component={Comingsoon}></Route>
              <Route path={`/pages/comingsoon/comingsoonImg`} component={ComingsoonImg}></Route>
              <Route path={`/pages/comingsoon/comingsoonVideo`} component={ComingsoonVideo}></Route>

              <Route path={`/pages/maintenance`} component={Maintenance}></Route>

              <Route path={`/callback`} render={() => <Callback />} />
              {/* <App>
                {console.log(authenticated)}
                <Route exact path={`/`} render={() => {
                    return (<Redirect to={`/dashboard/default/${layout}`} />)
                }} /> 
                <TransitionGroup>
                    {routes.map(({ path, Component }) => (
                      <Route key={path}  exact  path={`${path}`}>
                          {({ match }) => (
                              <CSSTransition 
                                in={match != null}
                                timeout={100}
                                classNames={anim} 
                                unmountOnExit>
                                <div><Component/></div>
                              </CSSTransition> 
                          )}
                      </Route>
                      ))}
                </TransitionGroup> 
              
              </App> 
                <Redirect
                          to={`/dashboard/default/${this.state.layout}`}
                        />
              */}
              {this.state.authenticated ? (
                <Layout>
                  <Route
                    exact
                    path={`/`}
                    render={() => {
                      return (
                        // ** Dkt sini utk redirect lps login ke page mana
                        // <Redirect to={`/dashboard/`} />
                        <Redirect to={`/overview/`} />
                      );
                    }}
                  />
                  <TransitionGroup>
                    {this.state.role === "Admin"
                      ? adminRoutes.map(({ path, Component }) => (
                          <Route key={path} exact path={`${path}`}>
                            {({ match }) => (
                              <CSSTransition
                                in={match != null}
                                timeout={100}
                                classNames={this.state.anim}
                                unmountOnExit
                              >
                                <div>
                                  <Component />
                                </div>
                              </CSSTransition>
                            )}
                          </Route>
                        ))
                      : routes.map(({ path, Component }) => (
                          <Route key={path} exact path={`${path}`}>
                            {({ match }) => (
                              <CSSTransition
                                in={match != null}
                                timeout={100}
                                classNames={this.state.anim}
                                unmountOnExit
                              >
                                <div>
                                  <Component />
                                </div>
                              </CSSTransition>
                            )}
                          </Route>
                        ))}
                  </TransitionGroup>
                </Layout>
              ) : (
                <Redirect to={`/pages/auth/login`} />
              )}
            </Switch>
          </HashRouter>
        </Provider>
      </Fragment>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
