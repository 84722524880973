import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AdminUnit } from "./adminUnit";
import { AdminSite } from "./adminSite";

import { version } from "../../../../utils/const";
import { Modal } from "../../global/Modal";
import { ChevronDown } from "../../../../svg-shapes/ChevronDown";

import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import styles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import selectStyles from "../../../../styles/plexyz/components/pages/devices/devices-select.module.css";
import { AdminUnitDevices } from "./adminUnitRefactor";

export const AdminDevices = () => {
  const [unitData, setUnitData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchAllUnit();
    fetchAllSite();
    fetchAllArea();
    fetchAllUser();

    //   return () => {
    //     second
    //   }
  }, []);

  const fetchAllUnit = () => {
    const URL = `${version.expURL}/api2/admin/all_unit_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      var LIST = res.data.data;
      setUnitData(LIST);
      // fetchList()
    });
  };
  const fetchAllSite = () => {
    const URL = `${version.expURL}/api2/admin/all_site_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      var LIST = res.data.data;
      // console.log('all_site',res.data.data );
      setSiteData(LIST);
      // fetchList()
    });
  };
  const fetchAllArea = () => {
    const URL = `${version.expURL}/api2/admin/all_area_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      var LIST = res.data.data;
      // console.log('all_area',res.data.data );
      setAreaData(LIST);
      // fetchList()
    });
  };

  const fetchAllUser = () => {
    const URL = `${version.expURL}/api2/admin/all_user_list`;
    var userID = localStorage.getItem("UID");

    var body = { user_uid: userID };

    axios.post(URL, body).then((res) => {
      var all = res.data.data;
      var LIST = [];
      for (let i = 0; i < all.length; i++) {
        if (all[i].user_uid !== undefined) {
          LIST.push(all[i]);
        }
      }
      setUserData(LIST);
      // fetchList()
    });
  };

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.topTitle}>
                Admin Devices
            </h1> */}
      {/* <AdminUnitDevices /> */}
      {/* // ** Below is the original code that's working and will be used for now and then */}
      <AdminUnit data={unitData} user={userData} refetchUnit={fetchAllUnit} />
      <AdminSite data={siteData} areaData={areaData} user={userData} />
    </div>
  );
};
