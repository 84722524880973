import React from "react";
import styles from "../../../styles/plexyz/components/global/pagination.module.css";

export const Pagination = ({itemCount, totalItem, page, maxPage, prevPage, nextPage, noBorder}) => {
    return (
        <>
            {
                !noBorder && <span className={styles.topBorder} />
            }            
            <div className={styles.pageControl}>
                <div className={styles.pageInfo}>
                {
                    `page ${page} : ${itemCount} out of ${totalItem} device${totalItem > 1?"s":""}`
                }                
                </div>
                <div className={styles.pageButtons}>
                    <div 
                        style={{opacity:`${page===1?0.3:1}`}}
                        className={styles.pageButton}
                        onClick={prevPage}
                    >
                        <img                                                     
                            src = { process.env.PUBLIC_URL + `/images/prev.svg`} 
                            alt = "prev" 
                        />
                    </div>
                    <div 
                        style={{opacity:`${(page === maxPage || totalItem === 0)?0.3:1}`}}
                        className={styles.pageButton}
                        onClick={nextPage}
                    >
                        <img                         
                            src = { process.env.PUBLIC_URL + `/images/next.svg`} 
                            alt = "next" 
                        />
                    </div>
                </div>
            </div>
        </>
    )
}