import React, { useState } from 'react';
import styles from "../../../../styles/plexyz/components/pages/dashboard/total-devices.module.css";

export const TotalDevices = ({data}) => {
    const [currentDevs, setCurrentDevs] = useState(100);
    const [maxCapacity, setMaxCapacity] = useState(190);

    return (
        <div className={styles.container}>
            <h4 className={styles.title}>
                Total Nearby Devices
            </h4>
            <div className={styles.infoCells}>
                <InfoCell infoTitle="current" infoContent={data} />
                <InfoCell infoTitle="max capacity" infoContent={maxCapacity} />
            </div>
            <div className={styles.barContainer}>
                <div className={styles.bar} style={{width:`${(data/maxCapacity * 241)}px`}} />
            </div>   
        </div>
    )
}

const InfoCell = ({infoTitle, infoContent}) => {
    return (
        <div className={styles.infoCell}>
            <h4 className={styles.infoTitle}>
                { infoTitle }
            </h4>    
            <p className={styles.infoContent}>
                { infoContent }    
            </p>
        </div>
    )
}