import React, { useState, useEffect,useRef } from 'react';
import { Select, SelectOptions } from "../../global/Select";
import { LineChart, LineChartAlt } from "../../graph-display/Linechart";
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import styles from "../../../../styles/plexyz/components/pages/dashboard/total-visitor-stats.module.css";

export const TotalVisitorStats = () => {
    const [currentOption, setOption] = useState("06M");
    const [line, setLine] = useState("yellow");
    const { width } =  useWindowDimensions();
    const isMobile = width < 600;

    const optionsRow = () => {
        return (
            <div className={styles.optionsRow}>
                <div className={styles.options}>
                {
                    ["01M", "06M", "01Y","All"].map((d, i) => (
                        <p 
                            onClick={()=>{setOption(d);}}
                            key={d} 
                            className={`${styles.option} ${d===currentOption && styles.active}`}
                        >
                        {d}
                        </p>
                    ))
                }
                </div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <h4 className={styles.title}>
                    Total Visitor Statistic
                </h4>
                <Select                       
                        options={["Current Month"]}
                        value={"Current Month"}
                        changeValue={()=>{}}   
                    />
            </div>
            { !isMobile && optionsRow() }
            <div className={styles.lineLegends}>
            {
                ["blue", "yellow"].map((d,i) => (
                    <div className={styles.lineLegend} key={i} onClick={()=>{setLine(d);}}>
                        <div className={`${styles.dot} ${d==="blue"?styles.blueDot:styles.yellowDot}`} />
                        <p className={`${styles.legendLabel} ${line === d && styles.legendLabelActive}`}>abcde</p>
                    </div>
                ))
            }
            </div>
            <LineChartDisplay line={line} />
            { isMobile && optionsRow() }
        </div>
    )
}

const LineChartDisplay = ({ line }) => {
    const graphRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isSorted, setSorted] = useState(false);
    
    useEffect(()=>{
        listenToResize();
        window.addEventListener("resize", listenToResize);

        return ()=>window.removeEventListener("resize", listenToResize);
    }, []);

    const listenToResize = () => {
        setWidth(graphRef!==null?(graphRef.current!==null?graphRef.current.offsetWidth:0):0);
        setHeight(graphRef!==null?(graphRef.current!==null?graphRef.current.offsetHeight:0):0);
    }
    
    return (
        <div className={styles.graphContainer} ref={graphRef}>
        {
            width > 0 && height > 0 && 
            <LineChart width={width} height={height} graphData={dataset2} line={line} />
        }
        </div>
    )
}

//<LineChart graphData={dataset2} width={width} height={height} isSorted={false} />

const dataset1 = [[
            [1,1], [12,20], [24,36],
            [32, 50], [40, 70], [50, 100],
            [55, 106], [65, 80], [73, 70],
            [78, 65], [83, 40], [89, 35],
            [100, 20]
],
[
    [1,20], [12,30], [24,58],
    [32,77], [40, 90], [50, 130],
    [55, 136], [65, 143], [73, 135],
    [78, 140], [83, 140], [89, 149],
    [100, 150]
]
];

const dataset2 = [[
    {month: "01/02/2022" , value: 20},
    {month: "02/02/2022" , value: 35},
    {month: "03/02/2022", value: 56},
    {month: "04/02/2022", value: 30},
    {month: "06/02/2022", value: 23} 
], [
    {month: "01/02/2022" , value: 35},
    {month: "02/02/2022" , value: 47},
    {month: "03/02/2022", value: 68},
    {month: "04/02/2022", value: 65},
    {month: "05/02/2022", value: 45},
    {month: "06/02/2022", value: 33}
]]

const barchartData = [
    {
        title:"Frequensi huruf dalam Bahasa Inggris",
        dataSet:[
            {label: "A", value: 2900},
            {label: "B", value: 3200},
            {label: "C", value: 2000},
            {label: "D", value: 4253},
            {label: "E", value: 12702},
            {label: "F", value: 2288},
            {label: "G", value: 2015},
            {label: "H", value: 6094},
            {label: "I", value: 6966},
            {label: "J", value: 153},
            {label: "K", value: 772},
            {label: "L", value: 4025},
            {label: "M", value: 2406},
            {label: "N", value: 6749},
            {label: "O", value: 7507},
            {label: "P", value: 1929},
            {label: "Q", value: 95},
            {label: "R", value: 5987},
            {label: "S", value: 6327},
            {label: "T", value: 9056},
            {label: "U", value: 2758},
            {label: "V", value: 978},
            {label: "W", value: 2360},
            {label: "X", value: 150},
            {label: "Y", value: 1974},
            {label: "Z", value: 74}
        ]
    }
]