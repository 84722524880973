import React, { useState, useEffect } from 'react';
import axios from "axios";
import { version } from "../../utils/const";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../styles/plexyz/login.module.css";
import { LoginLogo } from "../../svg-shapes/LoginLogo";
import { LoginPicture } from "../../svg-shapes/LoginPicture";
import { ShowHidePassword } from "../../svg-shapes/ShowHidePassword";

import { createHashHistory } from "history";
const history = createHashHistory();

export const PlexyzLogin = () => {
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ error, setError ] = useState("");
    const [ maintenaceMode, setMaintainceMode ] = useState(false);
    const [ togglePassword, setTogglePassword ] = useState(false);
    //const [ selected, setSelected ] = useState("firebase");
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        fetchMaintenanceMode();
    }, []);

    const fetchMaintenanceMode = () => {
        const URL = `${version.expURL}/api/maintenance_mode`;
        //console.log(URL);
        axios
            .get(URL)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === "success") {
                    setMaintainceMode(res.data.exp_data[0].mode);
                } else {
                    console.log("something wrong with " + URL);
                }
            })
            .catch((err) => {
                    console.log(err);
            })
            .finally(()=>{
                setLoading(false);
            });
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        //const { email, password } = this.state;
    
        if (username === "" && password === "") {
            setError("email and password are required");
        } else if ( password === "") {
            setError("Password required");
        } else if ( username === "") {
            setError("Username required");
        } /*else if (!this.validateEmail(this.state.email)) {
          this.setState({
            error: "Invalid email",
          });
        }*/ 
        else {
            try {
                await funcLogin(username, password);
            } catch (err) {
                console.log('error', err);
                /*try {
                    await funcLogin(username, password);
                } catch (error) {
                    setError("Authentication failed. " + error);
                }*/
                // setError("Authentication failed. " + err);
                toast.error("Authentication failed. " + err)
            }
        }
    };

    const funcLogin = (username, password) => {
        // console.log("fx funcLogin");
        const URL = `${version.expURL}/api/auth/sign-in`;
        // console.log(URL);
    
        if ( username === "" || password === "") {
            alert("Username or password can't be empty");
        } else {
            let final_body = {
                email: username,
                password: password,
            };
        
            fetch(URL, {
                body: JSON.stringify(final_body),
                headers: { "Content-Type": "application/json" },
                method: "POST",
            })
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    if(res.accessToken === null){
                        toast.error("Authentication failed :  " + res.message)
                    }
                    
                    // console.log(res);
                    const { subscription, role } = res;
                    if(subscription === "free" || subscription === "paid"){
                        setSession(res);
                        //console.log("login as user");                                               
                    }else{
                        if(role.toLowerCase() === "admin"){
                            setSession(res);                            
                            //console.log("login as admin");
                        }
                        else {
                            console.log('subscription is not "free" or "paid"', subscription);
                            console.log('role is not "admin" : ', role);
                            setError("Contact the Admin to upgrade Subscription");                            
                        }
                    }                       
                })
                .catch(err=>{
                    toast.error("Authentication failed. " + err)
                    console.log(err);
                });
        }
    };

    const setTier = (res,username) => {
        // console.log('ioio', res);

        if(res.tier === undefined){
            if(username.startsWith("user1")){
                localStorage.setItem("tier", 1);
            }else if(username.startsWith("user2")){
                localStorage.setItem("tier", 2);
            }else if(username.startsWith("user3")){
                localStorage.setItem("tier", 3);
            }else if(!username.startsWith("user")) {
                localStorage.setItem("tier", 3);
            }
        } else {
            var TIER = res.tier 
            TIER =  parseInt(TIER.charAt(TIER. length - 1))
            localStorage.setItem("tier", TIER);
        }

        if(res.username === undefined){
            localStorage.setItem("username", "Ahmad");
        } else {
            localStorage.setItem("username", res.username);
        }
        
    }

    const fakeLogin = () => {
        localStorage.setItem("UID", "123456");
        localStorage.setItem("token", "tokentoken");
        localStorage.setItem("email", "my@email.net");
        localStorage.setItem("role", "fake login");
        localStorage.setItem("tier", 3);

        window.location.href = "/";
    }

    const setSession = (res) => { 
        const { user_uid, token, email, role, tier, subscription, phone, message } = res; 
                 
        try {
            localStorage.setItem("UID", user_uid);
            localStorage.setItem("token", token);
            localStorage.setItem("email", email);
            localStorage.setItem("role", role);
            localStorage.setItem("tier", tier);
            localStorage.setItem("subscription", subscription?subscription:"");
            localStorage.setItem("phone", phone?phone:"");
            setTier(res,username);
            if (token === null) {
                alert(message);
            } else {
                checkAuth();
            }
        } catch (error) {
            console.log(error);
            alert(error);
        }
    };

    const checkAuth = () => {
        // console.log("fx checkAuth");
        const token_check = localStorage.getItem("token");
        // console.log(token_check);
        const URL = `${version.expURL}/api/auth/check-auth`;
        // console.log(URL);
        const given_token = "token=" + token_check;
        // console.log(given_token);
        fetch(URL, {
            body: given_token,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            method: "POST",
        })
            .then((response) => {
                return response.json();
                // console.log(response);
            })
            .then((res) => {
                // console.log(res);
                if (res.condition === true) {
                    toast.success("successfully logged in");
                    // console.log("redirecting");
                    window.location.href = "/";
                } else {
                    // toast.error("Authentication failed :" + res.message);
                }
                // console.log(res.condition);
            });
    };

    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const redirectRegister = () => {
        // console.log("inside redirectRegister");
        history.push("/pages/auth/register");
    };

    const renderError = () => {
        //display error message to user if there is error...
    }

    const renderMaintenance = () => {
        //display message to user if currently under maintenance..
    }

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <img 
                    className={styles.plexyzIcon}
                    src = {process.env.PUBLIC_URL + `/images/LoginLogo.svg`} 
                    alt={"Login"}
                />
                <img 
                    className={styles.loginPic}
                    src = {process.env.PUBLIC_URL + `/images/LoginPicture.svg`} 
                    alt={"Login Pic"}
                />
                <h2 className={styles.title}>
                    Our tech experts helps your team adopts faster
                </h2>
                <p className={styles.about}>
                    Our highly experienced capabilities are embedded with your organization and help ease the 
                    transition to go digital.
                </p>
            </div> 
            <ToastContainer/>
            <div className={styles.rightContainer}>
                <h2 className={styles.title}>
                    {error===""?"Welcome Back!":error}
                </h2>
                <InputContainer 
                    label="Email" 
                    inputId="username" 
                    value={ username }
                    onChange={(newValue)=>{setUsername(newValue)}}
                    type="text"
                    placeholder="eg: email@yahoomail.com"
                />
                <InputContainer 
                    label="Password" 
                    inputId="password" 
                    icon={<ShowHidePassword />} 
                    value={ password }
                    onChange={(newValue)=>{setPassword(newValue)}}
                    type="password"
                    placeholder=""
                />
                <p className={styles.forgotPassword}>
                    Forgot your password?
                </p>
                <div className={styles.buttonContainer}>
                    <button onClick={(e)=>{handleLogin(e);}}>
                        Sign in
                    </button>
                    <button className={styles.buttonBack} onClick={redirectRegister}>
                        Register
                    </button>
                </div>
            </div>   
        </div>
    )
}

const InputContainer = ({label, inputId, icon, value, onChange, type,placeholder}) => {
    
    const inputElement = () => (
        <input
            id={inputId}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e)=>{onChange(e.target.value);}}
        />
    );

    return (
        <div className={styles.inputContainer}>
            <label htmlFor={inputId}>{ label }</label>
            {
                icon ?
                <PasswordInput 
                    icon={icon} 
                    inputId={inputId} 
                    placeholder={placeholder} 
                    value={value} 
                    onChange={onChange} 
                />
                :
                inputElement() 
            }           
        </div>
    );
}

const PasswordInput = ({icon, inputId, placeholder, value, onChange}) => {
    const [hidePassword, toggleHidePassword ] = useState(true);

    return (
        <div className={styles.inputWithIcon}>
            <input
                id={inputId}
                type={hidePassword?"password":"text"}
                placeholder={placeholder}
                value={value}
                onChange={(e)=>{onChange(e.target.value);}}
            />
            <div className={styles.iconContainer} onClick={()=>{toggleHidePassword(!hidePassword);}}>
            { 
                icon 
            }
            </div>
        </div>
    );
}