import React, { useEffect, useState } from "react";
// import { UserInfo } from "./AccountOverview";
// import { TierOverview } from "./TierOverview";
import { useTablePagination } from "../../../../hooks/useTablePagination";
import { Pagination } from "../../global/Pagination";
import { Modal } from "../../global/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../../../styles/plexyz/components/pages/reports/reports.module.css";
import mainStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import tableStyles from "../../../../styles/plexyz/components/global/table.module.css";
import { UserInfo } from "../user-account/AccountOverview";
import { TierOverview } from "../user-account/TierOverview";
import { version } from "../../../../utils/const";
import TableScrollbar from "react-table-scrollbar";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";

const UserReports = () => {
  const history = useHistory();
  const [iframeSize, setIframeSize] = useState({ width: "70vw", height: "70vh" });

  const backPage = () => {
    try {
      console.log("inside");
      history.push(`/reports`);
    } catch (error) {
      console.log(error);
    }
  };

  const reportsTable = () => {
    return (
      <div>
        {/* <h2>Your Component Title</h2> */}
        {/* Adjusted width and height for responsiveness */}
        <div
          className={styles.iframeContainer}
          style={{ width: iframeSize.width, height: iframeSize.height }}
        >
          <iframe
            title="Looker Report"
            height="100%"
            width="100%"
            box="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            src="https://lookerstudio.google.com/embed/reporting/c5ffae02-16d5-475b-8684-38ea0caa8eb2/page/p_jehq2kju9c"
          ></iframe>
          {/* <iframe
            src="https://programiz.pro"
            title="programiz pro website"
            height="500"
            width="500"
          ></iframe> */}
        </div>
      </div>
    );
  };

  // Update iframe size on window resize
  useEffect(() => {
    const handleResize = () => {
      // Adjust iframe size based on the background size or container size
      setIframeSize({ width: "80vw", height: "80vh" });
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={mainStyles.container}>
      <div className={styles.container} style={{ marginTop: 15 }}>
        <div className={styles.topBar}>
          <h4 className={styles.title}>All Reports</h4>

          <div style={{ flexDirection: "row", display: "flex" }}>
            <div className={`${styles.basicButton} ${styles.active}`} onClick={() => backPage()}>
              <p className={styles.buttonTitle}>Back</p>
            </div>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <>{reportsTable()}</>
        </div>
      </div>
    </div>
  );
};

export default UserReports;
