import React, { useState } from "react";
import { Modal } from "../../global/Modal";
import modalStyles from "../../../../styles/plexyz/components/pages/devices/devices.module.css";
import axios from "axios";
import { version } from "../../../../utils/const";
import { toast } from "react-toastify";


export const RemoveUnit = ({unitData, setConfirmDialog, refetchUnit}) => {

    const removeDevice = async (d) => {

        const URL = `${version.expURL}/api2/unit/deregister_unit`;
        const userID = localStorage.getItem("UID");
    
        const body = {
          user_uid: userID,
          unit_uid: d.unit_uid,
        };
    
        let respond = await axios.post(URL, body);
        // console.log(respond);
        if (respond.data.status === "success") {
          // fetchList()
          refetchUnit();
          toast.success("Unit is now removed from this account.");
        }
        else {
          toast.error(respond.data.message)
        }
      }

    return (
        <Modal>
          <div className={modalStyles.addDeviceDialog}>
          <h3 className={modalStyles.addDeviceTitle}>Removal confirmation</h3>
          <p className={modalStyles.addDeviceTitle}>You are about to remove {unitData.device_id} from this user account. Are you sure?</p>
          <div className={modalStyles.addDeviceSelect}>
              <div
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceOk}`}
                onClick={() => {
                  removeDevice(unitData);
                  setConfirmDialog(false)
                  refetchUnit()
                }}
              >
                Yes
              </div>
              <div
                onClick={() => {setConfirmDialog(false)}}
                className={`${modalStyles.addDeviceButton} ${modalStyles.addDeviceCancel}`}
              >
                Cancel
              </div>
              </div>
          </div>
        </Modal>
    )
}