import React, { useState, useEffect } from "react";
import axios from "axios";
import { version } from "../../utils/const";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../../styles/plexyz/register.module.css";
import { LoginLogo } from "../../svg-shapes/LoginLogo";
import { LoginPicture } from "../../svg-shapes/LoginPicture";
import { ShowHidePassword } from "../../svg-shapes/ShowHidePassword";

import { createHashHistory } from "history";
const history = createHashHistory();

const InputContainer = ({
  label,
  inputId,
  icon,
  value,
  onChange,
  type,
  placeholder,
}) => {
  const inputElement = () => (
    <input
      id={inputId}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={inputId}>{label}</label>
      {icon ? (
        <PasswordInput
          icon={icon}
          inputId={inputId}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        inputElement()
      )}
    </div>
  );
};

const PasswordInput = ({ icon, inputId, placeholder, value, onChange }) => {
  const [hidePassword, toggleHidePassword] = useState(true);

  return (
    <div className={styles.inputWithIcon}>
      <input
        id={inputId}
        type={hidePassword ? "password" : "text"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <div
        className={styles.iconContainer}
        onClick={() => {
          toggleHidePassword(!hidePassword);
        }}
      >
        {icon}
      </div>
    </div>
  );
};

export const PlexyzRegister = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reconfirmPassword, setReconfirmPassword] = useState("");
  const [username, setUsername] = useState("")
  const [role, setRole] = useState("User");
  const [error, setError] = useState("");
  const [register, setRegister] = useState("");

  const handleCreateUser = async (event) => {
    event.preventDefault();
    if (email === "" && password === "") {
      toast.error("Email and password are required");
      return;
    } else if (username === "") {
      toast.error("Username is required");
      return;
    } else if (password === "") {
      toast.error("Password is required");
      return;
    } else if (email === "") {
      toast.error("Email is required");
      return;
    } else if (reconfirmPassword === "") {
      toast.error("Reconfirm password is required");
      return;
    } else if (password !== reconfirmPassword) {
      toast.error("Password does not match");
      setReconfirmPassword("");
      return;
    } else {
      try {
        await verifyEmail(email);
        if (error === "") {
          await createNewUser(email, password, username);
        }
      } catch (err) {
        toast.error(
          "Registration failed. Please check if the email or password entered is correct."
        );
      }
    }
  };

  const verifyEmail = () => {
    // Insert function here
    if (email === "") {
      toast.error("Email is required");
      setError("Email is required")
    } else if (!validateEmail(email)) {
      toast.error("Invalid email");
      setError("Invalid email")
      setEmail("");
    } else {
      try {
        // this.registerInterest(email, notes);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const createNewUser = async (email, pwd, username) => {
    // history.push("/pages/auth/register");

    var URL = `${version.expURL}/api/auth/sign_up`;
    // console.log('create_new_user',URL);
    let body = {
      email: email,
      password: pwd,
      role: "User",
      username: username
    };

    // let respond = await axios.post(body, URL);
    // console.log({ respond });
    fetch(URL, {
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then(async(res) => {
        if (res.register === true) {
          localStorage.setItem("token", res.token_signin_forever);
          localStorage.setItem("username", res.username);
          localStorage.setItem("email", res.email);
          localStorage.setItem("role", res.role);
          localStorage.setItem("UID", res.user_uid);
          // console.log("regisss", res);
          try {
            const mysql_URL = `${version.expURL}/api2/mysql/create_dashboard_mqtt_credential`;
            var mysql_body = {
              user_uid:res.user_uid
            }

            let mysql_respond = await axios.post(mysql_URL, mysql_body)
            console.log(mysql_respond);


            
          } catch (error) {
            console.log(error);
          }
          checkAuth()
          
        } else {
          toast.error("Error :" + res.message);
        }

        // funcLogin(email, pwd)
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const checkAuth = () => {
    const token_check = localStorage.getItem("token");
    localStorage.setItem("tier", 1);

    // console.log(token_check);
    const URL = `${version.expURL}/api/auth/check-auth`;
    // console.log(URL);
    const given_token = "token=" + token_check;
    // console.log('ehh',given_token);
    fetch(URL, {
        body: given_token,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        method: "POST",
    })
        .then((response) => {
            return response.json();
            // console.log(response);
        })
        .then((res) => {
            console.log('check_auth_after_register',res);
            if (res.condition === true) {
                toast.success("Successfully registered");
                window.location.href = "/";
            } else {
                toast.error("Error :" + res.message);
            }
            // console.log(res.condition);
        });
};


  const redirectLogin = () => {
    history.push("/pages/auth/login");
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img
          className={styles.plexyzIcon}
          src={process.env.PUBLIC_URL + `/images/LoginLogo.svg`}
          alt={"Login"}
        />
        <img
          className={styles.loginPic}
          src={process.env.PUBLIC_URL + `/images/LoginPicture.svg`}
          alt={"Login Pic"}
        />
        <h2 className={styles.title}>
          Our tech experts helps your team adopts faster
        </h2>
        <p className={styles.about}>
          Our highly experienced capabilities are embedded with your
          organization and help ease the transition to go digital.
        </p>
      </div>
      <ToastContainer />

      <div className={styles.rightContainer}>
        <h2 className={styles.title}>Create account with us!</h2>
        <InputContainer
          label="Username"
          inputId="username"
          value={username}
          onChange={(newValue) => {
            setUsername(newValue);
          }}
          type="text"
          placeholder="eg: Joe, Jane"
        />
        <InputContainer
          label="Email"
          inputId="email"
          value={email}
          onChange={(newValue) => {
            setEmail(newValue);
          }}
          type="text"
          placeholder="eg: email@yahoomail.com"
        />
        <InputContainer
          label="Password"
          inputId="password"
          icon={<ShowHidePassword />}
          value={password}
          onChange={(newValue) => {
            setPassword(newValue);
          }}
          type="password"
          placeholder="Type your password"
        />
        <InputContainer
          label="Reconfirm Password"
          inputId="reconfirmPassword"
          icon={<ShowHidePassword />}
          value={reconfirmPassword}
          onChange={(newValue) => {
            setReconfirmPassword(newValue);
          }}
          type="text"
          placeholder="Retype your password"
          disabled
        />
        {/* <p className={styles.errorOutput}>{error}</p> */}

        {/* <div className={styles.inputContainer}>
          <label htmlFor={role}>Role</label>
          <input id="role" type="text" value={role} disabled />
        </div> */}

        <div className={styles.buttonContainer}>
          <button
            onClick={(e) => {
              handleCreateUser(e);
            }}
          >
            Create Account
          </button>
          <button className={styles.buttonBack} onClick={redirectLogin}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};
