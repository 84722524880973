import React, { useState, useEffect, useRef } from "react";
import { BeaconInfo } from "./BeaconInfo";
import { NearestDevices } from "./NearestDev";
import { ColorIndicator } from "./ColorIndicator";
import { DecoratedRadar } from "../../graph-display/Radar";
import { Heatmap } from "../../graph-display/Heatmap";
import { ViewSwitch } from "./ViewSwitch";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useContainerDimensions } from "../../../../hooks/useContainerDimensions";
import { TopControlContext } from "./context";
import styles from "../../../../styles/plexyz/components/pages/dashboard/dashboard.module.css";
import { filter } from "d3";

export const TopAnalytics = ({
  nearestDevices,
  tier,
  fetchType,
  historyTime,
  fetchParamsChanged,
}) => {
  const [hoveredMac, setHoveredMac] = useState("");
  const [filterMac, setFilterMac] = useState("");
  const [isOpenUnit, setIsOpenUnit] = useState(true);
  const [isOpenNearest, setIsOpenNearest] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const graphRef = useRef(null);
  const { height } = useContainerDimensions(graphRef, [nearestDevices.length]);
  const { width } = useWindowDimensions();

  const isMidScreen = width > 600 && width < 900;

  useEffect(() => {
    setHoveredMac("");
  }, [nearestDevices]);

  const containerHeight = height > 0 ? height : 733;
  //If not mid screen, the top left analytics will not be displayed...
  //therefore, when it does, for height use figma height, which is 733px..

  const collapseAll = () => {
    setIsOpenUnit(false);
    setIsOpenNearest(false);
    setIsOpen(false);
  };
  const openAll = () => {
    setIsOpenUnit(true);
    setIsOpenNearest(true);
    setIsOpen(true);
  };

  const allWidgetCollapsed = isOpenUnit === false && isOpenNearest === false && isOpen === false;

  const fullwidthVisualization = isOpenUnit === false && isOpenNearest === false;

  const largeScreenContent = () => {
    return (
      <div className={styles.topAnalytics}>
        <div
          className={`${fullwidthVisualization ? styles.topLeftClosed : styles.topLeftAnalytics}`}
          ref={graphRef}
        >
          <BeaconInfo
            isOpenUnit={isOpenUnit}
            toggleOpenUnit={() => {
              setIsOpenUnit(!isOpenUnit);
            }}
            fetchType={fetchType}
            historyTime={historyTime}
          />
          <NearestDevices
            data={nearestDevices}
            tier={tier}
            hoveredMac={hoveredMac}
            setHoveredMac={setHoveredMac}
            filterMac={filterMac}
            setFilterMac={setFilterMac}
            isOpenNearest={isOpenNearest}
            toggleOpenNearest={() => {
              setIsOpenNearest(!isOpenNearest);
            }}
          />
        </div>
        <TopRightAnalytics
          data={nearestDevices}
          height={containerHeight}
          hoveredMac={hoveredMac}
          setHoveredMac={setHoveredMac}
          filterMac={filterMac}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          fetchParamsChanged={fetchParamsChanged}
          fullwidthVisualization={fullwidthVisualization}
        />
      </div>
    );
  };

  return (
    <TopControlContext.Provider
      value={{ openAll, collapseAll, allWidgetCollapsed, fullwidthVisualization }}
    >
      {width < 900 ? (
        <TopAnalyticsMidScreen
          hoveredMac={hoveredMac}
          setHoveredMac={setHoveredMac}
          height={containerHeight}
          nearestDevices={nearestDevices}
          tier={tier}
          canOverlap={width < 760}
        />
      ) : (
        largeScreenContent()
      )}
    </TopControlContext.Provider>
  );
};

const TopAnalyticsMidScreen = ({
  hoveredMac,
  setHoveredMac,
  height,
  nearestDevices,
  tier,
  canOverlap,
  filterMac,
}) => {
  const [leftOpen, setLeftOpen] = useState(false);
  //left analytics slide up and down

  const swipeOpen = () => {
    return (
      <div
        className={styles.leftSwipe}
        onClick={() => {
          setLeftOpen(true);
        }}
      >
        <div className={`${styles.swipeBars} ${styles.swipeBottom}`}>
          <div className={styles.swipeDecor} />
          <div className={styles.swipeDecor} />
        </div>
      </div>
    );
  };

  const close = () => {
    return (
      <div className={styles.closeLeftIcon}>
        <img
          src={process.env.PUBLIC_URL + `/images/mobile/closeIcon.svg`}
          alt={"Close"}
          onClick={() => {
            if (leftOpen) {
              setLeftOpen(false);
            }
          }}
        />
      </div>
    );
  };

  const closeLeftAnalytics = () => {
    setLeftOpen(false);
  };

  return (
    <div className={styles.topAnalyticsMidScreen}>
      <TopRightAnalytics
        data={nearestDevices}
        height={height}
        hoveredMac={hoveredMac}
        setHoveredMac={setHoveredMac}
        leftAnalyticsOpen={canOverlap && leftOpen}
        closeLeftAnalytics={canOverlap && closeLeftAnalytics}
        filterMac={filterMac}
      />
      <div className={`${styles.topLeftAnalytics} ${!leftOpen && styles.topLeftClosed}`}>
        <BeaconInfo />
        <NearestDevices
          data={nearestDevices}
          tier={tier}
          hoveredMac={hoveredMac}
          setHoveredMac={setHoveredMac}
        />
        {leftOpen ? close() : swipeOpen()}
      </div>
    </div>
  );
};

const TopRightAnalytics = ({
  data,
  height,
  hoveredMac,
  filterMac,
  setHoveredMac,
  leftAnalyticsOpen,
  closeLeftAnalytics,
  isOpen,
  setIsOpen,
  fetchParamsChanged,
  fullwidthVisualization,
}) => {
  const [vis, setVis] = useState("Radar");
  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  const radar = () => {
    return (
      <DecoratedRadar
        data={data}
        height={height}
        hoveredMac={hoveredMac}
        setHoveredMac={setHoveredMac}
        filterMac={filterMac}
      />
    );
  };

  return (
    <div
      className={`${fullwidthVisualization ? styles.topRightFullwidth : styles.topRightAnalytics}`}
    >
      {vis === "Radar" ? (
        radar()
      ) : (
        <Heatmap height={height} data={data} fetchParamsChanged={fetchParamsChanged} />
      )}
      {!isMobile && (
        <LegendAndSwitch
          vis={vis}
          setVis={setVis}
          leftAnalyticsOpen={leftAnalyticsOpen}
          closeLeftAnalytics={closeLeftAnalytics}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
};

const LegendAndSwitch = ({
  vis,
  setVis,
  leftAnalyticsOpen,
  closeLeftAnalytics,
  isOpen,
  setIsOpen,
}) => {
  useEffect(() => {
    if (leftAnalyticsOpen && isOpen) {
      setIsOpen(false);
      //when left and right analytics overlap each other, close right when left is open
    }
  }, [leftAnalyticsOpen]);

  useEffect(() => {
    if (leftAnalyticsOpen && isOpen) {
      closeLeftAnalytics();
      //when left and right analytics overlap each other, close left when right is open
    }
  }, [isOpen]);

  return (
    <div className={styles.rightAnalytics}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "45px",
        }}
      >
        {/* <ViewSwitch isOpen={isOpen} vis={vis} setVis={setVis} /> */}
      </div>
      <ColorIndicator
        isOpen={isOpen}
        toggleOpen={() => {
          setIsOpen(!isOpen);
        }}
      />
    </div>
  );
};
