import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import CAR from '../../assets/images/bluecarvert.png';
// import DevicesPerDay from './DevicesPerDay';

class DisplayPlexis extends Component {
    _renderCarDeets = () => {
        let display, carDetails, timestamp;
        const { data } = this.props;

        if(data !== undefined){
        // if(data._vehicle !== undefined){
            carDetails = data._vehicle._id !== '' ?
            <div>
                <h2>Vehicle Details</h2>
                This device is attached to {data._vehicle.plate}<br/>
                { data._vehicle.make !== '' ? data._vehicle.make + ', ' : null }
                { data._vehicle.model !== '' ? data._vehicle.model : null }
            </div> :
            <div>
                <h2>Vehicle Details</h2>
                This device is not attached to any vehicle. Go to the <NavLink to='/admin/devices'>devices page</NavLink>
            </div>
        } else {
            carDetails = <h2 className="pb-4">Click on the devices below to monitor Plexis</h2>
        }

        if (this.props.data !== undefined) {
        // if (this.props.data._PLEXIS_TIMESTAMP !== undefined) {
            // timestamp = <div>hello</div>
            timestamp = <small>Last Updated {new Date(this.props.data._PLEXIS_TIMESTAMP).toLocaleString()}</small>
        }

        display =
        <div className="mb-4">
            {carDetails}
            {timestamp}
        </div>

        return display;
    }
    _renderAnalytics = () => {
        let display;

        if(this.props.analytics !== undefined){
        // if(this.props.analytics.device_count !== undefined){
            display =
            <div className="mb-4">
                <Card body className="bg-gradient-default text-white mb-4">
                    {this.props.analytics.device_count} Unique Devices Detected
                </Card>

                {/* <DevicesPerDay
                    data={this.props.analytics}
                    day={this.props.day} month={this.props.month} year={this.props.year}
                    parentSetDate={this.props.parentSetDate}
                /> */}
            </div>
        }

        return display;
    }
    _renderPlexis = () => {
        let display, TEMP = [];

        if(this.props.data !== undefined && this.props.data._vehicle !== undefined) {
        // if(this.props.data._PLEXIS !== undefined && this.props.data._vehicle !== undefined) {
            this.props.data._PLEXIS.forEach((plexo) => {
                let D_SQR = Math.pow(plexo.distance,2)
                let X_NEG = Math.floor(Math.random() * 2)
                let Y_NEG = Math.floor(Math.random() * 2)
                let X = Math.floor(Math.random() * plexo.distance)
                let X_SQR = X * X
                let Y = Math.sqrt(D_SQR - X_SQR)
                let a = {
                    x: X_NEG !== 0 ? -X : X,
                    y: Y_NEG !== 0 ? -Y : Y,
                    d: plexo.distance
                }
                TEMP.push(a)
                // console.log('plexo', TEMP)
            })
            display = TEMP.map((plexis,index) => (
                <div key={index} className="bg-gradient-danger" style={{fontSize:'0.8rem', color:'white', position:'absolute', zIndex:'6', top:'50%', left:'50%', transform:`translate(${plexis.x * 2.5}rem, ${plexis.y * 2.5}rem)`, width:'0.3rem', height:'0.3rem', borderRadius:'50%'}}>
                    <div style={{color: 'black', position: 'absolute', bottom: '0.3rem', lineHeight: '13px', fontSize: '12px', fontWeight: 'bold',}}>{plexis.d}</div>
                </div>
            ))
        }
        if(this.props.data === undefined && this.props.data !== undefined) {
        // if(this.props.data._PLEXIS === undefined && this.props.data._vehicle !== undefined) {
            display = <div className="bg-gradient-danger text-white shadow" style={{ position:'absolute', zIndex:'6', top:'50%', left:'50%', transform:'translate(-50%,-50%)', padding:'10px 15px', width:'75%', borderRadius:'0.375rem'}}>There is no Plexis data on this device. Please turn the Plexis feature on to display any data.</div>
        }

        return display;
    }
    render() {
        // const { data } = this.props
        // console.log(this.props.analytics);
        
        return (
            // <Card body className= "shadow mb-3">
            <Card>
                <Row>
                    <Col md={6}>
                        {/* {this._renderCarDeets()} */}
                        <h2 className="pb-4">Click on the devices below to monitor Plexis</h2>
                        {/* {this._renderAnalytics()} */}
                    </Col>

                    {/* <Col md={6} sm={12} className="text-center" style={{position:'relative'}}> */}
                    <Col>
                        <div style={{width:'100%', paddingTop:'100%', margin:'0 auto', display:'flex', justifyContent:'center', alignItems:'center', position:'relative', zIndex:'5'}}>

                            {/* car image */}
                            <img src={CAR} alt="..." style={{margin:'0 auto', height:'10rem', position:'absolute', top:'50%', transform:'translateY(-50%)', zIndex:'2'}}/>

                            {/* central dot */}
                            <div className="bg-white" style={{border:'1px solid #adb5bd', borderRadius:'50%', width:'5px', height:'5px', position:'absolute', top:'50%', transform:'translateY(-50%)',zIndex:'5'}}></div>

                            {/* outer radius */}
                            <div style={{border:'1px dashed #adb5bd', borderRadius:'50%', width:'100%', height:'100%', position:'absolute', top:'50%', transform:'translateY(-50%)',zIndex:'1'}}></div>
                            <div style={{border:'1px dashed #adb5bd', borderRadius:'50%', width:'75%', height:'75%', position:'absolute', top:'50%', transform:'translateY(-50%)',zIndex:'1'}}></div>
                            <div style={{border:'1px dashed #adb5bd', borderRadius:'50%', width:'50%', height:'50%', position:'absolute', top:'50%', transform:'translateY(-50%)',zIndex:'1'}}></div>
                            <div style={{border:'1px dashed #adb5bd', borderRadius:'50%', width:'25%', height:'25%', position:'absolute', top:'50%', transform:'translateY(-50%)',zIndex:'1'}}></div>

                            {this._renderPlexis()}

                        </div>
                        
                    </Col>
                </Row>
            </Card>
        );
    }
}

DisplayPlexis.propTypes = {
    data: PropTypes.object.isRequired,
    analytics: PropTypes.object,
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
    parentSetDate: PropTypes.func,
};

export default DisplayPlexis;