import React from 'react';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import styles from "../../../../styles/plexyz/components/pages/dashboard/upgrade.module.css";

export const Upgrade = () => {
    const {width} = useWindowDimensions();
    const isMobile = width < 600;

    const mobileBackground = () => {
        return (
            <img 
                className={styles.backgroundImage}
                src = {process.env.PUBLIC_URL + "/images/mobile/upgradeAccount.svg"} 
                alt="upgrade"/>
        )
    }

    return (
        <div 
            className={styles.container} 
            style={{
                backgroundImage:
                    `${isMobile?"":"url(/images/upgrade.png"}`,
                        backgroundSize:"cover", 
                            backgroundRepeat:"no-repeat"}}    
        >
            { isMobile && mobileBackground() }
            <h4 className={styles.title}>
                Upgrade Account    
            </h4>
            <p className={styles.about}>
            More analytics, measurement, profiling and prediction
            </p>
            <button className={styles.upgradeButton}>
                Upgrade Now
            </button>
        </div>
    )
}