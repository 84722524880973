import React, {useState, useEffect} from 'react';
import {useD3} from "../../../hooks/used3";
import * as d3 from 'd3';
import { Tooltip } from '../global/Tooltip';
import styles from "../../../styles/plexyz/components/graph-display/custom-barchart.module.css";

export const BarChart = ({width, height,  graphData, isSorted}) => {
    const ref = useD3(
        (svg) => {
          //const height = height;          
          const margin = { top: 0, right: 0, bottom: 20, left: 40 };
          //const graphData = graphData;
          
          const tooltip = d3.select("div.tooltip").style("display", "none");

          const xDomain = graphData.map(d => d.label);

          const x = d3.scaleBand()
            .domain(xDomain)
            .rangeRound([margin.left, width - margin.right])
            .padding(0.2);
    
          const y1 = d3
            .scaleLinear()
            .domain([0, d3.max(graphData, (d) => d.value)])
            .rangeRound([height - margin.bottom, margin.top]);
    
          const xAxis = (g) =>
            g.attr("transform", `translate(0,${height - margin.bottom})`)
            .transition().duration(1000)
            .call(
              d3
                .axisBottom(x)
                .tickValues(
                  x.domain()
                )
                .tickSizeOuter(0)
            );
    
          const y1Axis = (g) =>
            g
              .attr("transform", `translate(${margin.left},0)`)
              .style("color", "steelblue")
              .call(d3.axisLeft(y1).ticks(null, "s"))
              .call((g) => g.select(".domain").remove())              
    
          svg.select(".x-axis").call(xAxis);
          svg.select(".y-axis").call(y1Axis);
    
          const canvas = svg
            .select(".plot-area")
            .attr("fill", "steelblue")

          const bars =  canvas
            .selectAll(".bar")
            .data(graphData, function(d){return d.label});

          bars.enter().append("rect")
            .attr("class", "bar")
            .attr("x", (d) => x(d.label))
            .attr("width", x.bandwidth())
            .attr("y", y1(0))
            .attr("height", 0)
            .attr("fill", "steelblue")      //#34D399
            .on("mouseover", (e, d)=>{
              canvas.selectAll("rect.bar")
                  .filter(dBar => dBar.label === d.label)
                .style("stroke", "black")
                .style("stroke-width", 1)

              tooltip.style("display", "inline");
              tooltip.select("p.label").text(d.label);
              tooltip.select("p.value").text(`${d3.format(",")(d.value)}`);
              
              d3.select(".x-axis").selectAll("text")
                .filter(dText=>dText === d.label).style("font-weight", "bold")
            })      
            .on("mousemove", (e, d)=>{
              const reduceFromPageX = 
                    width < 600?0:
                    width >= 600 && width <= 748?35:
                    width >  748 && width <= 1024?50:
                    width > 1024 && width <= 1440?90:
                    150;

              tooltip
                  .style("left", function(){
                      return (e.pageX - reduceFromPageX) + "px";
                  })
                  .style("top", function(){
                      return (e.pageY - 140) + "px";
                  });
            })
            .on("mouseout", (e, d)=>{
              canvas.selectAll("rect.bar")
                  .filter(dBar => dBar.label === d.label)
                .style("stroke", "none")
                .style("stroke-width", 0)

                tooltip.style("display", "none");

                d3.select(".x-axis").selectAll("text")
                  .filter(dText=>dText === d.label).style("font-weight", "normal")
            })
              .transition().duration(1000)  
            .attr("y", (d) => y1(d.value))          
            .attr("height", (d) => y1(0) - y1(d.value))
            .transition().duration(500)
            .attr("fill", "steelblue");            
          
          bars
            .transition().duration(1000)
            .attr("x", (d) => x(d.label))
            .attr("width", x.bandwidth())
            .attr("y", (d) => y1(d.value))
            .attr("height", (d) => y1(0) - y1(d.value))
            .attr("fill", "steelblue");
            
          bars.exit()
            .transition().duration(250)
            .attr("fill", "#B45309")
            .transition().duration(750)
            .attr("width", 0).attr("height", 0).attr("y", y1(0))            
            .remove();
          
        },
        [graphData, width, height, isSorted]
      );

  return (
    <>
    <svg
      ref={ref}
      style={{
        height: height,
        width: width,
        marginRight: "0px",
        marginLeft: "0px",
        marginBottom: "0px"
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
    <Tooltip />
    </>
  );
}

export const CustomBarchart = ({width, height, graphData, isSorted}) => {
  const barchartRef = useD3(
    (barchartContainer) => {
      barchartContainer.append("div").attr("className", "barShade");
      /*
      const barShades = barchartContainer.selectAll(".barShade")
        .data(graphData, function(d){return d.label});

      barShades.enter().append("div")
        .attr("class", "barShade")
        .attr("opacity", 1);

      barShades
          .transition().duration(250)
        .attr("opacity", 1)
        .attr("width",20)
        .attr("height", 200);

      barShades
          .exit()
          .transition().duration(250)
        .attr("opacity", 0)
          .remove();*/

    },[graphData]);

  return (
    <>
      <div className={styles.barchartAndYAxis}>
        <div className={styles.yAxis}>
        {
          ["4000", "3000", "2000", "1000","0"].map((d, i)=>(
            <div className={styles.yAxisLabel} key={d}>{ d }</div>
          ))
        }
        </div>
        <div className={styles.barchart}>
        {
          graphData.map((d, i)=>(
            <div className={styles.barShade} key={d.label}>
              <div 
                className={styles.bar}
                style={{
                  backgroundColor:d.label==="A"?"#5c0fff":d.label==="B"?"#ffac17":"#c8bde8",
                  height: ((d.value/4000) * 100) + "%"
                }}
              />
            </div> 
          ))
        } 
        </div>
      </div>
      <div className={styles.xAxisContainer}>
        <div className={styles.xAxis}>
        {
          graphData.map((d, i)=>(
            <p className={styles.xAxisLabel} key={d.label}>{ d.label }</p>
          ))
        }
        </div>
      </div>
    </>
  )
}
